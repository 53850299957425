import React from 'react';
import {Grid} from "semantic-ui-react"

import config from '../../config/main.config'
import BreadCrumbPrinterCartridge from "../components/BreadCrumbPrinterCartridge";
import history from "../helper/browserHistory"
import { withNamespaces } from 'react-i18next';
import QuestionAnswer from "../components/QuestionAnswer";
import MetaTags from "../components/MetaTags"
import TextHelper from "../components/TextHelper"
import {getTokenFromLocalStorage} from "../helper/util";
import {Helmet} from "react-helmet-async";


@withNamespaces()
export default class DefineState extends React.Component {

    constructor(props) {
        super(props);
        
        let {staticContext} = props

        if (staticContext && (staticContext.hasOwnProperty("type") && staticContext.hasOwnProperty("originalItemNumber") && staticContext.hasOwnProperty("manufacturer"))) {
            this.state = {
                orderNumber: 0,
                type: staticContext.type,
                manufacturer: staticContext.manufacturer,
                originalItemNumber: staticContext.originalItemNumber
            } 
        }else{

            this.state = {
                orderNumber: 0,
                type: "toner", //20200605 in reason of missing soruce code,
                //type: '', //,
                manufacturer: '',
                originalItemNumber: ''
            };
        }
        this.answerClicked = this.answerClicked.bind(this);
    }

    componentDidMount() {
        const {itemNumber} = this.props.match.params;

        /*
        //Send token if logged in to get also ink results
        const userToken = getTokenFromLocalStorage();
        let headers = config.HEADERS;
        if(userToken != null){
            headers.token =  userToken
        }

        if (typeof itemNumber !== 'undefined') {
            fetch(config.BASE_URL + "printerCartridgePrices/type?itemNumber=" + itemNumber + "&sendError=true", {
                method: 'GET',
                headers: headers
            }).then((response) => {
                if (response.status >= 200 && response.status < 400) {
                    response.json().then(json => {
                        this.setState({
                            type: json[0].type
                         })
                    });

                } else {
                    history.replace('/verkaufspreis-nicht-ermittelt');
                }
            }).catch(error => {
                history.replace('/toner-nicht-gefunden?1');
            });
        
            //also getPrinterCartridgeDetailsByItemNumber
            this.getPrinterCartridgeDetailsByItemNumber(itemNumber)
        
        } else {
            history.replace('/toner-nicht-gefunden?2');
        }*/

        if (typeof itemNumber !== 'undefined') {
             this.getPrinterCartridgeDetailsByItemNumber(itemNumber)
        } else {
            history.replace('/toner-nicht-gefunden?2');
        }
    }

    getPrinterCartridgeDetailsByItemNumber(itemNumber){

        //Send token if logged in to get also ink results
        const userToken = getTokenFromLocalStorage();
        let headers = config.HEADERS;
        if(userToken != null){
            headers.token =  userToken
        }

        fetch(config.BASE_URL + "printerCartridges/details?itemNumber=" + itemNumber, {
            method: 'GET',
            headers: headers
        }).then((response) => {
            if (response.status >= 200 && response.status < 400) {
                response.json().then(json => {

                    this.setState({
                        originalItemNumber: json.originalItemNumber,
                        manufacturer: json.manufacturer,
                        type: json.type, //use type as trigger -> only valid if type is toner or ink in render 
                     })
                });

            } else {
                history.replace('/verkaufspreis-nicht-ermittelt');
            }
        }).catch(error => {
            history.replace('/toner-nicht-gefunden?11');
        });
    }

    componentDidUpdate(prevProps){
        let prev = prevProps.match.params.itemNumber
        let current = this.props.match.params.itemNumber

        if(prev !== current){
            this.getPrinterCartridgeDetailsByItemNumber(current)
        }
    }
    
    answerClicked(answer, e) {
        const {itemNumber} = this.props.match.params;
        if(answer.isLastAnswer) {
            if(answer.state !== '' && typeof itemNumber !== "undefined") {
                localStorage.setItem(config.STORAGE_KEY_STATE, JSON.stringify({
                    state:answer.state,
                    itemNumber:itemNumber
                }));

                //TODO: Muss hier dekodiert werden?
                history.push('/kassieren/' + itemNumber + '/' + answer.state);
            }

        }
        else {
            this.setState({
                orderNumber: this.state.orderNumber + 1
            })
        }
    }


    render() {
         const {t} = this.props;
        const {itemNumber} = this.props.match.params;

        return (
            this.state.type == 'toner' && this.state.manufacturer !== '' && this.state.originalItemNumber !== '' ?
                <Grid stackable class='main-grid'>
                    <MetaTags page="zustand-definieren"
                              add={{
                                  manufacturer: this.state.manufacturer,
                                  originalItemNumber: this.state.originalItemNumber
                              }}/>
                    <Helmet>
                        <title>{t('meta.title.zustand-definieren', {
                            manufacturer: this.state.manufacturer,
                            originalItemNumber: this.state.originalItemNumber
                        })}</title>

                        <meta name="description" content={t('meta.description.zustand-definieren', {
                            manufacturer: this.state.manufacturer,
                            originalItemNumber: this.state.originalItemNumber
                        })}/>

                    </Helmet>

                    <Grid.Row class='main-grid-header-row'>
                        <Grid.Column>
                            <h1 className="fira big">
                                {t('defineState.headline', {
                                    manufacturer: this.state.manufacturer,
                                    originalItemNumber: this.state.originalItemNumber,
                                    type: "Tonerkartusche"
                                })}
                            </h1>
                            <span className="fira small block">
                                {t('defineState.subheadline')}
                            </span>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row class='main-grid-main-row'>
                        <QuestionAnswer itemNumber={itemNumber}
                                        questionBefore={() => this.setState({orderNumber: this.state.orderNumber - 1})}
                                        answerClicked={this.answerClicked}
                                        staticContext={this.props.staticContext}
                                        orderNumber={this.state.orderNumber}/>
                    </Grid.Row>
                    <Grid.Row class='main-grid-main-row'>
                        <Grid.Column>
                            <TextHelper f="defineStateDirect" add={{itemNumber: itemNumber}}/>
                        </Grid.Column>
                    </Grid.Row> 
                    <Grid.Row class='defineState-breadcrumb-wrapper main-grid-footer-row'>
                        <BreadCrumbPrinterCartridge
                            activeItem='state'
                            forceBottom={false}/>
                    </Grid.Row>
                </Grid>
                :
                this.state.type == 'ink' && this.state.manufacturer !== '' && this.state.originalItemNumber !== '' ? //because state type is only correct after componentDidMount()
                    <Grid stackable class='main-grid'>
                        <MetaTags page="zustand-definieren"
                                  add={{
                                      manufacturer: this.state.manufacturer,
                                      originalItemNumber: this.state.originalItemNumber
                                  }}/>
                        <Helmet>
                            <title>{t('meta.title.zustand-definieren', {
                                manufacturer: this.state.manufacturer,
                                originalItemNumber: this.state.originalItemNumber
                            })}</title>
                        </Helmet>

                        <Grid.Row class='main-grid-header-row'>
                            <Grid.Column>
                            <h1 className="fira big">
                                {t('defineState.headlineInk')}
                            </h1>
                            <TextHelper f="defineStateDirect" add={{itemNumber: itemNumber}}/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            :
            null
        );
    }
}