/**
 * Blog Page
 * @namespace BlogArticlePage
 */
import React from "react";
import config from "../../config/main.config";
import {Helmet} from "react-helmet-async";

export default class BlogArticlePageMeta extends React.Component {

    /**
     * Sets state all values for the article
     * @constructor
     * @memberOf BlogArticlePage
     */
    constructor(props) {
        super(props);

        if (props.staticContext) {
            if (props.staticContext.hasOwnProperty("statusCode")) {
                let head = "Es ist ein Fehler unterlaufen";
                let text;
                if (props.staticContext.statusCode === "201") {
                    //Not found
                    text = "<p>Dieser Artikel ist nicht mehr verfügbar</p>";
                } else {
                    //Not published
                    text = "<p>Dieser Artikel ist momentan nicht Verfügbar</p>";
                }
                this.state = {
                    header: head,
                    author: "",
                    date: "",
                    text: text,
                    description: "",
                    thumbnail: ""
                };
            }
            else {
                this.state = {
                    header: props.staticContext.header,
                    author: props.staticContext.author,
                    date: props.staticContext.date,
                    text: props.staticContext.text,
                    description: props.staticContext.description,
                    thumbnail: props.staticContext.thumbnail
                };
            }

        } else {
            this.state = {
                header: "",
                author: "",
                date: "",
                text: "",
                description: "",
                thumbnail: ""
            };
        }

    }

    /**
     * @memberOf BlogArticlePage
     */
    componentDidMount() {
        window.scrollTo(0, 0);
        let url = this.props.match.params.url;

        fetch(config.BASE_URL + 'blog/article/' + url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'type': 'seminars'
            }
        }).then((resp) => resp.json())
            .then(article => {
                if (article.hasOwnProperty("statusCode")) {
                    let head = "Es ist ein Fehler unterlaufen";
                    let text;
                    if (article.statusCode === "201") {
                        //Not found
                        text = "<p>Dieser Artikel ist nicht mehr Verfügbar</p>";
                    } else {
                        //Not published
                        text = "<p>Dieser Artikel ist momentan nicht Verfügbar</p>";
                    }

                    this.setState({
                        ...this.state,
                        header: head,
                        text: text
                    });
                } else {
                    this.setState({
                        ...this.state,
                        header: article.header,
                        author: article.author,
                        date: article.date,
                        text: article.text,
                        description: article.description,
                        thumbnail: article.thumbnail
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
                console.log(error);
            });
    }


    /**
     * Returns Blog page
     * @return {XML} html of this component
     * @memberOf BlogArticlePage
     */
    render() {
        return (
            <Helmet>
                <title>{this.state.header}</title>
                <meta name="description" content={this.state.description} />
                <meta name="robots" content="index,follow" />
            </Helmet>
        );
    }
}