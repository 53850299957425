import React from 'react';
import history from '../helper/browserHistory'
import {Icon, Image, Label, Menu, Segment} from 'semantic-ui-react'
import {Link} from 'react-router-dom'

import shoppingCartStore from '../stores/shoppingCartStore';
import {observer} from 'mobx-react';

import uiStore from '../stores/uiStore';
import SearchArticleNumber from "./SearchArticleNumber";
//TODO: TEST WEGEN SSR

@observer
export default class Header extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showPhoneNumber: false,
            shoppingCartItemNumber: 0,
            showShoppingCart: true
        };

        //this.togglePhoneNumber = this.togglePhoneNumber.bind(this);
    }

    componentDidMount() {

        // Listen for changes to the current location.
        this.unlisten = history.listen((location, action) => {
            // location is an object like window.location

            if (typeof window !== "undefined" && (location.pathname === "/verkaufen" || location.pathname === '/erfolgreich-verkauft')) {
                this.setState({
                    ...this.state,
                    showShoppingCart: false
                })
            } else {
                shoppingCartStore.removeAllDHLPostageStamps();
                this.setState({
                    ...this.state,
                    showShoppingCart: true
                })
            }

        });

        let showShoppingCart = this.state.showShoppingCart;

        if (typeof window !== "undefined" && (window.location.pathname === "/verkaufen" || window.location.pathname === '/erfolgreich-verkauft')) {
            showShoppingCart = false;
        } else {
            shoppingCartStore.removeAllDHLPostageStamps();
        }

        this.setState({
            ...this.state,
            showShoppingCart: showShoppingCart,
            shoppingCartItemNumber: shoppingCartStore.getSummaryOfItems()
        })
    }

    componentWillUnmount() {
        // To stop listening, call the function returned from listen().
        this.unlisten();
    }

    /*togglePhoneNumber() {
        this.setState({
            ...this.state,
            showPhoneNumber: !this.state.showPhoneNumber
        })
    }*/

    shoppingCartClicked() {
        shoppingCartStore.showShoppingCart = !shoppingCartStore.showShoppingCart;
        uiStore.showBurger = !uiStore.showBurger;
    }

    render() {

        return (
            <div class='header-wrapper'>
                <Menu class='header-menu' style={{"width": "100%"}}>
                    <Menu.Item>
                    <Link to='/'>
                        <Image
                            alt='dieTonerverwerter.de'
                            src='/images/logos/dietonerverwerter-round-logo.svg'
                            size='small'
                            className="pointer"
                            width="150"
                            height="47"
                        />
                    </Link>
                    </Menu.Item>
                    <Menu.Menu position='right'>
                        <Segment as={Menu.Item} class="onlyDesktop">
                            <SearchArticleNumber/>
                        </Segment>
                        {/*<Segment as={Menu.Item} {...Segment.onlyMobile} class='white'>*/}
                        <Segment as={Menu.Item} class='white onlyMobile'>
                            <Icon class='white' name='bars' style={{'cursor': 'pointer'}} size='large'
                                  onClick={() => uiStore.showBurger = !uiStore.showBurger}/>
                        </Segment>
                        {/*<Segment as={Menu.Item} minWidth={Segment.onlyMobile.maxWidth + 1}>*/}

                        <Segment as={Menu.Item} class="onlyDesktop">
                            {/*<Link class="white underline-hover bold" to='/volle-toner-verkaufen'>*/}
                            <Link class="white underline-hover bold" to='/anfrage'>
                                Anfrageformular
                            </Link>
                        </Segment>
                        <Segment as={Menu.Item} class="left-border-white onlyDesktop">
                            <Link class="white underline-hover" to='/voll-dabei/'>
                                Blog
                            </Link>
                        </Segment>
                        <Segment as={Menu.Item} class='left-border-white onlyDesktop'>
                            <Link class="white underline-hover" to='/service'>
                                Service
                            </Link>
                        </Segment>
                        <Segment as={Menu.Item} class='left-border-white onlyDesktop'>
                            <Link class="white underline-hover" to='/ankaufsbedingungen'>
                                AGB
                            </Link>
                            {/*
                                this.state.showPhoneNumber ? <div class="white">
                                        <Icon name='phone'/>
                                        <Link  to="tel:+9174976727" class="white underline-hover">+49 9174 9767-27</Link>
                                    </div>
                                    :
                                    <Link  class="white underline-hover service-link" to='#'
                                       onClick={this.togglePhoneNumber}>Service</Link>
                            */}

                        </Segment>
                        <Segment as={Menu.Item} class='left-border-white onlyDesktop'>
                            <Link class="white underline-hover" to='/datenschutz'>
                                Datenschutz
                            </Link>
                        </Segment>
                        <Segment as={Menu.Item} class='left-border-white onlyDesktop'>
                            <Link class="white underline-hover" to='/impressum'>
                                Impressum
                            </Link>
                        </Segment>
                        {
                            this.state.showShoppingCart ?
                                /*<Segment as={Menu.Item} class='last-item onlyDesktop' style={{'cursor':'pointer', 'display': this.state.showShoppingCart ? 'visible' : 'none'}} onClick={this.shoppingCartClicked.bind(this)}>*/
                                <Segment as={Menu.Item} class='last-item onlyDesktop' style={{'cursor': 'pointer'}}
                                            onClick={this.shoppingCartClicked.bind(this)}>
                                    <div className='gradient-round'>
                                        <Icon class='shopping-cart' name='shopping cart'/>
                                        {
                                            shoppingCartStore.getSummaryOfItems() > 0 ?
                                                <Label circular color='red' floating>
                                                    {shoppingCartStore.getSummaryOfItems()}
                                                </Label>
                                                :
                                                null
                                        }

                                    </div>
                                </Segment>
                                :
                                null
                        }
                    </Menu.Menu>
                </Menu>
                {/*<Segment {...Segment.onlyMobile} >*/}
                <div class="onlyMobile">
                    {
                        uiStore.showBurger ?
                            <Menu vertical class='header-menu' style={{"width": "100%", "height": "100%"}}>
                                {
                                    this.state.showShoppingCart ?
                                        /*<Menu.Item onClick={this.shoppingCartClicked.bind(this)} style={{'cursor':'pointer', 'display': this.state.showShoppingCart ? '' : 'none'}}>*/
                                        <Menu.Item onClick={this.shoppingCartClicked.bind(this)}
                                                   style={{'cursor': 'pointer'}}>
                                            <div className='gradient-round'>
                                                <Icon class='shopping-cart' name='shopping cart'/>
                                                {
                                                    shoppingCartStore.getSummaryOfItems() > 0 ?
                                                        <Label circular color='red' floating>
                                                            {shoppingCartStore.getSummaryOfItems()}
                                                        </Label>
                                                        :
                                                        null
                                                }
                                            </div>
                                        </Menu.Item>
                                        :
                                        null
                                }
                                <Menu.Item class='last-item' style={{"textAlign": "center"}}>
                                    <SearchArticleNumber fluid={true}/>
                                </Menu.Item>
                                <Menu.Item class='last-item' style={{"textAlign": "center"}}
                                           onClick={() => uiStore.showBurger = !uiStore.showBurger}>
                                    <Link class="white underline-hover bold" to='/anfrage'>
                                        Anfrageformular
                                    </Link>
                                </Menu.Item>
                                <Menu.Item class='last-item' style={{"textAlign": "center"}}
                                           onClick={() => uiStore.showBurger = !uiStore.showBurger}>
                                    <Link class="white underline-hover" to='/voll-dabei/'>
                                        Blog
                                    </Link>
                                </Menu.Item>
                                <Menu.Item class='last-item' style={{"textAlign": "center"}}
                                           onClick={() => uiStore.showBurger = !uiStore.showBurger}>
                                    <Link class="white underline-hover" to='/service'>
                                        Service
                                    </Link>
                                </Menu.Item>
                                <Menu.Item class='last-item' style={{"textAlign": "center"}}
                                           onClick={() => uiStore.showBurger = !uiStore.showBurger}>
                                    <Link class="white underline-hover" to='/ankaufsbedingungen'>
                                        AGB
                                    </Link>
                                </Menu.Item>
                                <Menu.Item class='last-item' style={{"textAlign": "center"}}
                                           onClick={() => uiStore.showBurger = !uiStore.showBurger}>
                                    <Link class="white underline-hover" to='/datenschutz'>
                                        Datenschutz
                                    </Link>
                                </Menu.Item>
                                <Menu.Item class='last-item' style={{"textAlign": "center"}}
                                           onClick={() => uiStore.showBurger = !uiStore.showBurger}>
                                    <Link class="white underline-hover" to='/impressum'>
                                        Impressum
                                    </Link>
                                </Menu.Item>

                                {/*<Menu.Item class='last-item' style={{"textAlign":"center"}}>
                                    {
                                        this.state.showPhoneNumber ? <div class="white">
                                                <Icon name='phone'/>
                                                <Link  to="tel:+4915146406559" class="white underline-hover">+49 151 46406559</Link>
                                            </div>
                                            :
                                            <Link  class="white underline-hover service-link" to='#'
                                               onClick={this.togglePhoneNumber}>Service</Link>
                                    }
                                </Menu.Item>*/}
                            </Menu>
                            :
                            null
                    }
                </div>
                {/*<div>
                    <center>
                        #MerryChristmas #HappyNewYear - <b>Online-Verkäufe und Anfragen jederzeit möglich</b>!<br />
                        Eingehende Sendungen werden durchgänig angenommen. Die Auswertungen und Auszahlungen erfolgen <b>ab dem 08.01.2024</b><br/>
                       <a href="https://www.sanocycling.de/index.php#betriebsurlaub" target="_blank">Weitere Informationen hier.</a><br />
                    </center>
                </div>*/}
            </div>
        );
    }
}