import React from 'react';
import {Grid} from "semantic-ui-react"
import PropTypes from "prop-types";
import history from "../helper/browserHistory";
import {withNamespaces} from 'react-i18next';
import config from "../../config/main.config";
import {Image} from "semantic-ui-react";
import {getTokenFromLocalStorage} from "../helper/util";

@withNamespaces()
export default class QuestionAnswer extends React.Component {

    constructor(props) {
        super(props);
        let {staticContext} = props

        if (staticContext) {

            if(
                staticContext.hasOwnProperty("question") &&
                staticContext.hasOwnProperty("originalItemNumber") &&
                staticContext.hasOwnProperty("manufacturer")
            ) {
                this.state = {
                    question: staticContext.question[0],
                    manufacturer: staticContext.manufacturer,
                    originalItemNumber: staticContext.originalItemNumber,
                }
            }
            else {
                this.state = {
                    question: null,
                    manufacturer: '',
                    originalItemNumber: '',
                }
            }

        } else {
            this.state = {
                question: null,
                manufacturer: '',
                originalItemNumber: '',
            }
        }

    }

    componentDidMount() {
        let {orderNumber, itemNumber} = this.props

        this.getQuestion(-1, orderNumber, itemNumber);

        if(typeof window !== "undefined") {
            let self = this;
            window.addEventListener("changeItemNumber", function(){
                let itemNumberToLoad = localStorage.getItem("searchItemNumber");
                if(typeof itemNumberToLoad === "undefined" || itemNumberToLoad === null) {
                    itemNumberToLoad = "";
                }

                self.getQuestion(-1, 0, itemNumberToLoad)
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {orderNumber, itemNumber} = this.props

        this.getQuestion(prevProps.orderNumber, orderNumber, itemNumber);
        if(typeof window !== "undefined") {
            window.removeEventListener("changeItemNumber", function() { });
        }
    }

    getQuestion(lastOrderNumber, currentOrderNumber, itemNumber) {

        if (itemNumber !== '' && (currentOrderNumber !== lastOrderNumber)) {

            //Send token if logged in to get also ink results
            const userToken = getTokenFromLocalStorage();
            let headers = config.HEADERS;
            if(userToken != null){
                headers.token =  userToken
            }

            fetch(config.BASE_URL + "questions/itemNumber?itemNumber=" + itemNumber + "&orderNumber=" + currentOrderNumber, {
                method: 'GET',
                headers: headers
            }).then((response) => {
                if (response.status >= 200 && response.status < 400) {
                    response.json().then(json => {
                        if (json.question.length === 0) {
                            // TODO: Evtl ändern mit Frage nicht gefunden?
                            history.replace('/toner-nicht-gefunden');
                        } else {
                            this.setState({
                                question: json.question[0],
                                manufacturer: json.manufacturer,
                                originalItemNumber: json.originalItemNumber
                            });
                        }

                    });

                } else {
                    history.replace('/toner-nicht-gefunden');
                }

            }).catch(error => {
                history.replace('/toner-nicht-gefunden');
            });
        } else if (itemNumber === '') {
            history.replace('/toner-nicht-gefunden');
        }
    }

    colorAnswer(answer) {
        switch(answer.content.toLowerCase().substr(0, 2)) {
            case 'ja':
                return <span className='green'>{this.contentReplacer(answer.content)}</span>;
            case 'ne': //nein
                return <span className='red'>{this.contentReplacer(answer.content)}</span>;
            default:
                return <span>{this.contentReplacer(answer.content)}</span>;
        }
        /*
        switch(answer.content.toLowerCase().substr(0, 2)) {
            case 'ja':
                return <span className='green'>{answer.content}</span>;
            case 'ne': //nein
                return <span className='red'>{answer.content}</span>;
            default:
                return <span>{answer.content}</span>;
        }*/
    }

    contentReplacer(content){
        return content.replace('[manufacturer]', this.state.manufacturer)
    }

    render() {
        const {orderNumber, answerClicked, questionBefore, itemNumber, t} = this.props;
        
        //20200110 check for step back
        let back = 0;
        if(typeof localStorage !== 'undefined') {
            let lastPrinterCartridge = JSON.parse(localStorage.getItem(config.STORAGE_KEY_SELECTED_PRINTER_CARTRIDGE));
            if(lastPrinterCartridge !== null && typeof lastPrinterCartridge !== "undefined"){
                if(lastPrinterCartridge.manufacturer !== null){
                    back = 1
                }
            }
        }


        let answerElements = [];

        if(this.state.question !== null) {
            answerElements = this.state.question.Answers.map(answer => (
                <Grid.Column key={answer.id} onClick={() => answerClicked(answer)}>
                    {
                       this.colorAnswer(answer)
                    }
                    <Image alt={this.contentReplacer(answer.content)} class='answer-image' src={config.BASE_IMAGE_URL + "conditions/" + answer.imageName.replace('[manufacturer]', this.state.manufacturer.toLowerCase())} width="277" height="277"/>
                </Grid.Column>
            ))
        }

        return (
            this.state.question !== null ?
                <Grid class='question-answer-grid'>
                    <Grid.Row>

                        <div style={{'width': '100%'}}>
                            <div className='printer-cartridge-list-logo'/>
                            <div className='question-answer-wrapper'>
                                <Grid columns={this.state.question.Answers.length}>
                                    <Grid.Row class="question-title">
                                        <Grid.Column style={{'width':'100%'}}>
                                            <h1 class="question-title">{this.state.question.content.replace('[originalItemNumber]', this.state.originalItemNumber).replace('[manufacturer]', this.state.manufacturer)}</h1>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        {answerElements}
                                    </Grid.Row>
                                </Grid>
                            </div>
                        </div>
                    </Grid.Row>
                    <Grid.Row>
                    {(back === 1 || orderNumber !== 0) ?    
                        <span className='step-back-link' onClick={() => {
                            if (orderNumber === 0) {
                                history.goBack();
                            }
                            else {
                                questionBefore();
                            }
                        }}>
                        {t('stepBack')}
                        </span>
                    :
                        null
                    }
                    </Grid.Row>
                </Grid>
                :
                <div/>
        );
    }
}

QuestionAnswer.propTypes = {
    orderNumber: PropTypes.number,
    itemNumber: PropTypes.string,
    answerClicked: PropTypes.func,
    questionBefore: PropTypes.func,
    staticContext: PropTypes.object
};

QuestionAnswer.defaultProps = {
    orderNumber: 0,
    itemNumber: '',
    answerClicked: () => {},
    questionBefore: () => {},
    staticContext: {}
};