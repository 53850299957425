import React from 'react';
import {Grid, Icon} from "semantic-ui-react"
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import { withNamespaces } from 'react-i18next';
import {observer} from 'mobx-react';
import config from '../../config/main.config'

import uiStore from '../stores/uiStore'

@withNamespaces()
@observer
export default class BreadCrumbPrinterCartridge extends React.Component {

    componentDidMount() {
        if(typeof window !== "undefined") {
            let self = this;
            window.addEventListener("changeItemNumber", function(){
                self.setState({})
            });
        }

    }

    componentWillUnmount() {
        if(typeof window !== "undefined") {
            window.removeEventListener("changeItemNumber", function() { });
        }
    }

    render() {
        const {forceBottom, activeItem, t} = this.props;
        let lastTonerLink = "/hersteller-waehlen/";
        let lastTonerStateLink = "/hersteller-waehlen/";

        if(typeof localStorage !== "undefined") {

            let lastPrinterCartridge = JSON.parse(localStorage.getItem(config.STORAGE_KEY_SELECTED_PRINTER_CARTRIDGE));

            if(lastPrinterCartridge && lastPrinterCartridge.manufacturer && lastPrinterCartridge.series && lastPrinterCartridge.printer) {
                lastTonerLink = "/toner-finden/kartusche-waehlen/" + lastPrinterCartridge.manufacturer.replace(/\s/g, '_') + "/"
                    + lastPrinterCartridge.series.replace(/\s/g, '_') + "/" +
                    lastPrinterCartridge.printer.replace(/\s/g, '_');
            }

            if(lastPrinterCartridge && lastPrinterCartridge.itemNumber) {
                lastTonerStateLink = "/zustand-definieren/" + lastPrinterCartridge.itemNumber;
            }

        }

        return (
            <Grid class={'sales-steps-wrapper ' + (forceBottom && !uiStore.showBurger ? 'sales-step-wrapper-bottom' : 'sales-step-wrapper-relative-bottom')}>
                <Grid.Row>
                    <Grid.Column mobile={4} tablet={4} computer={4} class='sales-steps-wrapper-item'>
                        {/*<Link to="/hersteller-waehlen" class={activeItem !== null && activeItem === 'manufacturer' ? 'active' : 'inactive'}>*/}
                        <Link to="/hersteller-waehlen" class={'active'}>
                            <div class="icon-wrapper">
                                <div className='round-background'>
                                    <Icon name='barcode'/>
                                </div>
                            </div>
                            <span style={{'height':"35px"}}>{t('breadCrumb.manufacturer')}</span>
                        </Link>
                    </Grid.Column>
                    <Grid.Column mobile={4} tablet={4} computer={4} class={'sales-steps-wrapper-item ' + (activeItem !== null && activeItem === 'type' ? 'active' : 'inactive')}>
                        {/*<Link to={lastTonerLink} class={activeItem !== null && activeItem === 'manufacturer' ? 'active' : 'inactive'}>*/}
                        <Link to={lastTonerLink} class={'active'}>
                            <span style={{position: 'absolute', color: 'transparent'}}>second step</span>
                            <div className="icon-wrapper">
                                <div className='round-background'>
                                    <Icon name='eye'/>
                                </div>
                            </div>
                        </Link>
                        <span style={{'height':"35px"}}>{t('breadCrumb.model')}</span>
                    </Grid.Column>
                    <Grid.Column mobile={4} tablet={4} computer={4} class={'sales-steps-wrapper-item ' + (activeItem !== null && activeItem === 'state' ? 'active' : 'inactive')}>
                        {/*<Link to={lastTonerStateLink} class={activeItem !== null && activeItem === 'manufacturer' ? 'active' : 'inactive'}>*/}
                        <Link to={lastTonerStateLink} class={'active'}>
                        <span style={{position: 'absolute', color: 'transparent'}}>third step</span>
                            <div className="icon-wrapper">
                                <div className='round-background'>
                                    <Icon name='clipboard'/>
                                </div>
                            </div>
                        </Link>
                        <span style={{'height':"35px"}}>{t('breadCrumb.state')}</span>
                    </Grid.Column>
                    <Grid.Column mobile={4} tablet={4} computer={4} class={'sales-steps-wrapper-last-item ' + (activeItem !== null && activeItem === 'checkout' ? 'active' : 'inactive')}>
                        <Link to="/verkaufen" class={'active'}>
                            <div className="icon-wrapper">
                                <div className='round-background'>
                                    <Icon name='money'/>
                                </div>
                            </div>
                            <span style={{'height':"35px"}}>{t('breadCrumb.cash')}<br />{t('breadCrumb.taxInformation')}</span>
                        </Link>
                    </Grid.Column>
                </Grid.Row>

            </Grid>

        );
    }
}


BreadCrumbPrinterCartridge.propTypes = {
    forceBottom: PropTypes.bool,
    activeItem:PropTypes.string
};

BreadCrumbPrinterCartridge.defaultProps = {
    forceBottom: false,
    activeItem:null
};