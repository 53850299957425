import React from 'react';
import MetaTags from "../components/MetaTags"
import {Link} from "react-router-dom";


export default class Faq extends React.Component {

    render() {

        return (
            <div class="static-content">
                <MetaTags page="faq" /> 
                <div class="static-content-paragraph">
                    <h1>FAQ - Häufig gestellte Fragen</h1>
                    <p>Falls Sie noch nicht überzeugt sind, möchten wir Ihnen noch einmal kompakte Antworten auf die häufigsten Fragen zu unserem Angebot geben:</p>

                    <div itemScope itemType="https://schema.org/FAQPage">

                        <p><div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">								
                            <h3 itemProp="name">Welche Druckerpatronen kann ich verkaufen?</h3>								
                                                    
                        <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                            <div itemProp="text">Sie können alle unbenutzten, originalen Toner-, Tonerkartuschen und Trommeleinheiten von Herstellern wie HP, Brother, Canon, Dell, Ricoh, Epson, Kyocera, Samsung und Lexmark an die Toner Verwerter verkaufen.</div>
                        </div>								
                        </div></p>

                        <p><div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">								
                            <h3 itemProp="name">Wie viel Geld bekomme ich für die unbenutzte Kartuschen?</h3>								
                                                    
                        <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                            <div itemProp="text">Die Vergütung ist abhängig von der Qualität der Toner. Die Bewertungsrichtlinien unterscheiden sich außerdem je nach Hersteller und Modell. Eine pauschale Aussage über die Höhe der Vergütung können wir demnach nicht treffen. Durchlaufen Sie einfach unseren unkomplizierten Anfrageprozess, um noch heute ein Angebot für Ihre Druckerpatronen-Restbestände zu erhalten.</div>
                        </div>								
                        </div></p>

                        <p><div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">								
                            <h3 itemProp="name">Müssen die Toner originalverpackt sein, um Sie zu verkaufen?</h3>								
                                                    
                        <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                            <div itemProp="text">Nicht unbedingt! Handelt es sich bei Ihren Überbeständen um originalverpackte Toner, können Sie mit dem höchsten Erlös rechnen. Je nach Hersteller und Modell verwerten wir aber auch Druckerpatronen, die sich nicht mehr in ihrer Originalverpackung befinden. Starten Sie auch hier einfach unseren Anfrageprozess und wir teilen Ihnen sofort mit, ob wir das jeweilige Modell ankaufen und wie hoch die Vergütung ausfällt.</div>
                        </div>								
                        </div></p>

                        <p><div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">								
                            <h3 itemProp="name">Was passiert mit den vollen Druckerpatronen?</h3>								
                                                    
                        <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                            <div itemProp="text">Viele Verbraucher nutzen Drucker, für die bereits seit einigen Jahren keine neuen Tonerkartuschen hergestellt werden. Um diese Marktlücke zu schließen, haben wir uns dazu entschlossen, die bei uns vorhandene Infrastruktur zu nutzen, Restanten aufzukaufen und diese zu fairen Preisen weiterzuverkaufen. Damit schaffen wir eine Win-Win-Situation für alle Beteiligten.</div>
                        </div>								
                        </div></p>

                        <p><div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">								
                            <h3 itemProp="name">Gibt es versteckte Kosten und Abzüge?</h3>								
                                                    
                        <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                            <div itemProp="text">Nein! Unser Service ist transparent und ehrlich. Alle Ankaufbedingungen sind zu 100 % einsehbar. Wir möchten gerne langfristig mit unseren Kunden / Zulieferern zusammenarbeiten und könnten uns nicht erlauben, intransparent und unfair zu arbeiten. Der vereinbarte Betrag für Ihre vollen Druckerpatronen wird Ihnen zudem eins zu eins überwiesen - versteckte Kosten, Abzüge o.Ä. haben Sie nicht zu befürchten - Ehrenwort!</div>
                        </div>								
                        </div></p>

                        <p><div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">								
                            <h3 itemProp="name">Passen die alten Druckerpatronen nicht in meinen neuen Drucker?</h3>								
                                                    
                        <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                            <div itemProp="text">In manchen Fällen passen die Kartuschen eines Vorgängermodells auch ins Nachfolgemodell eines Druckers. An dieser Stelle müssen Sie die noch vollen Druckerpatronen natürlich nicht an uns verkaufen, sondern können diese im Neugerät verwenden. Sofern Sie bei der Anschaffung eines neuen Laserdruckers auf diesen Sachverhalt achten, können Sie sich richtig was sparen. Ist die Weiterverwendung der übrig gebliebenen Druckerpatronen jedoch nicht möglich, starten Sie unseren bequemen Anfrageprozess und verkaufen Sie Ihre vollen Druckerpatronen noch heute zu einem fairen Preis an die Toner Verwerter.</div>
                        </div>								
                        </div></p>

                        <p><div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">								
                            <h3 itemProp="name">Ist der Privatverkauf eine Alternative zur Entsorgung über die Toner Verwerter?</h3>								
                                                    
                        <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                            <div itemProp="text">Selbstverständlich können Sie Ihre Toner-Überbestände bzw. -Restbestände auch privat verkaufen. Allerdings ist es nicht immer ganz einfach, einen Abnehmer für das jeweilige Modell zu finden; besonders bei Tonern für ältere Druckermodelle. Bei uns bekommen Sie hingegen noch heute Angebot für Ihre vollen Toner, ohne Kleinanzeigen oder Ähnliches schalten zu müssen.</div>
                        </div>								
                        </div></p>

                        <p><div itemScope itemProp="mainEntity" itemType="https://schema.org/Question">								
                            <h3 itemProp="name">Warum kann ich den Toner Verwertern vertrauen?</h3>								
                                                    
                        <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                            <div itemProp="text">Seit 1998 gehören der Ankauf und die Verwertung von Drucker-Verbrauchsmaterial zu unserem Kerngeschäft. Neben einer Menge Erfahrung, unzähligen zufriedenen Kunden und Partnern sowie einer stets wachsenden Infrastruktur, sind wir zudem freiwilliger Teilnehmer am bayerischen Umweltpakt. Im Zuge dieser Teilnahme setzen wir uns z.B. mit der Verwertung von Tonern für Nachhaltigkeit und einen sorgsamen Umgang mit Ressourcen ein.</div>
                        </div>								
                        </div></p>

                    </div>

                    <br /><br /><p>Sie sind noch nicht wunschlos glücklich und haben weitere Fragen?. Kein Problem. Wir sind für Sie da. <a href="/impressum" title="Kontaktdaten der Sanocycling GmbH">Alle wichtigen Kontaktdaten finden Sie in unserem Impressum</a>.</p>

                </div>
            </div>
        );
    }
}