import config from '../../config/main.config'

export function isDevelop() {
    return process.env.NODE_ENV === 'development' || typeof process.env.NODE_ENV === 'undefined';
}

export function getTokenFromLocalStorage() {
    if (typeof localStorage !== "undefined") {
        let object = JSON.parse(localStorage.getItem(config.TOKEN_STORAGE_KEY));

        if (object === null) {
            return null;
        }

        let oneDayAfterTokenDate = new Date(object.timestamp);
        oneDayAfterTokenDate.setDate(oneDayAfterTokenDate.getDate() + 1);

        if (oneDayAfterTokenDate.getTime() > new Date().getTime()) {
            return object.token;
        } else {
            removeTokenFromStorage();
            return null;
        }
    }

    return null;

}


export function removeTokenFromStorage() {
    localStorage.removeItem(config.TOKEN_STORAGE_KEY);
}

export function setTokenLocalStorage(token) {
    let object = {
        "token": token,
        timestamp: new Date().getTime()
    };

    localStorage.setItem(config.TOKEN_STORAGE_KEY, JSON.stringify(object));
}

export function getStateLanguageString(result, t) {

    for (let prop in result) {
        switch (prop) {
            case 'closedPrice':
                return t('cashResult.closedPriceState');
            case 'openPrice':
                return t('cashResult.openPriceState');
            case 'trashPrice':
                return t('cashResult.trashPriceState');
        }
    }

    return t('cashResult.notDefinedState');
}

export function getStateLanguageStringFromState(state, t) {

    switch (state) {
        case 'closedPrice':
            return t('cashResult.closedPriceState');
        case 'openPrice':
            return t('cashResult.openPriceState');
        case 'trashPrice':
            return t('cashResult.trashPriceState');
    }

    return t('cashResult.notDefinedState');
}

export function validateEmail(email) {
    const re = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    return re.test(String(email).toLowerCase());
}

export function hasNoSpecialCharacters(string) {
    const re = /^[a-zA-Z0-9!@äöüß.,_ \-&]*$/;
    return re.test(String(string).toLowerCase());
}

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export function debounce(func, wait, immediate) {
    let timeout;
    return function () {
        let context = this, args = arguments;
        let later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        let callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

export function showBlobFile(blob, filename) {

    if (typeof window !== "undefined") {
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        let newBlob = new Blob([blob], {type: "application/pdf"});

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);
        let link = document.createElement('a');
        link.href = data;

        link.download = filename+ ".pdf";

        link.click();
        setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
        }, 100);
    }
}
