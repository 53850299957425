import React from 'react';
import {Button, Grid, Icon, Modal} from "semantic-ui-react"
import history from "../helper/browserHistory";
import {withNamespaces} from 'react-i18next';
import shoppingCartStore from "../stores/shoppingCartStore";
import Currency from 'react-currency-formatter';
import {getStateLanguageStringFromState} from "../helper/util";
import {observer} from 'mobx-react';
import * as mobx from "mobx"
import {observable, toJS} from "mobx"
import config from "../../config/main.config";
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import copy from 'copy-to-clipboard';

const CHAR_LINK_PREFIX = "https://dietonerverwerter.de/verkaufskorb/";

@withNamespaces()
@observer
export default class ShoppingCart extends React.Component {

    @observable
    chartLinkModalOpen = false;
    @observable
    chartLinkId = "";
    @observable
    linkCopied = false;

    constructor(props) {
        super(props);
        this.currentShoppingCartReaction = mobx.reaction(
            () => shoppingCartStore.showShoppingCart,
            () => {
                shoppingCartStore.shoppingCart;
            }, {
                equals: function (oldValue, newValue) {
                    // If value switches from false to true then we should check shopping cart
                    return oldValue && !newValue;
                }
            }
        );
    }

    componentWillUnmount() {
        this.currentShoppingCartReaction();
    }

    getSizeForCurrencyInItem(currencyInItem) {
        let fontSizeForCurrency = 1;
        let currency = Math.round(currencyInItem * 100) / 100;

        if (currency.toFixed(2).length >= 6) {
            fontSizeForCurrency = 0.8;
        }

        if (currency.toFixed(2).length >= 7) {
            fontSizeForCurrency = 0.6;
        }

        return fontSizeForCurrency;
    }

    createShoppingChartLink() {
        //get shopping chart from local storage
        let currentShoppingCart = shoppingCartStore._shoppingCart.filter(shopItem => shopItem.type === 'printerCartridge');
        let self = this;
        //send it to the server
        return fetch(config.BASE_URL + "shoppingChart/", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify(toJS(currentShoppingCart))
        })
            .then(response => {
                if (response.status >= 200 && response.status < 300) {
                    response.json().then(json => {
                        if (json.hasOwnProperty("chartId")) {
                            self.chartLinkId = json.chartId;
                            self.chartLinkModalOpen = true;
                        }

                    });

                } else {
                    //TODO: error modal
                }
            })
            .catch(
                error => {
                    //TODO: error modal
                }
            );

    }

    handleCopy() {
        copy(CHAR_LINK_PREFIX + this.chartLinkId);
        this.linkCopied = true;
        this.setState({});
    }

    render() {

        const {t} = this.props;

        let currentShoppingCart = shoppingCartStore._shoppingCart.filter(shopItem => shopItem.type === 'printerCartridge');
        let shoppingCartElements = [];

        let fontSizeForCurrency = 1.5;
        let currency = Math.round(shoppingCartStore.getSummaryOfPrices() * 100) / 100;

        if (currency.toFixed(2).length >= 6) {
            fontSizeForCurrency = 1.3;
        }

        if (currency.toFixed(2).length >= 7) {
            fontSizeForCurrency = 1.1;
        }

        if (currency.toFixed(2).length >= 8) {
            fontSizeForCurrency = 1.0;
        }


        if (currentShoppingCart !== null) {
            shoppingCartElements = currentShoppingCart.map((shoppingCartItem, index) => (
                <Grid.Row key={shoppingCartItem.printerCartridgeId + index}
                          class={index === (currentShoppingCart.length - 1) ? "shopping-cart-last-cell" : "shopping-cart-cell"}>
                    <Grid.Column style={{"textAlign": "left"}}>
                        <div>
                            {shoppingCartItem.manufacturer}
                        </div>
                        <div>
                            {shoppingCartItem.originalItemNumber}
                        </div>
                        <div style={{"fontWeight": "bold"}}>
                            {shoppingCartItem.amount} Stk. je
                            &nbsp;<Currency
                                quantity={parseFloat(shoppingCartItem.netPriceForOne)}
                                currency="EUR"
                            />
                        </div>
                        <div>
                            {getStateLanguageStringFromState(shoppingCartItem.state, t)}
                        </div>
                    </Grid.Column>
                    <Grid.Column style={{"margin": "auto", "display": "flex"}}>
                        <div style={{"textAlign": "right", "flex": 3}}>
                            <Button class='plus-minus-shop-button'
                                    onClick={() => shoppingCartStore.addAmountToItemIndex(index)}
                                    circular size='mini' icon='plus'/>
                            <Button class='plus-minus-shop-button'
                                    style={{"marginRight": "8px"}}
                                    onClick={() => shoppingCartStore.subtractAmountToItemIndex(index)}
                                    circular size='mini' icon='minus'/>

                        </div>
                        <div style={{
                            "textAlign": "right",
                            "flex": 2,
                            fontSize: (this.getSizeForCurrencyInItem(shoppingCartItem.netPrice) + "em"),
                            "margin": "auto"
                        }}>
                            <Currency
                                quantity={parseFloat(shoppingCartItem.netPrice)}
                                currency="EUR"
                            />
                        </div>
                    </Grid.Column>
                </Grid.Row>
            ))
        }

        return (
            <Grid style={{"margin": 0, "overflowX": "hidden", "height": "100%"}}>
                <Grid.Row class='close-row' onClick={() => shoppingCartStore.showShoppingCart = false}>
                    <Grid.Column width={12} class='close-title-column'>
                        {t("shoppingCart.title")}
                    </Grid.Column>
                    <Grid.Column width={4} class='close-icon-column'>
                        <Icon name='close'/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row class="shopping-detail-row">
                    {
                        currentShoppingCart === null || currentShoppingCart.length === 0 ?
                            <Grid style={{"margin": 0, "padding": 0}}>
                                <Grid.Row>
                                    <Grid.Column>
                                        <div className='gradient-round-big'>
                                            <Icon class="cash-result-end-shopping-icon" name="shopping cart"/>
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <div style={{'width': '100%', 'textAlign': 'center'}}>
                                        {t("shoppingCart.empty")}
                                    </div>

                                </Grid.Row>
                            </Grid>
                            :
                            <Grid columns={2} style={{"margin": 0, "padding": 0, "paddingBottom": 70}}>
                                <Grid.Row>
                                    <Grid.Column width={10} style={{"textAlign": "left"}}>
                                        {t("shoppingCart.summaryPrices")}
                                    </Grid.Column>
                                    <Grid.Column width={6} style={{fontSize: fontSizeForCurrency + "em"}}>
                                        <Currency
                                            quantity={parseFloat(shoppingCartStore.getSummaryOfPrices())}
                                            currency="EUR"
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row style={{"paddingBottom": 0}}>
                                    <Button.Group style={{"width": "100%", "height": "45px"}}>
                                        <Button style={{"borderRadius": 0}} onClick={() => {
                                            shoppingCartStore.showShoppingCart = false;
                                            history.push('/hersteller-waehlen')
                                        }}>
                                            {t("sellMore")}
                                        </Button>
                                        <Button style={{"borderRadius": 0}}
                                                disabled={shoppingCartStore.getSummaryOfPrices() <= 0}
                                                onClick={() => {
                                                    shoppingCartStore.showShoppingCart = false;
                                                    history.push('/verkaufen')
                                                }}
                                                class="white button primary">
                                            {t("toCheckout")}
                                        </Button>
                                    </Button.Group>
                                </Grid.Row>
                                {
                                    shoppingCartStore.getSummaryOfPrices() <= 0 ?
                                        <Grid.Row class="shopping-cart-information-row">
                                            <div class="shopping-cart-information-wrapper">
                                                <div>
                                                    <Icon name="info"/>
                                                </div>
                                                <div>
                                                    {t("shoppingCart.minusMessage")}
                                                </div>
                                            </div>
                                        </Grid.Row>
                                        :
                                        null
                                }

                                            {shoppingCartElements}
                                <Grid.Row class="remove-button-row">
                                    <Modal trigger={
                                        <Button
                                            onClick={() => this.createShoppingChartLink()}
                                            class="white button primary remove-shopping-button"
                                            style={{marginBottom: "10px"}}>
                                            {t("exportShoppingCart")}
                                        </Button>}
                                           size='small'
                                           open={this.chartLinkModalOpen}
                                           onClose={() => this.chartLinkModalOpen = false}
                                           className={"chart-copy-link"}>
                                        <Modal.Header content={t("share_chart")}/>
                                        <Modal.Content scrolling>
                                            <Segment
                                                onClick={() => this.handleCopy()}
                                                style={{cursor: "pointer"}}>
                                                <p>
                                                    {CHAR_LINK_PREFIX + this.chartLinkId}
                                                </p>
                                            </Segment>
                                            <Grid.Row>
                                                {t("share_chart_valid")}
                                            </Grid.Row>
                                        </Modal.Content>
                                        <Modal.Actions>
                                            <Button primary
                                                    onClick={() => this.handleCopy()}
                                            >{this.linkCopied ? t("copiedClipboard") : t("copyToClipboard")}</Button>
                                            <Button
                                                className={"close-modal"}
                                                onClick={() => this.chartLinkModalOpen = false}>
                                                {t("close")}
                                            </Button>

                                        </Modal.Actions>
                                    </Modal>

                                    <Button
                                        onClick={() => {
                                            shoppingCartStore.removeShoppingCart()
                                        }}
                                        class="white button primary remove-shopping-button">
                                        {t("removeShoppingCart")}
                                    </Button>
                                </Grid.Row>
                            </Grid>
                    }
                </Grid.Row>
            </Grid>
        )
    }
}