import React from 'react';
import {Segment, Button, Grid} from "semantic-ui-react"

import config from '../../config/main.config'
import BreadCrumbPrinterCartridge from "../components/BreadCrumbPrinterCartridge";
import history from "../helper/browserHistory"
import {withNamespaces} from 'react-i18next';
import Currency from 'react-currency-formatter';
import shoppingCartStore from '../stores/shoppingCartStore'
import {getStateLanguageString, getTokenFromLocalStorage} from '../helper/util'
import MetaTags from "../components/MetaTags"
import TextHelper from '../components/TextHelper';


@withNamespaces()
export default class CashResult extends React.Component {

    constructor(props) {
        super(props);
        if (props.staticContext && props.staticContext[0]) {

            let result = props.staticContext[0];
            if(
                result.hasOwnProperty("closedPrice") ||
                result.hasOwnProperty("openPrice") ||
                result.hasOwnProperty("trashPrice")
            ) {

                let netPrice = 0;

                for(let prop in result) {
                    if(prop === "closedPrice" || prop === "openPrice" || prop === "trashPrice") {
                        netPrice = result[prop];
                    }
                }

                this.state = {
                    result: result,
                    amount: 1,
                    netPrice: netPrice,
                    width: 0
                }
            }
            else {
                this.state = {
                    result: null,
                    amount: 1,
                    netPrice: 0,
                    width: 0
                }
            }

        } else {
            this.state = {
                result: null,
                amount: 1,
                netPrice: 0,
                width: 0
            }
        }

    }

    handleOnUpdate = (e, { width }) => {
        this.setState({ ...this.state, width:width })
    }

    componentDidMount() {
        const {itemNumber, state} = this.props.match.params;

        if (typeof itemNumber !== 'undefined' && typeof state !== 'undefined' && localStorage) {
            const userToken = getTokenFromLocalStorage();

            let headers = config.HEADERS;
            if(userToken != null){
                headers.token =  userToken
            }

            fetch(config.BASE_URL + "printerCartridgePrices/?state=" + state + "&itemNumber=" + itemNumber + "&sendError=true", {
                method: 'GET',
                headers: headers
            }).then((response) => {
                if (response.status >= 200 && response.status < 400) {
                    response.json().then(json => {
                        
                        if (json.length === 0) {
                            //20190717 - Fallback for unkown "trash" articles:
                            state === "trashPrice" ?
                                this.setState({
                                    netPrice: "-999"
                                })
                            :
                                history.replace('/verkaufspreis-nicht-ermittelt')
                        } else {
                            let netPrice = 0;

                            if(json[0] !== null) {
                                for(let prop in json[0]) {
                                    if(prop === "closedPrice" || prop === "openPrice" || prop === "trashPrice") {
                                        netPrice = json[0][prop];
                                    }
                                }
                            }


                            if(netPrice === null) {
                                history.replace('/verkaufspreis-nicht-ermittelt');
                            }
                            else {
                                this.setState({
                                    result: json[0],
                                    amount: 1,
                                    netPrice: netPrice
                                })
                            }

                        }

                    });

                } else {
                    history.replace('/verkaufspreis-nicht-ermittelt');
                }

            }).catch(error => {
                history.replace('/verkaufspreis-nicht-ermittelt');
            });
        } else {
            history.replace('/verkaufspreis-nicht-ermittelt');
        }
    }

    getState(result) {

        for(let prop in result) {
            switch(prop) {
                case 'closedPrice':
                    return 'closedPrice';
                case 'openPrice':
                    return 'openPrice';
                case 'trashPrice':
                    return 'trashPrice';
            }
        }

        return "undefinedState";
    }

    addItemToShoppingCart() {
        //if(this.state.netPrice !== 0 && this.state.netPrice !== null) {
        if(this.state.netPrice !== null) {
            shoppingCartStore.addToShoppingCart({
                "manufacturer":this.state.result.manufacturer,
                "amount":this.state.amount,
                "type":"printerCartridge",
                "state":this.getState(this.state.result),
                "price":this.state.netPrice,
                "netPriceForOne":this.state.netPrice,
                "originalItemNumber":this.state.result.originalItemNumber,
                "itemNumber":this.state.result.itemNumber,
                "id":this.state.result.id
            });
            shoppingCartStore.showShoppingCart = true;
            history.push("/toner-im-verkaufskorb/" + this.state.result.itemNumber);
        }

    }

    render() {

        const {t} = this.props;

        let forceBreadCrumbToBottom = true;

       /* if(this.state.width <= Responsive.onlyMobile.maxWidth && this.state.width < Responsive.onlyComputer.minWidth) {
            forceBreadCrumbToBottom = false
        }*/

        let fontSizeForCurrency = 3;
        let currency = Math.round((this.state.netPrice*100) * this.state.amount)/100;

        if(currency.toFixed(2).length >= 6) {
            fontSizeForCurrency = 2.5;
        }

        if(currency.toFixed(2).length >= 7) {
            fontSizeForCurrency = 2;
        }

        if(currency.toFixed(2).length >= 8) {
            fontSizeForCurrency = 1.5;
        }

        return (
            <Grid stackable class='main-grid'>
                <MetaTags page="kassieren" />
                <Grid.Row class='main-grid-header-row'>
                    <Grid.Column>
                        <h2 className="fira big">
                        {
                            parseFloat(this.state.netPrice) !== -999 ?
                                t('cashResult.headline')
                            :
                                t('cashResultTrash.headline')
                        }
                        </h2>
                        <span className="fira small block">
                        {
                            parseFloat(this.state.netPrice) !== -999 ?
                                t('cashResult.subheadline')
                            :
                                t('cashResultTrash.subheadline')
                        }
                        </span>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{"paddingBottom": 0}}>
                    <div className='printer-cartridge-list-logo'/>
                </Grid.Row>
                {
                    parseFloat(this.state.netPrice) !== -999 ?
                        <Grid.Row class='cash-result-row main-grid-main-row' style={{"paddingTop": 0}}>
                            <Grid.Column width={11} className='result-wrapper'>
                                <h1 class="result-headline">
                                    {t('cashResult.resultHeadline')}
                                </h1>
                                {
                                    this.state.result !== null ?
                                        <table class="result-table">
                                            <tbody>
                                            <tr>
                                                <td style={{"width": "40%"}}>
                                                    {t('manufacturer_colon')}
                                                </td>
                                                <td style={{"width": "60%"}}>
                                                    {this.state.result.manufacturer}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{"width": "40%"}}>
                                                    {t('itemNumber_colon')}
                                                </td>
                                                <td style={{"width": "60%"}}>
                                                    {this.state.result.originalItemNumber}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{"width": "40%"}}>
                                                    {t('state_colon')}
                                                </td>
                                                <td style={{"width": "60%"}}>
                                                    {
                                                        getStateLanguageString(this.state.result, t)
                                                    }
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        :
                                        null
                                }
                            </Grid.Column>
                            <Grid.Column width={5} class="cash-wrapper">
                                <div className='gradient-round-big'>
                                    <span class="currency-span" style={{fontSize: fontSizeForCurrency + "em"}}>
                                        <Currency
                                            quantity={this.state.netPrice * this.state.amount}
                                            currency="EUR"
                                            pattern="#,##0.00"
                                        />
                                    </span>
                                </div>
                                <div className="cash-amount">
                                    {t('amount_colon')} {this.state.amount}
                                </div>
                                <Button.Group style={{'width':'100%', 'marginBottom': '48px'}}>
                                    <Button class='plus-minus-button' icon='plus' onClick={() => {
                                        this.setState({
                                            ...this.state,
                                            amount: this.state.amount + 1
                                        })
                                    }}/>
                                    <Button class='plus-minus-button' icon='minus' onClick={() => {
                                        if(this.state.amount > 1) {
                                            this.setState({
                                                ...this.state,
                                                amount: this.state.amount - 1
                                            })
                                        }
                                    }}/>
                                </Button.Group>
                                <Button class='white to-shopping-cart-button' onClick={this.addItemToShoppingCart.bind(this)}>
                                    {t('cashResult.toShoppingCart')}
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                        :
                        <Grid.Row class='cash-result-row main-grid-main-row' style={{"paddingTop": 0}}>
                            <Grid.Column width={16} className='result-wrapper'>
                                <TextHelper f="cashResultTrash"/>                                   
                                
                                <Button class='buy-more-button' onClick={() => history.push('/hersteller-waehlen')}>
                                    {t("sellOther")}
                                </Button>            
                            </Grid.Column>
                        </Grid.Row>

                }

                <Grid.Row>
                        <span className='step-back-link' onClick={() => {
                            const {itemNumber} = this.props.match.params;

                            // TODO: Checken wenn getItem fehlschlägt

                            if (typeof itemNumber !== 'undefined') {
                                history.replace('/zustand-definieren/' + itemNumber)
                            }

                        }}>
                         {t('stepBack')}
                        </span>
                </Grid.Row>
                <Segment as={Grid.Row} fireOnMount onUpdate={this.handleOnUpdate} class='defineState-breadcrumb-wrapper main-grid-footer-row'>
                    <BreadCrumbPrinterCartridge
                        activeItem='checkout'
                        forceBottom={forceBreadCrumbToBottom}/>
                </Segment>
            </Grid>
        );
    }
}