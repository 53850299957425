import 'whatwg-fetch';
import 'babel-polyfill';
import React from "react"
import {observer} from "mobx-react";
import {Menu, Segment, Sidebar} from "semantic-ui-react"
import {Route} from 'react-router-dom'
import Header from "../components/Header";
import {withNamespaces} from 'react-i18next';
import ShoppingCart from '../components/ShoppingCart'
import shoppingCartStore from '../stores/shoppingCartStore';
import {renderRoutes} from "react-router-config";
import routes from "../../../shared/routes";
import BlogArticlePageMeta from "./BlogArticlePageMeta";

if (typeof document !== "undefined") {
    // Normalize
    require('../../stylesheets/normalize.css');

    // Require scss files
    require('../../stylesheets/_all.scss');
}

@withNamespaces()
@observer
export default class Layout extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            clientWidth: 10000
        }
    }

    componentDidMount() {
        this.setState({
            clientWidth: this.getWidth()
        })

    }

    getWidth() {

        if (typeof document !== "undefined") {
            return Math.max(
                document.body.scrollWidth,
                document.documentElement.scrollWidth,
                document.body.offsetWidth,
                document.documentElement.offsetWidth,
                document.documentElement.clientWidth
            );
        }

        return 10000;


    }

    //TODO: Scroll nicht vergessen
    render() {
        const {t} = this.props;

        let isSafari = false;

        if (typeof window !== "undefined") {
            isSafari = /constructor/i.test(window.HTMLElement) || (function (p) {
                return p.toString() === "[object SafariRemoteNotification]";
            })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
        }

        const safariStyle = {width: '100%'};

        return (
            <Sidebar.Pushable as={Segment}>
                <Sidebar
                    as={Menu}
                    animation='overlay'
                    icon='labeled'
                    direction='right'
                    vertical
                    onHide={() => shoppingCartStore.showShoppingCart = false}
                    visible={shoppingCartStore.showShoppingCart}
                    width='wide'
                >
                    <ShoppingCart/>
                </Sidebar>

                <Sidebar.Pusher dimmed={shoppingCartStore.showShoppingCart}>
                    <div class="layout-wrapper">
                        <div class="layout-header">
                            <Header/>
                        </div>
                        <div class="layout-main" style={isSafari ? safariStyle : {}}>
                            {renderRoutes(routes)}
                            <Route path="/voll-dabei/:url" component={BlogArticlePageMeta}/>
                        </div>
                        <div id="erich"/>
                        <div class="pe-richsnippets"/>
                    </div>
                </Sidebar.Pusher>
            </Sidebar.Pushable>
        );
    }
}