import React from 'react';
import {Grid} from "semantic-ui-react"
import PrinterCartridgeTypeList from "../components/PrinterCartridgeTypeList";
import config from '../../config/main.config'
import BreadCrumbPrinterCartridge from "../components/BreadCrumbPrinterCartridge";
import history from "../helper/browserHistory"
import {withNamespaces} from 'react-i18next';
import {getTokenFromLocalStorage} from "../helper/util";


@withNamespaces()
export default class PrinterCartridgeManufacturerSelection extends React.Component {

    constructor(props) {
        super(props);
        if (props.staticContext) {

            let formatManufacturer = [];
            for(let prop in props.staticContext) {
                if(props.staticContext[prop].hasOwnProperty("manufacturer")) {
                    formatManufacturer.push(props.staticContext[prop].manufacturer)
                }
            }

            this.state = {
                manufacturers: formatManufacturer
            };

        } else {
            this.state = {
                manufacturers: []
            };
        }

    }

    componentDidMount() {
        //Send token if logged in to get also ink results
        const userToken = getTokenFromLocalStorage();
        let headers = config.HEADERS;
        if(userToken != null){
            headers.token =  userToken
        }
        fetch(config.BASE_URL + "printerCartridges/manufacturer", {
            method: 'GET',
            headers: headers
        }).then((response) => {
            if (response.status >= 200 && response.status < 400) {
                response.json().then(json => {
                    if(json.length === 0) {
                        history.replace('/toner-nicht-gefunden');
                    }
                    else {
                        this.setState({
                            manufacturers: json.map(result => result.manufacturer)
                        });
                    }

                });

            } else {
                history.replace('/toner-nicht-gefunden');
            }

        }).catch(error => {
            history.replace('/toner-nicht-gefunden');
        });
    }

    render() {
        const {t, without} = this.props;
        //console.log(without)

        let bc = 1;
        if(without === 1){
            bc = 0
        }
       

        return (
            <Grid stackable class='main-grid'>
                <Grid.Row class='main-grid-main-row'>
                {/*need to set a link for (google) sitemap!*/}
                <PrinterCartridgeTypeList data={this.state.manufacturers}
                    showBackLink={false}
                    aHrefItem={(manufacturer) => {
                        let replacedManufacturer = manufacturer.replace(/\s/g, '_');
                        return ('/toner-finden/serie-waehlen/' + encodeURI(replacedManufacturer))
                        }
                    }
                    searchAvailable="0"
                     notFound = {{text: t('printerCartridge.manufacturer.notFound'), link: "/nicht-aufgelistet"}}
                />
                </Grid.Row>
                {bc === 1 ?
                    <Grid.Row class='bread-crumb-row main-grid-footer-row'>
                    <BreadCrumbPrinterCartridge
                        activeItem='manufacturer'
                        forceBottom={true}/>
                    </Grid.Row>
                : 
                    null
                }
            </Grid>
       
        );
    }
}