import {action, computed, observable} from 'mobx';
import config from "../../config/main.config";

class ShoppingCartStore {
    @observable showShoppingCart = false;
    @observable error = '';
    @observable _shoppingCart = [];
    @observable loading = false;

    @action addToShoppingCart(item) {
        let netPrice = item.price;

        let netPriceWithAmount = 0;
        //let grossPriceWithAmount = 0;

        if (netPrice !== 0) {
            netPrice = Math.round(Number(netPrice.toFixed(2)) * 100) / 100;

            netPriceWithAmount = Math.round(Number(netPrice.toFixed(2)) * item.amount * 100) / 100;
            //  grossPriceWithAmount = Math.round(Number(netPrice.toFixed(2)) * item.amount * 1.19 * 100) / 100;
        }

        /* "grossPrice": grossPriceWithAmount, */
        let itemToAdd = {
            "amount": item.amount,
            "type": item.type,
            "state": item.state,
            "manufacturer": item.manufacturer,
            "netPrice": netPriceWithAmount,
            "printerCartridgeId": item.id,
            "netPriceForOne": item.netPriceForOne,
            "originalItemNumber": item.originalItemNumber,
            "itemNumber": item.itemNumber,
            "createdAt": new Date(),
            "updatedAt": new Date()
        };

        if (typeof localStorage !== 'undefined') {
            const shoppingCartFromLocalStorage = JSON.parse(localStorage.getItem(config.STORAGE_KEY_SHOPPING_CART));

            if (shoppingCartFromLocalStorage !== null && typeof shoppingCartFromLocalStorage !== "undefined" && shoppingCartFromLocalStorage.length > 0 && this._shoppingCart.length === 0) {
                this._shoppingCart = shoppingCartFromLocalStorage;
            }
        }

        if (this._shoppingCart.length === 0 || item.type === "dhlPostageStamp") {
            this._shoppingCart.push(itemToAdd)
        } else {
            let foundIndex = this._shoppingCart.findIndex(shoppingItem => {
                return shoppingItem.printerCartridgeId === item.id && shoppingItem.state === item.state;
            });

            if (foundIndex > -1) {
                this._shoppingCart[foundIndex].amount += item.amount;
                if (netPrice !== null && netPrice !== 0 && typeof netPrice !== "undefined") {
                    this._shoppingCart[foundIndex].netPrice = Math.round(Number(netPrice.toFixed(2)) * this._shoppingCart[foundIndex].amount * 100) / 100;
                    //this._shoppingCart[foundIndex].grossPrice = Math.round(Number(netPrice.toFixed(2)) * this._shoppingCart[foundIndex].amount * 1.19 * 100) / 100;
                }

                this._shoppingCart[foundIndex].updatedAt = new Date();
            } else {
                this._shoppingCart.push(itemToAdd);
            }

        }

        localStorage.setItem(config.STORAGE_KEY_SHOPPING_CART, JSON.stringify(this._shoppingCart));
    }

    removeAllDHLPostageStamps() {
        if (typeof localStorage !== 'undefined') {
            const shoppingCartFromLocalStorage = JSON.parse(localStorage.getItem(config.STORAGE_KEY_SHOPPING_CART));

            if (shoppingCartFromLocalStorage !== null && typeof shoppingCartFromLocalStorage !== "undefined" && shoppingCartFromLocalStorage.length > 0 && this._shoppingCart.length === 0) {
                this._shoppingCart = shoppingCartFromLocalStorage;
            }

            if (this._shoppingCart.length > 0) {
                this._shoppingCart = this._shoppingCart.filter(shopItem => shopItem.type === 'printerCartridge')
                localStorage.setItem(config.STORAGE_KEY_SHOPPING_CART, JSON.stringify(this._shoppingCart));
            }
        }

    }

    getDHLPostageStampAmounts() {
        if (typeof localStorage !== 'undefined') {
            const shoppingCartFromLocalStorage = JSON.parse(localStorage.getItem(config.STORAGE_KEY_SHOPPING_CART));

            if (shoppingCartFromLocalStorage !== null && typeof shoppingCartFromLocalStorage !== "undefined" && shoppingCartFromLocalStorage.length > 0 && this._shoppingCart.length === 0) {
                this._shoppingCart = shoppingCartFromLocalStorage;
            }
        }

        if (this._shoppingCart.length > 0) {
            const dhlPostageItems = this._shoppingCart.filter(shopItem => shopItem.type === 'dhlPostageStamp');
            return dhlPostageItems.length;
        }

        return 0;
    }

    setAmountOfDHLPostageStamp(amount) {
        if (typeof localStorage !== 'undefined') {
            const shoppingCartFromLocalStorage = JSON.parse(localStorage.getItem(config.STORAGE_KEY_SHOPPING_CART));

            if (shoppingCartFromLocalStorage !== null && typeof shoppingCartFromLocalStorage !== "undefined" && shoppingCartFromLocalStorage.length > 0 && this._shoppingCart.length === 0) {
                this._shoppingCart = shoppingCartFromLocalStorage;
            }
        }

        if (this._shoppingCart.length > 0) {

            this.removeAllDHLPostageStamps();

            for (let i = 0; i < amount; i++) {
                this.addToShoppingCart({
                    "manufacturer": "",
                    "amount": 1,
                    "type": "dhlPostageStamp",
                    "state": "",
                    "price": 6.00,
                    "netPriceForOne": 6.00,
                    "originalItemNumber": "",
                    "itemNumber": "",
                    "id": ""
                })
            }

            localStorage.setItem(config.STORAGE_KEY_SHOPPING_CART, JSON.stringify(this._shoppingCart));
        }
    }

    getSummaryOfItems() {
        let summaryAmount = 0;
        if (typeof localStorage !== 'undefined') {
            const shoppingCartFromLocalStorage = JSON.parse(localStorage.getItem(config.STORAGE_KEY_SHOPPING_CART));
            let shoppingCartToCheck = this._shoppingCart;

            if (shoppingCartFromLocalStorage !== null && typeof shoppingCartFromLocalStorage !== "undefined" && shoppingCartFromLocalStorage.length > 0 && this._shoppingCart.length === 0) {
                shoppingCartToCheck = shoppingCartFromLocalStorage;
            }

            if (shoppingCartToCheck.length > 0) {
                for (let i = 0; i < shoppingCartToCheck.length; i++) {
                    if (shoppingCartToCheck[i].type === "printerCartridge") {
                        summaryAmount += shoppingCartToCheck[i].amount;
                    }

                }
            }
        }

        return summaryAmount;
    }

    getDhlPostageSummary() {
        let summaryPrice = 0;

        if (typeof localStorage !== 'undefined') {
            const shoppingCartFromLocalStorage = JSON.parse(localStorage.getItem(config.STORAGE_KEY_SHOPPING_CART));

            if (shoppingCartFromLocalStorage !== null && typeof shoppingCartFromLocalStorage !== "undefined" && shoppingCartFromLocalStorage.length > 0 && this._shoppingCart.length === 0) {
                this._shoppingCart = shoppingCartFromLocalStorage;
            }
        }

        if (this._shoppingCart.length > 0) {
            const dhlPostageStamps = this._shoppingCart.filter(shopItem => shopItem.type === 'dhlPostageStamp');

            if (dhlPostageStamps.length > 0) {

                for (let i = 0; i < dhlPostageStamps.length; i++) {
                    //summaryPrice += Math.round(Number(dhlPostageStamps[i].netPriceForOne.toFixed(2)) * 1.19 * 100 * dhlPostageStamps[i].amount) / 100;
                    summaryPrice += Number(dhlPostageStamps[i].netPriceForOne.toFixed(2)) * dhlPostageStamps[i].amount;
                }

            }

        }

        return summaryPrice;
    }

    /* getTaxOfDhlPostageStamp() {
         let tax = 0;

         if (typeof localStorage !== 'undefined') {
             const shoppingCartFromLocalStorage = JSON.parse(localStorage.getItem(config.STORAGE_KEY_SHOPPING_CART));

             if (shoppingCartFromLocalStorage !== null && typeof shoppingCartFromLocalStorage !== "undefined" && shoppingCartFromLocalStorage.length > 0 && this._shoppingCart.length === 0) {
                 this._shoppingCart = shoppingCartFromLocalStorage;
             }
         }

         if (this._shoppingCart.length > 0) {
             const dhlPostageStamps = this._shoppingCart.filter(shopItem => shopItem.type === 'dhlPostageStamp');

             if (dhlPostageStamps.length > 0) {

                 for (let i = 0; i < dhlPostageStamps.length; i++) {
                     tax += Math.round(((Number(dhlPostageStamps[i].grossPrice.toFixed(2)) - Number(dhlPostageStamps[i].netPrice.toFixed(2))) * 100)) / 100;
                 }


             }
         }

         return tax;
     }*/

    /* getTaxOfSummaryPrice() {
         let tax = 0;

         if (typeof localStorage !== 'undefined') {
             const shoppingCartFromLocalStorage = JSON.parse(localStorage.getItem(config.STORAGE_KEY_SHOPPING_CART));

             if (shoppingCartFromLocalStorage !== null && typeof shoppingCartFromLocalStorage !== "undefined" && shoppingCartFromLocalStorage.length > 0 && this._shoppingCart.length === 0) {
                 this._shoppingCart = shoppingCartFromLocalStorage;
             }
         }

         if (this._shoppingCart.length > 0) {
             const printerCartridges = this._shoppingCart.filter(shopItem => shopItem.type === 'printerCartridge');

             for (let i = 0; i < printerCartridges.length; i++) {
                 tax += Math.round(((Number(printerCartridges[i].grossPrice.toFixed(2)) - Number(printerCartridges[i].netPrice.toFixed(2))) * 100)) / 100;
             }
         }

         return tax;
     }*/

    getSummaryOfPrinterCartridges() {
        let summaryPrice = 0;

        if (typeof localStorage !== 'undefined') {
            const shoppingCartFromLocalStorage = JSON.parse(localStorage.getItem(config.STORAGE_KEY_SHOPPING_CART));

            if (shoppingCartFromLocalStorage !== null && typeof shoppingCartFromLocalStorage !== "undefined" && shoppingCartFromLocalStorage.length > 0 && this._shoppingCart.length === 0) {
                this._shoppingCart = shoppingCartFromLocalStorage;
            }
        }

        if (this._shoppingCart.length > 0) {
            const printerCartridges = this._shoppingCart.filter(shopItem => shopItem.type === 'printerCartridge');

            for (let i = 0; i < printerCartridges.length; i++) {
                summaryPrice += Number(printerCartridges[i].netPrice.toFixed(2));
            }

        }

        return summaryPrice;
    }

    removeTrashPrices() {

        let areThereTrashPrices = false;

        if (typeof localStorage !== 'undefined') {
            const shoppingCartFromLocalStorage = JSON.parse(localStorage.getItem(config.STORAGE_KEY_SHOPPING_CART));

            if (shoppingCartFromLocalStorage !== null && typeof shoppingCartFromLocalStorage !== "undefined" && shoppingCartFromLocalStorage.length > 0 && this._shoppingCart.length === 0) {
                this._shoppingCart = shoppingCartFromLocalStorage;
            }

            if (this._shoppingCart.length > 0) {
                areThereTrashPrices = this._shoppingCart.filter(shopItem => shopItem.state === 'trashPrice').length > 0;

                this._shoppingCart = this._shoppingCart.filter(shopItem => shopItem.state !== 'trashPrice');
                localStorage.setItem(config.STORAGE_KEY_SHOPPING_CART, JSON.stringify(this._shoppingCart));
            }
        }

        return areThereTrashPrices;
    }

    getSummaryOfPrices(isPrivatePerson = true) {
        let summaryPrice = 0;

        if (typeof localStorage !== 'undefined') {
            const shoppingCartFromLocalStorage = JSON.parse(localStorage.getItem(config.STORAGE_KEY_SHOPPING_CART));

            if (shoppingCartFromLocalStorage !== null && typeof shoppingCartFromLocalStorage !== "undefined" && shoppingCartFromLocalStorage.length > 0 && shoppingCartFromLocalStorage.length > 0 && this._shoppingCart.length === 0) {
                this._shoppingCart = shoppingCartFromLocalStorage;
            }
        }

        if (this._shoppingCart.length > 0) {
            const printerCartridges = this._shoppingCart.filter(shopItem => shopItem.type === 'printerCartridge');
            const dhlPostageStamps = this._shoppingCart.filter(shopItem => shopItem.type === 'dhlPostageStamp');

            for (let i = 0; i < printerCartridges.length; i++) {
                // if (isPrivatePerson) {
                summaryPrice += Number(printerCartridges[i].netPrice.toFixed(2));
                //} else {
                // summaryPrice += Number(printerCartridges[i].grossPrice.toFixed(2));
                // }

            }

            for (let i = 0; i < dhlPostageStamps.length; i++) {
                summaryPrice -= Number(dhlPostageStamps[i].netPrice.toFixed(2));
            }
        }

        //return summaryPrice;
        //20190128 workaround
        return summaryPrice.toFixed(2);
    }

    getSummaryOfPricesWithoutDHLPostageStamp(isPrivatePerson = true) {
        let summaryPrice = 0;

        if (typeof localStorage !== 'undefined') {
            const shoppingCartFromLocalStorage = JSON.parse(localStorage.getItem(config.STORAGE_KEY_SHOPPING_CART));

            if (shoppingCartFromLocalStorage !== null && typeof shoppingCartFromLocalStorage !== "undefined" && shoppingCartFromLocalStorage.length > 0 && this._shoppingCart.length === 0) {
                this._shoppingCart = shoppingCartFromLocalStorage;
            }
        }

        if (this._shoppingCart.length > 0) {
            const printerCartridges = this._shoppingCart.filter(shopItem => shopItem.type === 'printerCartridge');

            for (let i = 0; i < printerCartridges.length; i++) {
                // if (isPrivatePerson) {
                summaryPrice += Number(printerCartridges[i].netPrice.toFixed(2));
                /* } else {
                     summaryPrice += Number(printerCartridges[i].grossPrice.toFixed(2));
                 }*/

            }
        }

        //return summaryPrice;
        //201028 workaround
        return summaryPrice.toFixed(2);
    }

    @action addAmountToItemIndex(index) {
        if (typeof localStorage !== 'undefined') {
            const shoppingCartFromLocalStorage = JSON.parse(localStorage.getItem(config.STORAGE_KEY_SHOPPING_CART));

            if (shoppingCartFromLocalStorage !== null && typeof shoppingCartFromLocalStorage !== "undefined" && shoppingCartFromLocalStorage.length > 0 && this._shoppingCart.length === 0) {
                this._shoppingCart = shoppingCartFromLocalStorage;
            }
        }

        if (this._shoppingCart.length > 0) {
            this._shoppingCart[index].amount++;
            this._shoppingCart[index].netPrice = Math.round(Number(this._shoppingCart[index].netPriceForOne.toFixed(2)) * Number(this._shoppingCart[index].amount.toFixed(2)) * 100) / 100;
            // this._shoppingCart[index].grossPrice = Math.round(Number(this._shoppingCart[index].netPriceForOne.toFixed(2)) * Number(this._shoppingCart[index].amount.toFixed(2)) * 1.19 * 100) / 100;
            this._shoppingCart[index].updatedAt = new Date();
            localStorage.setItem(config.STORAGE_KEY_SHOPPING_CART, JSON.stringify(this._shoppingCart));
        }
    }

    @action subtractAmountToItemIndex(index) {
        if (typeof localStorage !== 'undefined') {
            const shoppingCartFromLocalStorage = JSON.parse(localStorage.getItem(config.STORAGE_KEY_SHOPPING_CART));

            if (shoppingCartFromLocalStorage !== null && typeof shoppingCartFromLocalStorage !== "undefined" && shoppingCartFromLocalStorage.length > 0 && this._shoppingCart.length === 0) {
                this._shoppingCart = shoppingCartFromLocalStorage;
            }
        }

        if (this._shoppingCart.length > 0) {
            this._shoppingCart[index].amount--;
            if (this._shoppingCart[index].amount <= 0) {
                this._shoppingCart.splice(index, 1);
            } else {
                this._shoppingCart[index].netPrice = Math.round(Number(this._shoppingCart[index].netPriceForOne.toFixed(2)) * this._shoppingCart[index].amount * 100) / 100;
                //this._shoppingCart[index].grossPrice = Math.round(Number(this._shoppingCart[index].netPriceForOne.toFixed(2)) * this._shoppingCart[index].amount * 1.19 * 100) / 100;
                this._shoppingCart[index].updatedAt = new Date();
            }

            localStorage.setItem(config.STORAGE_KEY_SHOPPING_CART, JSON.stringify(this._shoppingCart));
        }
    }

    @action removeShoppingCart() {
        this._shoppingCart = [];
        localStorage.removeItem(config.STORAGE_KEY_SHOPPING_CART);
    }

    @action setShoppingChart(chart) {
        if (typeof localStorage !== 'undefined') {
            this._shoppingCart = chart;
            localStorage.setItem(config.STORAGE_KEY_SHOPPING_CART, JSON.stringify(this._shoppingCart));
        }
    }

    @computed get shoppingCart() {
        if (typeof localStorage !== 'undefined') {

            const shoppingCartFromLocalStorage = JSON.parse(localStorage.getItem(config.STORAGE_KEY_SHOPPING_CART));
            if (shoppingCartFromLocalStorage !== null && typeof shoppingCartFromLocalStorage !== "undefined" && shoppingCartFromLocalStorage.length > 0) {

                return fetch(config.BASE_URL + "printerCartridgePrices/checkInvoiceDetails", {
                    method: 'PUT',
                    headers: config.HEADERS,
                    body: JSON.stringify({
                        invoiceDetails: shoppingCartFromLocalStorage
                    })
                }).then((response) => {
                    if (response.status >= 200 && response.status < 400) {
                        return response.json().then(json => {
                            localStorage.setItem(config.STORAGE_KEY_SHOPPING_CART, JSON.stringify(json));

                            // When we set the observable every component with observer gets triggered
                            // but only if observable is in render function
                            this._shoppingCart = json;
                            return json;
                        });

                    } else {
                        return null;
                    }

                }).catch(error => {
                    return null;
                });

            } else {
                return new Promise((resolve) => {
                    resolve([]);
                })
            }
        }
    }

}

const store = new ShoppingCartStore();

export default store;