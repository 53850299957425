import React from 'react';
import {Grid} from "semantic-ui-react"

import PrinterCartridgeTypeList from "../components/PrinterCartridgeTypeList";
import config from '../../config/main.config'
import BreadCrumbPrinterCartridge from "../components/BreadCrumbPrinterCartridge";
import history from "../helper/browserHistory"
import {withNamespaces} from 'react-i18next';
import MetaTags from "../components/MetaTags"
import {getTokenFromLocalStorage} from "../helper/util";

@withNamespaces()
export default class PrinterCartridgePrinterSeries extends React.Component {

    constructor(props) {
        super(props);

        if (props.staticContext) {

            let formatPrinterSeries = [];
            for(let prop in props.staticContext) {
                if(props.staticContext[prop].hasOwnProperty("printerSeries")) {
                    formatPrinterSeries.push(props.staticContext[prop].printerSeries)
                }
            }

            this.state = {
                printerSeries: formatPrinterSeries
            };

        } else {
            this.state = {
                printerSeries: []
            };
        }


    }

    componentDidMount() {      
        const { manufacturer } = this.props.match.params;
        if(typeof manufacturer !== 'undefined' && manufacturer !== 'null') {
            if (manufacturer.slice(-1) === "_") {
                history.replace('/toner-nicht-gefunden');
            } else {
                let realManufacturer = decodeURI(manufacturer).replace(/_/g, ' ');

                localStorage.setItem(config.STORAGE_KEY_SELECTED_PRINTER_CARTRIDGE, JSON.stringify({
                    manufacturer:realManufacturer
                }));

                //Send token if logged in to get also ink results
                const userToken = getTokenFromLocalStorage();
                let headers = config.HEADERS;
                if(userToken != null){
                    headers.token =  userToken
                }

                fetch(config.BASE_URL + "printerCartridges/series?manufacturer=" + realManufacturer, {
                    method: 'GET',
                    headers: headers
                }).then((response) => {
                    if (response.status >= 200 && response.status < 400) {
                        response.json().then(json => {
                            if(json.length === 0) {
                                history.replace('/toner-nicht-gefunden');
                            }
                            else {
                                let printerSeries = json.map(result => result.printerSeries);
                                this.setState({
                                    printerSeries: printerSeries
                                });
                            }
                        });

                    } else {
                        history.replace('/toner-nicht-gefunden');
                    }

                }).catch(error => {
                    history.replace('/toner-nicht-gefunden');
                });
            }
        }
        else {
            history.push('/toner-nicht-gefunden');
        }

    }

    render() {
        const {t} = this.props;
        const {manufacturer} = this.props.match.params;

        let add = {manufacturer: manufacturer}

        return (
            <Grid stackable class='main-grid'>
                <MetaTags page="serie-waehlen" add={add} />
                <Grid.Row class='main-grid-header-row'>
                    <Grid.Column>
                        <h1 className="fira big">
                            {t('printerCartridge.printerSeries.headline', add)}
                        </h1>
                        <a href="/service" className="fira small block">
                            {t('printerCartridge.printerSeries.subheadline')}
                        </a>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row class='main-grid-main-row'>
                    <PrinterCartridgeTypeList data={this.state.printerSeries}
                                              textForNull={t('printerCartridge.printerSeries.textForNull')}
                                              aHrefItem={(printerSeries) => {
                                                const { manufacturer } = this.props.match.params;
                                                    if(typeof manufacturer !== 'undefined' && manufacturer !== 'null') {
                                                        let replacedManufacturer = manufacturer.replace(/\s/g, '_');
                                                        let replacedSeries = printerSeries;

                                                        if(printerSeries !== null) {
                                                            replacedSeries = printerSeries.replace(/\s/g, '_');
                                                        }

                                                        return('/toner-finden/drucker-waehlen/' + encodeURI(replacedManufacturer) + '/' + encodeURI(replacedSeries))
                                                    }
                                                }}
                                                notFound = {{text: t('printerCartridge.printerSeries.notFound'), link: "/nicht-aufgelistet"}}
                                            />
                </Grid.Row>
                <Grid.Row class='bread-crumb-row main-grid-footer-row'>
                    <BreadCrumbPrinterCartridge
                        activeItem='type'
                        forceBottom={true}/>
                </Grid.Row>
            </Grid>
        );
    }
}