import React from 'react';
import {Grid} from "semantic-ui-react"
import {withNamespaces} from 'react-i18next';
import MetaTags from '../components/MetaTags'
import PrinterCartridgeManufacturerSelection from "../components/PrinterCartridgeManufacturerSelection";


@withNamespaces()
export default class PrinterCartridgeManufacturer extends React.Component {
    
    render() {
        
        const {t} = this.props;
        return (
            <div>
                <Grid stackable class='main-grid'>
                    <MetaTags page="hersteller-waehlen"/>
                    <Grid.Row class='main-grid-header-row'>
                        <Grid.Column>
                            <h1 className="fira big">
                                {t('printerCartridge.manufacturer.headline')}
                            </h1>
                            <a href="/service" className="fira small block">
                                {t('printerCartridge.manufacturer.subheadline')}
                            </a>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

                <PrinterCartridgeManufacturerSelection />
            </div>
        )
    }
}    
