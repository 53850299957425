import { observable } from 'mobx';

class InvoiceStore {
    @observable invoiceId = null;
    @observable email = null;
    @observable isPrivatePerson = true;
    @observable dhlPostageStamp = false;
}

const store = new InvoiceStore();

export default store;