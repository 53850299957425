import React from 'react';
import {withNamespaces} from 'react-i18next';
import MetaTags from '../components/MetaTags'
import PrinterCartridgeManufacturerSelection from "../components/PrinterCartridgeManufacturerSelection";
import {Link} from 'react-router-dom'


@withNamespaces()
export default class LpFullTonerSell extends React.Component {
    
    render() {
        
        const {t} = this.props;
        return (
            <div class="landingpage">
                <MetaTags page="volle-toner-verkaufen" />
                <h1>Volle / ungebrauchte Toner verkaufen</h1>
                <p>Sie haben noch unbenutzte Toner auf Vorrat und wissen nicht wohin damit?<br />
                <br />
                Wir kaufen Toner und Trommeleinheiten in verschiedenen Zuständen zu fairen Preisen.
                Nutzen Sie ganz einfach unseren onlinebasierten Produktkatalog und finden Sie umgehend Ihre Ankaufspreise heraus:</p>

                <PrinterCartridgeManufacturerSelection without={1} />
              
                <h2>Volle Toner Ankauf: alle Hersteller + jeder Zustand</h2>
                <p>Bei uns verkaufen Sie volle Toner und unbenutzte Trommeleinheiten aller gängigen Hersteller!
                Auch der Zustand der vollen Toner ist prinzipiell egal.
                Die höchste Vergütung erhalten Sie jedoch bei originalverpackten Tonern.
                Starten Sie unseren onlinebasierten Anfrageprozess (siehe oben), um den Zustand Ihrer ungebrauchten Tonerkartuschen zu definieren.</p>

                <br />

                <h2>Volle Toner verkaufen: Gut für die Umwelt, gut für den Geldbeutel</h2>
                <p>
                <Link to="/voll-dabei/volle-toner-entsorgen-und-geld-verdienen" title="Druckerpatronen nach KrWG und ElektroG entsorgen" className="lp">Nicht mehr gebrauchte Toner müssen ebenso wie 
                Tintenpatronen gemäß Kreislaufwirtschaftsgesetz und ElektroG entsorgt werden</Link>. Wer die Toner einfach wegwirft, riskiert 
                empfindliche Bußgelder. Bei uns entsorgen Firmen/Büros, Händler, Insolvenzverwalter und Privatpersonen Toner hingegen gewinnbringend.</p>
                <br />
                <p><i><Link to="/voll-dabei/ungebrauchte-toner-verkaufen-noch-heute-angebot-erhalten" title="Mehr Informationen zum Toner Verkaufe" className="lp">Weiterführende Informationen zum Toner-Verkauf</Link></i></p>
                
            </div>
        )
    }
}    
