import React from 'react';
import {Grid, Button, Label, Input, Form, Checkbox} from "semantic-ui-react"
import IBAN from "../helper/iban"
import {observer} from "mobx-react"
import * as mobx from "mobx"

import config from '../../config/main.config'
import history from "../helper/browserHistory"
import {withNamespaces} from 'react-i18next';
import Currency from 'react-currency-formatter';
import shoppingCartStore from '../stores/shoppingCartStore'
import {getStateLanguageStringFromState, hasNoSpecialCharacters, validateEmail} from '../helper/util'
import invoiceStore from '../stores/invoiceStore'
import validateVat from 'jsvat'
import MetaTags from "../components/MetaTags"
import {Link} from "react-router-dom";


@withNamespaces()
@observer
export default class SummaryPage extends React.Component {

        /*
        taxDHLPostageStamps: 0,
        summaryPriceTax: 0,

         */
    state = {
        loading: false,
        showTrashPriceInfo: false,
        shoppingCart: [],
        amountOfItems: 0,
        summaryPriceOfPrinterCartridges: 0,
        summaryPriceWithoutDHLPostageStamp: 0,
        dhlPostageStampAmounts: 0,
        summaryPriceDHLPostageStamps: 0,
        checkedTOS: {
            value: false,
            error: false
        },
        dhlPostageNumbers: [{
            key: 0,
            text: 0,
            value: 0
        }],
        isPrivatePerson: true,
        shippingOnOwnCosts: true,
        firstname: {
            value: '',
            emptyError: false,
            specialCharactersError: false,
            minLengthError: false,
            maxLengthError: false
        },
        lastname: {
            value: '',
            emptyError: false,
            specialCharactersError: false,
            minLengthError: false,
            maxLengthError: false
        },
        street: {
            value: '',
            emptyError: false,
            specialCharactersError: false,
            minLengthError: false,
            maxLengthError: false
        },
        houseNumber: {
            value: '',
            emptyError: false,
            specialCharactersError: false,
            minLengthError: false,
            maxLengthError: false
        },
        extraInformation: {
            value: '',
            emptyError: false,
            specialCharactersError: false,
            minLengthError: false,
            maxLengthError: false
        },
        city: {
            value: '',
            emptyError: false,
            specialCharactersError: false,
            minLengthError: false,
            maxLengthError: false
        },
        postalCode: {
            value: '',
            emptyError: false,
            specialCharactersError: false,
            maxLengthError: false,
            minLengthError: false
        },
        email: {
            value: '',
            emptyError: false,
            validationError: false,
            specialCharactersError: false,
            minLengthError: false,
            maxLengthError: false
        },
        bankAccountOwner: {
            value: '',
            emptyError: false,
            specialCharactersError: false,
            minLengthError: false,
            maxLengthError: false
        },
        iban: {
            value: '',
            emptyError: false,
            specialCharactersError: false,
            minLengthError: false,
            validationError: false
        },
        company: {
            value: '',
            specialCharactersError: false,
            minLengthError: false,
            emptyError: false
        },
        taxIdentificationNumber: {
            value: '',
            specialCharactersError: false,
            minLengthError: false,
            emptyError: false,
            validationError: false
        }
    };

    hasError(object) {


        return (typeof object.specialCharactersError !== "undefined" && object.specialCharactersError) ||
            (typeof object.emptyError !== "undefined" && object.emptyError) ||
            (typeof object.maxLengthError !== "undefined" && object.maxLengthError) ||
            (typeof object.minLengthError !== "undefined" && object.minLengthError) ||
            (typeof object.validationError !== "undefined" && object.validationError);
    }

    whichError(object) {
        if (typeof object.specialCharactersError !== "undefined" && object.specialCharactersError) {
            return 'specialCharacter';
        } else if (typeof object.emptyError !== "undefined" && object.emptyError) {
            return 'emptyError';
        } else if (typeof object.maxLengthError !== "undefined" && object.maxLengthError) {
            return 'maxLengthError';
        } else if (typeof object.minLengthError !== "undefined" && object.minLengthError) {
            return 'minLengthError';
        } else if (typeof object.validationError !== "undefined" && object.validationError) {
            return 'validationError';
        }

        return 'noError'
    }

    getLabelError(object, maxLength, minLength = 0) {
        const {t} = this.props;
        let errorString = t("summaryPage.noValueError");

        switch (this.whichError(object)) {
            case 'specialCharacter':
                errorString = t("summaryPage.specialCharactersError");
                break;
            case 'emptyError':
                errorString = t("summaryPage.emptyError");
                break;
            case 'maxLengthError':
                errorString = t("summaryPage.maxLengthError", {length: maxLength});
                break;
            case 'minLengthError':
                errorString = t("summaryPage.minLengthError", {length: minLength});
                break;
            case 'validationError':
                errorString = t("summaryPage.validationError");
                break;
            default:
                errorString = t("summaryPage.notDefinedError");
                break;
        }

        return <Label basic color='red' pointing>
            {errorString}
        </Label>;
    }

    handleChange = (e, {name, value}) => {
        if (name === 'firstname' || name === 'lastname') {

            let firstNameWithValue = this.state.firstname.value;
            let lastNameWithValue = this.state.lastname.value;

            if (name === 'firstname') {
                firstNameWithValue = value;
            } else if (name === 'lastname') {
                lastNameWithValue = value
            }

            this.setState({
                ...this.state,
                bankAccountOwner: {
                    value: firstNameWithValue + ' ' + lastNameWithValue,
                    error: false,
                    maxLengthError: false,
                    validationError: false,
                    minLengthError: false
                },
                [name]: {
                    value: value,
                    error: false,
                    maxLengthError: false,
                    validationError: false,
                    minLengthError: false
                }
            });
        } else {
            this.setState({
                ...this.state,
                [name]: {
                    value: value,
                    error: false,
                    maxLengthError: false,
                    validationError: false,
                    minLengthError: false
                }
            });
        }
    };

    checkFormFields() {
        let formData = this.state;
        let noErrors = true;

        let firstErrorName = "";

        if (formData.firstname.value.trim() === '') {
            formData.firstname.emptyError = true;
            if(firstErrorName === "") {
                firstErrorName = "firstname";
            }
            noErrors = false;
        } else if (formData.firstname.value.length > 24) {
            formData.firstname.maxLengthError = true;
            if(firstErrorName === "") {
                firstErrorName = "firstname";
            }
            noErrors = false;
        } else if (!hasNoSpecialCharacters(formData.firstname.value)) {
            formData.firstname.specialCharactersError = true;
            if(firstErrorName === "") {
                firstErrorName = "firstname";
            }
            noErrors = false;
        }

        if (formData.lastname.value.trim() === '') {
            formData.lastname.emptyError = true;
            if(firstErrorName === "") {
                firstErrorName = "lastname";
            }
            noErrors = false;
        } else if (formData.lastname.value.length > 24) {
            formData.lastname.maxLengthError = true;
            if(firstErrorName === "") {
                firstErrorName = "lastname";
            }
            noErrors = false;
        } else if (!hasNoSpecialCharacters(formData.lastname.value)) {
            formData.lastname.specialCharactersError = true;
            if(firstErrorName === "") {
                firstErrorName = "lastname";
            }
            noErrors = false;
        }


        if (formData.street.value.trim() === '') {
            formData.street.emptyError = true;
            if(firstErrorName === "") {
                firstErrorName = "street";
            }
            noErrors = false;
        } else if (formData.street.value.length > 42) {
            formData.street.maxLengthError = true;
            if(firstErrorName === "") {
                firstErrorName = "street";
            }
            noErrors = false;
        } else if (!hasNoSpecialCharacters(formData.street.value)) {
            formData.street.specialCharactersError = true;
            if(firstErrorName === "") {
                firstErrorName = "street";
            }
            noErrors = false;
        }

        if (formData.extraInformation.value.trim() !== '' && formData.extraInformation.value.length > 50) {
            formData.extraInformation.maxLengthError = true;
            noErrors = false;
        } else if (!hasNoSpecialCharacters(formData.extraInformation.value)) {
            formData.extraInformation.specialCharactersError = true;
            noErrors = false;
        }

        if (formData.houseNumber.value.trim() === '') {
            formData.houseNumber.emptyError = true;
            if(firstErrorName === "") {
                firstErrorName = "houseNumber";
            }
            noErrors = false;
        } else if (formData.houseNumber.value.length > 7) {
            formData.houseNumber.maxLengthError = true;
            if(firstErrorName === "") {
                firstErrorName = "houseNumber";
            }
            noErrors = false;
        } else if (!hasNoSpecialCharacters(formData.houseNumber.value)) {
            formData.houseNumber.specialCharactersError = true;
            if(firstErrorName === "") {
                firstErrorName = "houseNumber";
            }
            noErrors = false;
        }

        if (formData.city.value.trim() === '') {
            formData.city.emptyError = true;
            if(firstErrorName === "") {
                firstErrorName = "city";
            }
            noErrors = false;
        } else if (formData.city.value.length > 50) {
            formData.city.maxLengthError = true;
            if(firstErrorName === "") {
                firstErrorName = "city";
            }
            noErrors = false;
        } else if (!hasNoSpecialCharacters(formData.city.value)) {
            formData.city.specialCharactersError = true;
            if(firstErrorName === "") {
                firstErrorName = "city";
            }
            noErrors = false;
        }

        if (formData.postalCode.value.trim() === '') {
            formData.postalCode.emptyError = true;
            if(firstErrorName === "") {
                firstErrorName = "postalCode";
            }
            noErrors = false;
        } else if (formData.postalCode.value.length > 8) {
            formData.postalCode.maxLengthError = true;
            if(firstErrorName === "") {
                firstErrorName = "postalCode";
            }
            noErrors = false;
        } else if (formData.postalCode.value.length < 5) {
            formData.postalCode.minLengthError = true;
            if(firstErrorName === "") {
                firstErrorName = "postalCode";
            }
            noErrors = false;
        } else if (!hasNoSpecialCharacters(formData.postalCode.value)) {
            formData.postalCode.specialCharactersError = true;
            if(firstErrorName === "") {
                firstErrorName = "postalCode";
            }
            noErrors = false;
        }

        if (formData.email.value.trim() === '') {
            formData.email.emptyError = true;
            if(firstErrorName === "") {
                firstErrorName = "email";
            }
            noErrors = false;
        } else if (!validateEmail(formData.email.value)) {
            formData.email.validationError = true;
            if(firstErrorName === "") {
                firstErrorName = "email";
            }
            noErrors = false;
        }


        if (formData.bankAccountOwner.value.trim() === '') {
            formData.bankAccountOwner.emptyError = true;
            if(firstErrorName === "") {
                firstErrorName = "bankAccountOwner";
            }
            noErrors = false;
        } else if (!hasNoSpecialCharacters(formData.bankAccountOwner.value)) {
            formData.bankAccountOwner.specialCharactersError = true;
            if(firstErrorName === "") {
                firstErrorName = "bankAccountOwner";
            }
            noErrors = false;
        }

        if (formData.iban.value.trim() === '') {
            formData.iban.emptyError = true;
            if(firstErrorName === "") {
                firstErrorName = "iban";
            }
            noErrors = false;
        } else if (!IBAN.isValid(formData.iban.value)) {
            formData.iban.validationError = true;
            if(firstErrorName === "") {
                firstErrorName = "iban";
            }
            noErrors = false;
        } else if (!hasNoSpecialCharacters(formData.iban.value)) {
            formData.iban.specialCharactersError = true;
            if(firstErrorName === "") {
                firstErrorName = "iban";
            }
            noErrors = false;
        }


        if (!formData.isPrivatePerson) {
            if (formData.company.value.trim() === '') {
                formData.company.emptyError = true;
                firstErrorName = "company";
                noErrors = false;
            } else if (!hasNoSpecialCharacters(formData.company.value)) {
                formData.company.specialCharactersError = true;
                firstErrorName = "company";
                noErrors = false;
            }

            if (formData.taxIdentificationNumber.value.trim() === '') {
                formData.taxIdentificationNumber.emptyError = true;
                if(firstErrorName === "") {
                    firstErrorName = "taxIdentificationNumber";
                }
                noErrors = false;
            } else if (!hasNoSpecialCharacters(formData.taxIdentificationNumber.value)) {
                formData.taxIdentificationNumber.specialCharactersError = true;
                if(firstErrorName === "") {
                    firstErrorName = "taxIdentificationNumber";
                }
                noErrors = false;
            } else if (!validateVat.checkVAT(formData.taxIdentificationNumber.value).isValid) {
                formData.taxIdentificationNumber.validationError = true;
                if(firstErrorName === "") {
                    firstErrorName = "taxIdentificationNumber";
                }
                noErrors = false;
            }
        }


        if (!formData.checkedTOS.value) {
            formData.checkedTOS.error = true;
            noErrors = false;
            if(firstErrorName === "") {
                firstErrorName = "checkedTOS";
            }

        }


        if (!noErrors) {
            const inputElement = document.getElementsByName(firstErrorName);

            if(inputElement.length === 1) {
                if(document) {
                    const isIE = /*@cc_on!@*/false || !!document.documentMode;
                    if (!isIE) {
                        inputElement[0].scrollIntoView()
                    }
                }

            }

            this.setState(formData);
        }

        return noErrors;

    }

    onSubmit() {
        if (this.checkFormFields()) {
            this.setState({
                ...this.state,
                loading: true
            });
/*
                        totalGrossPrice: this.state.isPrivatePerson ? 0 : shoppingCartStore.getSummaryOfPrices(false),
* */
            fetch(config.BASE_URL + "invoices/", {
                method: 'POST',
                headers: config.HEADERS,
                body: JSON.stringify({
                    baseData: {
                        firstname: this.state.firstname.value,
                        lastname: this.state.lastname.value,
                        street: this.state.street.value,
                        houseNumber: this.state.houseNumber.value,
                        extraInformation: this.state.extraInformation.value,
                        city: this.state.city.value,
                        postalCode: this.state.postalCode.value,
                        email: this.state.email.value,
                        bankAccountOwner: this.state.bankAccountOwner.value,
                        iban: this.state.iban.value,
                        company: this.state.company.value,
                        taxIdentificationNumber: this.state.taxIdentificationNumber.value,
                        totalNetPrice: shoppingCartStore.getSummaryOfPrices(),
                        isPrivatePerson: this.state.isPrivatePerson
                    },
                    details: mobx.toJS(shoppingCartStore._shoppingCart)
                })
            }).then((response) => {
                this.setState({
                    ...this.state,
                    loading: false
                });
                if (response.status >= 200 && response.status < 400) {
                    response.json().then(json => {
                        if (json.length === 0) {
                            history.push('/verkauf-nicht-erstellt');
                        } else {

                            invoiceStore.email = json.email;
                            invoiceStore.invoiceId = json.id;
                            invoiceStore.isPrivatePerson = this.state.isPrivatePerson;
                            invoiceStore.dhlPostageStamp = !this.state.shippingOnOwnCosts;
                            history.push('/erfolgreich-verkauft');
                        }

                    });

                } else {
                    history.push('/verkauf-nicht-erstellt');
                }

            }).catch(error => {
                this.setState({
                    ...this.state,
                    loading: false
                });
                history.push('/verkauf-nicht-erstellt');
            });
        }
    }

    componentDidMount() {

        shoppingCartStore.shoppingCart.then(shoppingCart => {
            if (Array.isArray(shoppingCart) && shoppingCart.length === 0) {
                return history.push('/verkaufskorb-leer');
            }

            if (Array.isArray(shoppingCart) && shoppingCartStore.getSummaryOfPrinterCartridges(true) <= 0) {
                return history.push('/verkauf-nicht-erstellt');
            }
/*
                summaryPriceTax: shoppingCartStore.getTaxOfSummaryPrice(),
                taxDHLPostageStamps: shoppingCartStore.getTaxOfDhlPostageStamp(),

 */
            this.setState({
                ...this.state,
                amountOfItems: shoppingCartStore.getSummaryOfItems(),
                summaryPriceOfPrinterCartridges: shoppingCartStore.getSummaryOfPrinterCartridges(),
                summaryPriceWithoutDHLPostageStamp: shoppingCartStore.getSummaryOfPricesWithoutDHLPostageStamp(true),
                dhlPostageStampAmounts: shoppingCartStore.getDHLPostageStampAmounts(),
                summaryPriceDHLPostageStamps: shoppingCartStore.getDhlPostageSummary(),
            });

            if (shoppingCart !== null) {
                shoppingCartStore.removeAllDHLPostageStamps();
                let dhlNumbers = [];

                for (let i = 1; i <= Math.floor(shoppingCartStore.getSummaryOfPricesWithoutDHLPostageStamp(true) / 6); i++) {
                    dhlNumbers.push({
                        key: i,
                        text: i,
                        value: i
                    });
                }

                this.setState({
                    ...this.state,
                    shoppingCart: shoppingCart,
                    dhlPostageNumbers: dhlNumbers
                });
            } else {
                history.push('/verkauf-nicht-erstellt');
            }
        }).catch(error => {
            history.push('/averkauf-nicht-erstellt');
        });
    }

    render() {

        const {t} = this.props;

        const shoppingCartElements = this.state.shoppingCart.filter(shopItem => shopItem.type === 'printerCartridge').map((shoppingCartItem, index) => (
            <Grid.Row key={shoppingCartItem.printerCartridgeId + index}
                      class={index !== this.state.shoppingCart.length ? "summary-border-bottom" : ""}>
                <Grid.Column width={4} style={{"padding": 0}}>
                    <div>
                        {shoppingCartItem.manufacturer}
                    </div>
                    <div>
                        {shoppingCartItem.originalItemNumber}
                    </div>
                    <div>
                        {getStateLanguageStringFromState(shoppingCartItem.state, t)}
                    </div>
                </Grid.Column>
                <Grid.Column width={7} style={{"margin": "auto", "padding": 0}}>
                    <div class='quantity-price' style={{"fontWeight": "bold", "textAlign": "center"}}>
                        {t("summaryPage.priceFor", {amount: shoppingCartItem.amount})}&nbsp;
                        &nbsp;<Currency
                            quantity={shoppingCartItem.netPriceForOne}
                            currency="EUR"
                        />
                    </div>
                </Grid.Column>
                <Grid.Column width={5} style={{"margin": "auto", "padding": 0, "textAlign": "right"}}>
                    <Currency
                        quantity={shoppingCartItem.netPrice}
                        currency="EUR"
                    />
                </Grid.Column>
            </Grid.Row>
        ));

        return (
            <Grid stackable style={{'width': '100%'}}>
                <MetaTags page="verkaufen" />
                <Grid.Row>
                    <Grid.Column>
                        <h1 className="fira big">
                            {t("summaryPage.headline")}
                        </h1>
                        <span className="safe-checkout-wrapper">
                            <span class="safe-ssl">
                                {t("safeSSLConnection")}
                            </span>
                            <div className="safe-steps">
                                <span className="safe-step-1 white">
                                    {t("transferSaleData")}
                                </span>
                                <span className="safe-step-2">
                                    {t("generateDeliveryNote")}
                                </span>
                            </div>
                        </span>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{"paddingBottom": 0}}>
                    <div className='printer-cartridge-list-logo'/>
                </Grid.Row>
                <Grid.Row class='summary-wrapper'>
                    <Grid class='summary-grid'>
                        <Grid.Row style={{"paddingBottom": 0}}>
                            <Grid.Column width={6} class='summary-header-item-number'>
                                {t("summaryPage.itemNumber")}
                            </Grid.Column>
                            <Grid.Column width={6} class='summary-header'>
                                {t("summaryPage.amount")}
                            </Grid.Column>
                            <Grid.Column width={4} class='summary-header-sum'>
                                {t("summaryPage.sum")}
                            </Grid.Column>
                        </Grid.Row>
                        {shoppingCartElements}
                        <Grid class="summary-grid-gray">
                            <Grid.Row>
                                <Grid.Column width={8} style={{"textAlign": "center", "padding": 0}}>
                                    {t("summaryPage.betweenSum")}
                                </Grid.Column>
                                <Grid.Column width={3} style={{"padding": 0}}>
                                    {t("summaryPage.amountOfItems", {amount: shoppingCartStore.getSummaryOfItems()})}
                                </Grid.Column>
                                <Grid.Column width={5}
                                             style={{"padding": 0}}
                                             class='summary-price'>
                                    <Currency
                                        quantity={shoppingCartStore.getSummaryOfPrinterCartridges()}
                                        currency="EUR"
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            {
                                this.state.isPrivatePerson && this.state.showTrashPriceInfo ?
                                    <Grid.Row style={{"padding":0}}>
                                        <Grid.Column width={16} style={{"textAlign": "center", "fontSize": "9px", "lineHeight":1}}>
                                            {t("summaryPage.privatePersonTrashPrice")}
                                        </Grid.Column>
                                    </Grid.Row>
                                    :
                                    null
                            }
                            <Grid.Row>
                                <Form style={{"width": "100%", "margin": "16px"}} autoComplete="off" loading={this.state.loading}
                                      disabled={this.state.loading}>
                                    <Form.Checkbox class="summary-checkbox"
                                                   name='privatePerson'
                                                   label={t("summaryPage.sellAsPrivatePerson")}
                                                   onChange={() => {
/*
                                                           summaryPriceTax: shoppingCartStore.getTaxOfSummaryPrice(),
                                                           taxDHLPostageStamps: shoppingCartStore.getTaxOfDhlPostageStamp(),
* */
                                                       this.setState({
                                                           ...this.state,
                                                           showTrashPriceInfo:shoppingCartStore.removeTrashPrices(),
                                                           shoppingCart: shoppingCartStore._shoppingCart,
                                                           isPrivatePerson: true,
                                                           amountOfItems: shoppingCartStore.getSummaryOfItems(),
                                                           summaryPriceOfPrinterCartridges: shoppingCartStore.getSummaryOfPrinterCartridges(),
                                                           summaryPriceWithoutDHLPostageStamp: shoppingCartStore.getSummaryOfPricesWithoutDHLPostageStamp(true),
                                                           dhlPostageStampAmounts: shoppingCartStore.getDHLPostageStampAmounts(),
                                                           summaryPriceDHLPostageStamps: shoppingCartStore.getDhlPostageSummary(),
                                                       })
                                                   }}
                                                   checked={this.state.isPrivatePerson}/>
                                    <Form.Checkbox label={t("summaryPage.sellAsCompany")}
                                                   name='companyPerson'
                                                   onChange={() => {
                                                       /*
                                                           summaryPriceTax: shoppingCartStore.getTaxOfSummaryPrice(),
                                                           taxDHLPostageStamps: shoppingCartStore.getTaxOfDhlPostageStamp(),

                                                        */
                                                       this.setState({
                                                           ...this.state,
                                                           isPrivatePerson: false,
                                                           amountOfItems: shoppingCartStore.getSummaryOfItems(),
                                                           summaryPriceOfPrinterCartridges: shoppingCartStore.getSummaryOfPrinterCartridges(),
                                                           summaryPriceWithoutDHLPostageStamp: shoppingCartStore.getSummaryOfPricesWithoutDHLPostageStamp(false),
                                                           dhlPostageStampAmounts: shoppingCartStore.getDHLPostageStampAmounts(),
                                                           summaryPriceDHLPostageStamps: shoppingCartStore.getDhlPostageSummary(),
                                                       })
                                                   }}
                                                   checked={!this.state.isPrivatePerson}/>
                                    {
                                        !this.state.isPrivatePerson ?
                                            <p><i>{t("summaryPage.taxSeperat")}</i></p>
                                        :
                                            null
                                    }

                                    {
                                        !this.state.isPrivatePerson ?
                                            <Form.Field autoComplete="new-password" error={this.hasError(this.state.company)}>
                                                <Input fluid
                                                       autoComplete="new-password"
                                                       placeholder={t("summaryPage.company")}
                                                       required
                                                       name='company' onChange={this.handleChange}
                                                       value={this.state.company.value}/>
                                                {
                                                    this.hasError(this.state.company) ?
                                                        this.getLabelError(this.state.company)
                                                        :
                                                        null
                                                }
                                            </Form.Field>
                                            :
                                            null

                                    }
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <Form.Input fluid placeholder={t("summaryPage.firstname")}
                                                        autoComplete="new-password"
                                                        error={this.hasError(this.state.firstname)}
                                                        name='firstname' onChange={this.handleChange}
                                                        value={this.state.firstname.value}/>
                                            {
                                                this.hasError(this.state.firstname) ?
                                                    this.getLabelError(this.state.firstname, 24)
                                                    :
                                                    null
                                            }
                                        </Form.Field>
                                        <Form.Field>
                                            <Form.Input fluid placeholder={t("summaryPage.lastname")}
                                                        autoComplete="new-password"
                                                        error={this.hasError(this.state.lastname)}
                                                        name='lastname' onChange={this.handleChange}
                                                        value={this.state.lastname.value}/>
                                            {
                                                this.hasError(this.state.lastname) ?
                                                    this.getLabelError(this.state.lastname, 24)
                                                    :
                                                    null
                                            }
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <Form.Input fluid placeholder={t("summaryPage.street")}
                                                        autoComplete="new-password"
                                                        error={this.hasError(this.state.street)}
                                                        name='street' onChange={this.handleChange}
                                                        value={this.state.street.value}/>
                                            {
                                                this.hasError(this.state.street) ?
                                                    this.getLabelError(this.state.street, 42)
                                                    :
                                                    null
                                            }
                                        </Form.Field>
                                        <Form.Field>
                                            <Form.Input fluid placeholder={t("summaryPage.houseNumber")}
                                                        autoComplete="new-password"
                                                        error={this.hasError(this.state.houseNumber)}
                                                        name='houseNumber' onChange={this.handleChange}
                                                        value={this.state.houseNumber.value}/>
                                            {
                                                this.hasError(this.state.houseNumber) ?
                                                    this.getLabelError(this.state.houseNumber, 7)
                                                    :
                                                    null
                                            }
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <Form.Input fluid placeholder={t("summaryPage.postalCode")}
                                                        autoComplete="new-password"
                                                        error={this.hasError(this.state.postalCode)}
                                                        name='postalCode' onChange={this.handleChange}
                                                        value={this.state.postalCode.value}/>
                                            {
                                                this.hasError(this.state.postalCode) ?
                                                    this.getLabelError(this.state.postalCode, 8, 5)
                                                    :
                                                    null
                                            }
                                        </Form.Field>
                                        <Form.Field>
                                            <Form.Input fluid placeholder={t("summaryPage.city")}
                                                        autoComplete="new-password"
                                                        error={this.hasError(this.state.city)}
                                                        name='city' onChange={this.handleChange}
                                                        value={this.state.city.value}/>
                                            {
                                                this.hasError(this.state.city) ?
                                                    this.getLabelError(this.state.city, 50)
                                                    :
                                                    null
                                            }
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Field autoComplete="new-password" error={this.hasError(this.state.email)}>
                                        <Input fluid placeholder={t("summaryPage.email")}
                                               autoComplete="new-password"
                                               name='email' onChange={this.handleChange}
                                               value={this.state.email.value}/>
                                        {
                                            this.hasError(this.state.email) ?
                                                this.getLabelError(this.state.email, 0)
                                                :
                                                null
                                        }
                                    </Form.Field>
                                    <Form.Field autoComplete="new-password" error={this.hasError(this.state.bankAccountOwner)}>
                                        <Input fluid placeholder={t("summaryPage.bankAccountOwner")}
                                               autoComplete="new-password"
                                               name='bankAccountOwner' onChange={this.handleChange}
                                               value={this.state.bankAccountOwner.value}/>
                                        {
                                            this.hasError(this.state.bankAccountOwner) ?
                                                this.getLabelError(this.state.bankAccountOwner, 0)
                                                :
                                                null
                                        }
                                    </Form.Field>
                                    <Form.Field autoComplete="new-password" error={this.hasError(this.state.iban)}>
                                        <Input fluid placeholder={t("summaryPage.iban")}
                                               autoComplete="new-password"
                                               name='iban' onChange={this.handleChange}
                                               value={this.state.iban.value}/>
                                        {
                                            this.hasError(this.state.iban) ?
                                                this.getLabelError(this.state.iban, 0)
                                                :
                                                null
                                        }
                                    </Form.Field>
                                    {
                                        !this.state.isPrivatePerson ?
                                            <Form.Field autoComplete="new-password" error={this.hasError(this.state.taxIdentificationNumber)}>
                                                <Input fluid
                                                       autoComplete="new-password"
                                                       placeholder={t("summaryPage.taxIdentificationNumber")}
                                                       required
                                                       name='taxIdentificationNumber' onChange={this.handleChange}
                                                       value={this.state.taxIdentificationNumber.value}/>
                                                {
                                                    this.hasError(this.state.taxIdentificationNumber) ?
                                                        this.getLabelError(this.state.taxIdentificationNumber, 0)
                                                        :
                                                        null
                                                }
                                            </Form.Field>
                                            :
                                            null

                                    }
                                    <Form.Checkbox label={t("summaryPage.deliverOnOwnCosts")}
                                                   onChange={() => {
                                                       /*
                                                           summaryPriceTax: shoppingCartStore.getTaxOfSummaryPrice(),
                                                           taxDHLPostageStamps: shoppingCartStore.getTaxOfDhlPostageStamp(),
                                                       */
                                                       shoppingCartStore.removeAllDHLPostageStamps();
                                                       this.setState({
                                                           ...this.state,
                                                           shippingOnOwnCosts: true,
                                                           amountOfItems: shoppingCartStore.getSummaryOfItems(),
                                                           summaryPriceOfPrinterCartridges: shoppingCartStore.getSummaryOfPrinterCartridges(),
                                                           summaryPriceWithoutDHLPostageStamp: shoppingCartStore.getSummaryOfPricesWithoutDHLPostageStamp(this.state.isPrivatePerson),
                                                           dhlPostageStampAmounts: shoppingCartStore.getDHLPostageStampAmounts(),
                                                           summaryPriceDHLPostageStamps: shoppingCartStore.getDhlPostageSummary(),
                                                           dhlPostageNumbers: [{
                                                               key: 0,
                                                               text: 0,
                                                               value: 0
                                                           }]
                                                       })

                                                   }}
                                                   checked={this.state.shippingOnOwnCosts}/>
                                    {
                                        Math.floor(shoppingCartStore.getSummaryOfPricesWithoutDHLPostageStamp(true) / 6) > 0 && shoppingCartStore.getSummaryOfPricesWithoutDHLPostageStamp(true) > 6 ?
                                            <Form.Group>
                                                <Form.Field>
                                                    <Form.Checkbox checked={!this.state.shippingOnOwnCosts}
                                                                   onChange={() => {

                                                                       if (this.state.shippingOnOwnCosts) {
                                                                           shoppingCartStore.addToShoppingCart({
                                                                               "manufacturer": "",
                                                                               "amount": 1,
                                                                               "type": "dhlPostageStamp",
                                                                               "state": "",
                                                                               "price": 6.00,
                                                                               "netPriceForOne": 6.00,
                                                                               "originalItemNumber": "",
                                                                               "itemNumber": "",
                                                                               "id": ""
                                                                           });

                                                                           let dhlNumbers = [];

                                                                           for (let i = 1; i <= Math.floor(shoppingCartStore.getSummaryOfPricesWithoutDHLPostageStamp() / 6); i++) {
                                                                               dhlNumbers.push({
                                                                                   key: i,
                                                                                   text: i,
                                                                                   value: i
                                                                               });
                                                                           }
/*
                                                                               summaryPriceTax: shoppingCartStore.getTaxOfSummaryPrice(),
                                                                               taxDHLPostageStamps: shoppingCartStore.getTaxOfDhlPostageStamp(),

*/
                                                                           this.setState({
                                                                               ...this.state,
                                                                               shippingOnOwnCosts: false,
                                                                               amountOfItems: shoppingCartStore.getSummaryOfItems(),
                                                                               summaryPriceOfPrinterCartridges: shoppingCartStore.getSummaryOfPrinterCartridges(),
                                                                               summaryPriceWithoutDHLPostageStamp: shoppingCartStore.getSummaryOfPricesWithoutDHLPostageStamp(this.state.isPrivatePerson),
                                                                               dhlPostageStampAmounts: shoppingCartStore.getDHLPostageStampAmounts(),
                                                                               summaryPriceDHLPostageStamps: shoppingCartStore.getDhlPostageSummary(),
                                                                               dhlPostageNumbers: dhlNumbers
                                                                           })
                                                                       }

                                                                   }}
                                                                   label={t("summaryPage.needDHLPostageStamps")}/>
                                                </Form.Field>


                                                {
                                                    !this.state.shippingOnOwnCosts ?
                                                        <Form.Field>
                                                            <Form.Select options={this.state.dhlPostageNumbers}
                                                                         onChange={(event, data) => {
                                                                             shoppingCartStore.setAmountOfDHLPostageStamp(data.value);
                                                                             /*
                                                                                 summaryPriceTax: shoppingCartStore.getTaxOfSummaryPrice(),
                                                                                 taxDHLPostageStamps: shoppingCartStore.getTaxOfDhlPostageStamp(),

                                                                              */

                                                                             this.setState({
                                                                                 ...this.state,
                                                                                 amountOfItems: shoppingCartStore.getSummaryOfItems(),
                                                                                 summaryPriceOfPrinterCartridges: shoppingCartStore.getSummaryOfPrinterCartridges(),
                                                                                 summaryPriceWithoutDHLPostageStamp: shoppingCartStore.getSummaryOfPricesWithoutDHLPostageStamp(this.state.isPrivatePerson),
                                                                                 dhlPostageStampAmounts: shoppingCartStore.getDHLPostageStampAmounts(),
                                                                                 summaryPriceDHLPostageStamps: shoppingCartStore.getDhlPostageSummary(),
                                                                             })
                                                                         }}
                                                                         value={shoppingCartStore.getDHLPostageStampAmounts()}/>
                                                        </Form.Field>
                                                        :
                                                        null
                                                }

                                            </Form.Group>
                                            :
                                            null
                                    }
                                    <Form.Field error={this.state.checkedTOS.error} style={{"marginTop":"1em"}}>
                                        <Checkbox
                                            name='checkedTOS'
                                            label={t("summaryPage.tosText")}
                                            onChange={() => {
                                                this.setState({
                                                    ...this.state,
                                                    checkedTOS: {
                                                        value: !this.state.checkedTOS.value,
                                                        error: false
                                                    }
                                                })
                                            }}
                                            checked={this.state.checkedTOS.value}/>
                                            <Link to={t("summaryPage.linkTos.to")} target="_blank" className="summaryAGB" title={t("summaryPage.linkTos.title")}>{t("summaryPage.linkTos.value")}</Link>
                                        {
                                            this.state.checkedTOS.error ?
                                                <Label basic color='red' pointing>
                                                    {t("summaryPage.tosError")}
                                                </Label>
                                                :
                                                null
                                        }
                                    </Form.Field>
                                </Form>
                            </Grid.Row>
                        </Grid>
                        {/*
                            !this.state.isPrivatePerson ?
                                <Grid.Row class="summary-row gray-border-bottom">
                                    <Grid.Column width={10}>
                                        {t("summaryPage.taxWithPercent")}
                                    </Grid.Column>
                                    <Grid.Column width={6} style={{"textAlign": "right"}}>
                                        <Currency
                                            quantity={shoppingCartStore.getTaxOfSummaryPrice()}
                                            currency="EUR"
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                :
                                null
                        */}
                        {
                            shoppingCartStore.getDHLPostageStampAmounts() > 0 ?
                                <Grid.Row class="summary-row gray-border-bottom">
                                    <Grid.Column width={10}>
                                        {t("summaryPage.deliverWithDHLPostageStampAmount", {amount: shoppingCartStore.getDHLPostageStampAmounts()})}
                                    </Grid.Column>
                                    <Grid.Column width={6} style={{"textAlign": "right"}}>
                                        <Currency
                                            quantity={shoppingCartStore.getDhlPostageSummary()}
                                            currency="EUR"
                                            pattern="-#,##0.00 !"
                                        />
                                        {/*<div style={{"fontSize": "10px"}}>
                                            {t("inclusive_shortcut")}
                                            <Currency
                                                quantity={shoppingCartStore.getTaxOfDhlPostageStamp()}
                                                currency="EUR"
                                            />
                                            {t("tax_shortcut")}
                                        </div>*/}
                                    </Grid.Column>
                                </Grid.Row>
                                :
                                null
                        }
                        <Grid.Row class="summary-row">
                            <Grid.Column width={8}>
                                {t("summaryPage.finalSum")}
                            </Grid.Column>
                            <Grid.Column width={8} class="summary-price-right">
                                <Currency
                                    quantity={shoppingCartStore.getSummaryOfPrices(this.state.isPrivatePerson)}
                                    currency="EUR"
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{"paddingBottom": 0}}>
                            <Button loading={this.state.loading}
                                    disabled={this.state.loading}
                                    class='white finish-shopping-button'
                                    onClick={this.onSubmit.bind(this)}>
                                {t("summaryPage.finishContract")}
                            </Button>
                        </Grid.Row>
                    </Grid>
                </Grid.Row>
            </Grid>
        );
    }
}