import React from "react";
import {observable} from "mobx";
import {Button, Grid, Image, Input} from "semantic-ui-react";
import config from "../../config/main.config";
import {observer} from "mobx-react";
import {getTokenFromLocalStorage, removeTokenFromStorage} from "../helper/util";
import userStore from "../stores/userStore";
import {withNamespaces} from "react-i18next";
import MetaTags from "../components/MetaTags"
import {Link} from "react-router-dom";

@withNamespaces()
@observer
export default class BlogStartPage extends React.Component {

    showArticle(url, r=0) {
        if(r === 1){
            return ('/voll-dabei/' + url)
        }else{
            this.props.history.push('/voll-dabei/' + url);
        }
    }

    @observable articles = [];
    @observable filteredArticles = [];
    @observable filterString = "";
    @observable categories = [];
    @observable loading = true;
    @observable subcategories = [];
    @observable values = [];
    @observable dropdownOpen = false;
    @observable filtersOpen = false;

    constructor(props) {
        super(props);

        if(props.staticContext) {
            if(props.staticContext.articles) {
                this.articles = props.staticContext.articles;

            }

            if(props.staticContext.filteredArticles) {
                this.filteredArticles = props.staticContext.filteredArticles;
            }

            if(props.staticContext.categories) {
                this.categories = props.staticContext.categories;
            }

            if(props.staticContext.subcategories) {
                this.subcategories = props.staticContext.subcategories;
            }

            if(props.staticContext.values) {
                this.values = props.staticContext.values;
            }
        }

    }

    /**
     * Fetches all blog posts from the server
     * @memberOf BlogPage
     */
    componentDidMount() {
        window.scrollTo(0, 0);

        // Get Articles from Server
        fetch(config.BASE_URL + 'blog', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*"
            }
        }).then((resp) => resp.json())
            .then(data => {
                let articles = data.articles;

                //Sort Articles
                articles.sort(function (a, b) {
                    a = new Date(a.createdAt);
                    b = new Date(b.createdAt);
                    return a > b ? -1 : a < b ? 1 : 0;
                });

                this.articles = articles;
                this.filteredArticles = articles;
                this.loading = false;
            })
            .catch(function (error) {
                console.log(error);
            });

        fetch(config.BASE_URL + 'blog/categories', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*"
            }
        }).then((resp) => resp.json())
            .then(response => {

                this.categories = response.categories;
                let subcategories = [];
                let values = [];
                for (let i = 0; i < this.categories.length; i++) {
                    let categoryName = this.categories[i].name;
                    for (let j = 0; j < this.categories[i].sub_categories.length; j++) {
                        this.categories[i].sub_categories[j].name =
                            categoryName + " " + this.categories[i].sub_categories[j].name;
                        this.categories[i].sub_categories[j].checked = true;
                        subcategories.push(this.categories[i].sub_categories[j]);
                        values.push(this.categories[i].sub_categories[j].name);
                    }
                }
                this.subcategories = subcategories;
                this.values = values;
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    /**
     * Handle the input of the search filed
     * @param type
     * @param event
     */
    handleInputChange(type, event) {
        switch (type) {
            case 'filter':
                this.filterArticles(this.subcategories, event.target.value);
                break;
        }
    }

    /**
     * Filter articles with categories and search field
     * @param categories
     * @param filterString
     */
    filterArticles(categories, filterString) {
        let updatedList = [];
        let chosenCategories = [];

        //Get chosen category ids
        categories.forEach(function (category) {
            if (category.checked) {
                chosenCategories.push(category.id);
            }
        });

        //Filter categories
        for (let i = 0; i < this.articles.length; i++) {
            //Get chosen category ids of article
            if (chosenCategories.includes(this.articles[i].categoryId))
                updatedList.push(this.articles[i]);
        }

        //Filter for headers and description
        updatedList = updatedList.filter(function (item) {
            return (item.header.toLowerCase().search(
                filterString.toLowerCase()) !== -1 ||
                item.description.toLowerCase().search(
                    filterString.toLowerCase()) !== -1);
        });

        this.filteredArticles = updatedList;
        this.categories = categories;
        this.filterString = filterString;
    }

    handleChange = (e, {value}) => {
        if (value.length === 0)
            return;
        this.values = value;
        let categories = this.subcategories;
        for (let i = 0; i < categories.length; i++) {
            categories[i].checked = this.values.includes(categories[i].name);
        }
        this.subcategories = categories;
        this.filterArticles(this.subcategories, this.filterString);
    };

    /**
     * Returns Blog page
     * @return {XML} html of this component
     * @memberOf BlogPage
     */
    render() {

        const {t} = this.props;

        const cArticles = this.filteredArticles.map((article, index) =>
            <Grid.Column mobile={16} tablet={16} computer={16} key={index} className={"article-card"}>
                <div class="article-header">
                    <h1>{article.header}</h1>
                </div>
                <div class="article-subtitle">
                    {article.date}
                </div>
                <div class="article-body">
                    <div class="article-description">
                        <p>{article.description}</p>

                        <Link to={this.showArticle(article.url, 1)} title={t("blog.read") + ": " + article.header}>
                            <button className="call-to-action">
                                <strong> {t("blog.read")} </strong>
                             </button>
                        </Link>

                        {/*<button className="call-to-action"
                                onClick={this.showArticle.bind(this, article.url)}>
                            <strong> {t("blog.read")} </strong>
                        </button>*/}
                    </div>

                </div>
            </Grid.Column>
        );

        return (
            <Grid class='blog-start-grid'>
                <MetaTags page="voll-dabei" />
                <Grid.Row class="blog-start-row">
                    <h3 style={{"paddingLeft": "12px"}}>
                        {t("blog.headline")}
                    </h3>

                </Grid.Row>
                <Grid.Row class="blog-start-row">
                    <div style={{"paddingLeft": "12px"}}>
                        <Image src="/images/voll-dabei/voll-dabei-blog.png"/>
                    </div>
                </Grid.Row>
                <Grid.Row>
                    <h3 style={{"paddingLeft": "12px"}}>
                        {t("blog.welcomeHeadline")}
                    </h3>
                    <div style={{"paddingLeft": "12px"}}>
                        {t("blog.welcomeText")}
                    </div>
                </Grid.Row>

                {
                    getTokenFromLocalStorage() !== null ?
                        <Grid.Row>
                            <Grid.Column>
                                <Button class='button primary white' onClick={() => {
                                    removeTokenFromStorage();
                                    userStore.user = null;
                                    this.setState({});
                                    history.push("/");
                                }}>
                                    Logout
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                        :
                        null
                }

                <Grid.Row columns={1}>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                        <Grid width={16}>
                            <Grid.Row width={16} columns={2} className={this.articles.length === 0 ? "hidden" : ""}
                                      style={{
                                          "paddingBottom": 0,
                                          "marginLeft": "-4px"
                                      }}>
                                <Grid.Column mobile={16} tablet={8} computer={8}>
                                    <Input
                                        icon='search'
                                        placeholder={t("blog.placeholderSearch")}
                                        value={this.filterString}
                                        onChange={this.handleInputChange.bind(this, 'filter')}
                                        fluid
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <Grid width={16}>
                            <Grid.Row width={16} columns={2}>
                                {cArticles}
                            </Grid.Row>
                        </Grid>
                        <Grid.Row className={this.articles.length === 0 ? "" : "hidden"}>
                            <i>{t("blog.noArticles")}</i>
                        </Grid.Row>
                        <div class={this.loading ? "big-loader" : "hidden"}>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}