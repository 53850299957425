import React from 'react';
import {Grid} from "semantic-ui-react"

import history from "../helper/browserHistory";
import {withNamespaces} from 'react-i18next';
import MetaTags from "../components/MetaTags"
@withNamespaces()
export default class ErrorPagePriceNotClear extends React.Component {


    render() {
        const {t} = this.props;

        return (
            <Grid stackable class='main-grid'>
                <MetaTags page="verkaufspreis-nicht-ermittelt" />
                
                <Grid.Row>
                    <Grid.Column className="main-grid">
                        <h1 className="fira big">
                            {t('errorPage.price-headline')}
                        </h1>
                        <a href="/service" className="fira small block">
                            {t('errorPage.subheadline')}
                        </a>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <span className='step-back-link' onClick={() => history.goBack()}>
                        {t('stepBack')}
                    </span>
                </Grid.Row>
            </Grid>
        );
    }
}