import React from 'react';
import MetaTags from "../components/MetaTags"
import {Image} from "semantic-ui-react"

export default class Inks extends React.Component {

    render() {
        return (
            <div class="static-content">
                <MetaTags page="inks" />
                <div class="static-content-paragraph">
                    <h1>Tintenpatronen verkaufen</h1>
                    <p>Sie wundern sich, warum Sie in unserem <a href="/hersteller-waehlen" title="Suchen Sie den Verkaufspreis für Ihre Toner über unseren Produktkatalog">Produktkatalog nur Ankaufspreise für volle Tonerkartuschen</a> finden?<br />
                    Diese Frage ist ganz einfach zu beantworten: Bei Tintenpatronen (also Patronen aus einem Tintenstrahl- oder Inkjet-Drucker) befindet sich flüssige Tinte in der Patrone.<br />
                    Daher ist für die Beurteilung des richtigen Ankaufspreises neben der Verpackungsart unter anderem auch das Mindesthaltbarkeitsdatum wichtig.<br />
                    Interssant für Sie ist dabei, dass ggf. <b>auch bereits abgelaufene Tintenpatronen noch einen Restwert</b> besitzen!</p>
           
           
                    <p>Im Folgenden haben wir für Sie ein spezielles Anfrageformular für volle Tintenpatronen bereit gestellt:<br />
                    <br />
                        <a href="/download/Anfrageformular-Tintenpatronen.pdf" title="Anfrageformular für Tintenpatronen">
                        <b>Anfrageformular für Tintenpatronen herunterladen</b><br />
                        <Image src="/images/anfrageformular-tintenpatronen.png" alt="Anfrageformular für Tintenpatronen" width="106" height="150"/>
                    </a></p>
                    <p>Tragen Sie in diesem Formular bitte Ihre Artikel ein und senden Sie das Formular (gerne zusammen mit Bildern zum Artikelzustand) an <a href="mailto:info@sanocycling.de">info@sanocycling.de</a><br />
                    <br />
                    Damit Ihnen die Deklaration der Tintenpatronen leichter fällt, finden Sie nachfolgend die verschieden Verpackungsarten der gängigsten Tintenpatronen und weitere Informationen, wo Sie das Mindesthalbkarkeits- bzw. Ablaufdatum (MHD) finden.</p>
                    <br />

                    <h2>Unterschiedliche Verpackungsarten von Tintenpatronen:</h2>
    
                    <Image src="/images/logos/logo-canon.png" alt="Canon Tintenpatronen verkaufen" width="76" height="16"/>

                    <div class="rahmen1">
                        <h2 class="rahmen1">Unbeschädigte Verkaufsverpackung</h2>

                        <Image src="/images/conditions/canon-cl-541-ovp.png" class="answer-image" width="280" height="280" alt="Canon Tintenpatronen Originalverpackt verkaufen" />

                        <p class="rahmen1">Unbeschädigte, nicht beklebte oder beschriftete Canon Tintenpatrone in einer aktuellen Verpackung. Die Verpackung ist noch original verschlossen.</p>
                    </div>

                    <div class="rahmen1">
                        <h2 class="rahmen1">Geöffnete Verkaufsverpackung</h2>

                        <Image src="/images/conditions/canon-cl-541-offen.png" class="answer-image" width="280" height="280" alt="Canon Tintenpatronen geöffnet verkaufen" />

                        <p class="rahmen1">Bereits geöffnete oder anderweitig beschädigte Umverpackung. Die Tintenpatrone befindet sich noch in der verschlossenen Kunststoffverpackung.</p>
                    </div>

                    <div class="rahmen1">
                        <h2 class="rahmen1">Unverpackte Tintenpatrone</h2>

                        <Image src="/images/conditions/canon-cl-541-leer.png" class="answer-image" width="280" height="280" alt="Canon Tintenpatronen ohne Verpackung" />

                        <p class="rahmen1">Unverpackte Tintenpatrone von Canon. Unabhängig vom Restinhalt bewerten wir diesen Zustand als Leergut und kaufen ihn nicht an.</p>
                    </div>

                    <div class="clear"></div>

                    <Image src="/images/logos/logo-hp.png" alt="HP Tintenpatronen verkaufen" width="80" height="16" />

                    <div class="rahmen1">
                        <h2 class="rahmen1">Unbeschädigte Verkaufsverpackung</h2>

                        <Image src="/images/conditions/hp-ch562e-ovp.png" class="answer-image" width="280" height="280" alt="HP Tintenpatronen Originalverpackt verkaufen" />

                        <p class="rahmen1">Unbeschädigte, nicht beklebte oder beschriftete HP Tintenpatrone in einer aktuellen Verpackung. Die Verpackung ist noch original verschlossen.</p>
                    </div>

                    <div class="rahmen1">
                        <h2 class="rahmen1">Geöffnete Verkaufserpackung</h2>

                        <Image src="/images/conditions/hp-ch562e-offen.png" class="answer-image" width="280" height="280" alt="HP Tintenpatronen geöffnet verkaufen" />

                        <p class="rahmen1">Bereits geöffnete oder anderweitig beschädigte Umverpackung. Die HP befindet sich noch in der verschlossenen Folienverpackung.</p>

                    </div>

                    <div class="rahmen1">
                        <h2 class="rahmen1">Unverpackte Tintenpatrone</h2>

                        <Image src="/images/conditions/hp-ch562e-leer.png" class="answer-image" width="280" height="280" alt="Canon Tintenpatronen ohne Verpackung" />

                        <p class="rahmen1">Unverpackte Tintenpatrone von HP. Unabhängig vom Restinhalt bewerten wir diesen Zustand als Leergut und kaufen ihn nicht an.</p>
                    </div>

                    <div class="clear"></div>


                    <h2>Hier finden Sie das Mindesthaltbarkeitsdatum (MHD):</h2>
                    <br />
                    <Image src="/images/conditions/tinte-haltbarkeit.png" class="answer-image" alt="Hier finden Sie das Mindesthaltbarkeitsdatum" /><br />
                    <br />
                    <p><b>Bitte beachten:</b> Nicht auf jeder Tintenpatrone ist zwingend ein Mindesthaltbarkeitsdatum ersichtlich.<br />
                    Auf Grund einer besonders luftdichten Verpackung verzichtet z.B. Canon meist auf ein Haltbarkeitsdatum. Falls Sie nicht fündig werden, lassen Sie das Feld im Anfragefomular einfach frei.</p>
                    <br />
                </div>
            </div>
        );
    }
}