import React from 'react';
import {Grid, Icon, Image} from "semantic-ui-react"
import {Link} from 'react-router-dom';
import BreadCrumbPrinterCartridge from "../components/BreadCrumbPrinterCartridge";
import {withNamespaces} from 'react-i18next';
import MetaTags from "../components/MetaTags"

@withNamespaces()
export default class LpHip extends React.Component {

    render() {
        return (
            <Grid stackable class='white homepage-wrapper'>
                <MetaTags page="hip" />
                <Grid.Row>
                    <Grid.Column mobile={16} tablet={16} computer={9}>
                       
                        <h1>Unbenutzte Druckerpatronen in Hilpoltstein verkaufen</h1>
                        <hr></hr>
                        <br />
                        <p>Sie haben die Zeit in den vergangenen Tagen genutzt und mal wieder Ordnung in Ihrem Schrank geschaffen? Vielleicht stand auch aufgrund von Home-Office ein Druckerwechsel an und die alten Ersatzpatronen passen  nicht mehr ins neue Gerät?<br /> 
                        <br />

                        dieTonerverwerter <b>aus Hilpoltstein</b> kaufen Ihre Restbestände an ungebrauchten Druckerpatronen auf. Machen Sie Ihre alten Tonerkartuschen und Tintenpatronen noch zu Geld.
                        <br />
                        <br />
                        <Image alt="Originale Durckerpatronen in Hilpoltstein zu Geld machen" src="/images/hip/landkarte.png" style={{}}/>
                       
                        <br />
                        <br />
                        Damit wir den Wert Ihrer Druckerpatronen bestmöglich bestimmen können, laden Sie sich bitte die folgende Preisanfrage herunter. Füllen diese aus und senden diese zurück an <a href="mailto:info@sanocycling.de" className="whiteBold">info@sanocycling.de</a>.<br />
                        Gerne können Sie uns auch Ihre eigenen Auflistungen, Dateien oder Fotos zukommen lassen.
                        </p>
                        
                        <a href="/download/Preisanfrage-Druckerpatronen-bei-dieTonerverwerter_de.pdf" title="Preisanfrage für unbenutzte Druckerpatronen herunterladen" className="whiteBold" target="_blank">
                            <img src="/images/preisanfrage-druckerpatronen.jpg" alt="Preisanfrage für unbenutzte Druckerpatronen herunterladen"/><br />
                            <b>Preisanfrage für Druckerpatronen hier herunterladen</b><br />
                        </a>
                        
                        <br />
                        <br />
                        <hr></hr>
                        <br />
                        <h2>Haben Sie Fragen?</h2>
                        <p>Dann schreiben Sie gerne eine E-Mail an <a href="mailto:info@sanocycling.de" className="whiteBold">info@sanocycling.de</a> oder rufen uns unter <a href="tel:09174976727" className="whiteBold" traget="_blank">09174/976727</a> an.<br />
                        <br />
                        Unser Team erreichen Sie telefonisch zu unseren Geschäftszeiten:<br />
                        Montag bis Donnerstag von 09:00 Uhr bis 12:00 Uhr sowie von 13:00 Uhr bis 16:00 Uhr.
                        </p>

                        <br />
                        <hr></hr>
                        <br />

                        <h2>Kann ich einfach vorbei kommen und meine Patronen abgeben?</h2>
                        <p>In Zeiten von COVID-19 (Corona-Virus) sind Selbstanlieferungen nur nach vorheriger Terminabsprache möglich. Bitte nehmen Sie Kontakt mit uns auf.
                        </p>

                        <br />
                        <hr></hr>
                        <br />

                        <h2>Kann ich meine Patronen auch spenden?</h2>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column computer={9}> 
                                    <p>Selbstverständlich können Sie Ihre vollen Druckerpatronen auch spenden. <br />Hierzu haben wir eine eigenen Projektwebseite mit vielen Informationen auf <a href="https://www.RestToner.de" alt="Volle Tonerkartuschen zu Gunsten der Kinderhilfe spenden" target="_blank" className="whiteBold">www.RestToner.de</a> eingerichtet. Der Erlös kommt der Mc'Donalds Kinderhilfe zu Gute.<br />
                                    <br />
                                    <a href="https://www.RestToner.de" title="Projektwebseite RestToner.de zur Spende von übrig gebliebenen Tonerkartuschen" target="_blank">
                                        <img src="/images/resttoner/logo-resttoner.png" alt="Bei RestToner.de können Sie Ihre vollen Druckerpatronen spenden"/>
                                        </a>
                                    <br />
                                    <br />
                                    Um das Beste für die Kinderhilfe raus zuholen, können Sie - anstatt eine vorfrankierte Paketmarke zu nutzen - die Patronen auch bei uns vorbei bringen. Aber auch hier gilt: Bitte erst nach Terminvereinbarung!
                                    </p>
                                </Grid.Column>
                                <Grid.Column computer={7}>
                                    <img src="/images/resttoner/lisa-nick.png" alt="Der Erlös aus unbenutzten Druckerpatronen geht zu Gunsten der Kinderhilfe"/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <br />
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={16} computer={7}>
                        <Image alt="Originale Durckerpatronen in Hilpoltstein zu Geld machen" src="/images/hip/verkaufen-in-hilpoltstein.png" style={{}}/>
                    </Grid.Column>
                </Grid.Row>
                
                <Grid.Row class="homepage-breadcrumb-wrapper">
                    <BreadCrumbPrinterCartridge/>
                </Grid.Row>
            </Grid>
        );
    }
}