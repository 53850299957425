import React from 'react';
import {Grid, Button, Icon} from "semantic-ui-react"

import BreadCrumbPrinterCartridge from "../components/BreadCrumbPrinterCartridge";
import history from "../helper/browserHistory"
import {withNamespaces} from 'react-i18next';
import shoppingCartStore from "../stores/shoppingCartStore";
import MetaTags from "../components/MetaTags"

@withNamespaces()
export default class CashResultEnd extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            lessThanZeroPrice: false
        }
    }


    componentDidMount() {
        const {itemNumber} = this.props.match.params;
        if(itemNumber == null ||typeof itemNumber === "undefined") {
            history.replace("/");
        }
    }

    render() {

        const {t} = this.props;
        const {itemNumber} = this.props.match.params;

        return (
            <Grid stackable class='main-grid'>
                <MetaTags page="toner-im-verkaufskorb" />
                <Grid.Row class='main-grid-header-row'>
                    <Grid.Column>
                        <h2 className="fira big">
                            {t("cashResultEnd.headline")}
                        </h2>
                        <span className="fira small block">
                            {t("cashResultEnd.subheadline")}
                        </span>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{"paddingBottom": 0}}>
                    <div className='printer-cartridge-list-logo'/>
                </Grid.Row>
                <Grid.Row class='cash-result-row main-grid-main-row' style={{"paddingTop": 0}}>
                    <Grid.Column width={11} className='result-wrapper'>
                        <h1 className="result-headline">
                            {t("cashResultEnd.itemInShoppingCart")}
                        </h1>
                        <span className='step-same-link' onClick={() => history.push('/zustand-definieren/'+itemNumber)}>
                            {t('samePrinterCartridgeDifferentState')}
                        </span>                       
                        <Button class='buy-more-button' onClick={() => history.push('/hersteller-waehlen')}>
                            {t("sellMore")}
                        </Button>
                    </Grid.Column>
                    <Grid.Column width={5} class="result-shopping-cart-wrapper">
                        {
                            this.state.lessThanZeroPrice ?
                                <div class="shopping-cart-information-wrapper">
                                    <div>
                                        <Icon name="info"/>
                                    </div>
                                    <div>  
                                        {t("shoppingCart.minusMessage")}
                                    </div>
                                </div>
                                :
                                <div className='gradient-round-big'>
                                    <Icon class="cash-result-end-shopping-icon" name="shopping cart"/>
                                </div>
                        }
                        <Button class='white to-shopping-cart-button' onClick={() => {
                            if (shoppingCartStore.getSummaryOfPrices() <= 0) {
                                this.setState({
                                    lessThanZeroPrice: true
                                });
                            } else {
                                history.push('/verkaufen');
                            }

                        }}>
                            {t("toCheckout")}
                        </Button>
                    </Grid.Column>

                </Grid.Row>
                {/*<Grid.Row>
                    <span className='step-back-link' onClick={() => history.push('/zustand-definieren/'+itemNumber)}>
                         {t('samePrinterCartridgeDifferentState')}
                    </span>
                </Grid.Row>*/}
                <Grid.Row class='defineState-breadcrumb-wrapper main-grid-footer-row'>
                    <BreadCrumbPrinterCartridge
                        activeItem='checkout'
                        forceBottom={true}/>
                </Grid.Row>
            </Grid>
        );
    }
}