import React from 'react';
import {Grid, Icon, Image,} from "semantic-ui-react"
import {Link} from 'react-router-dom';
import BreadCrumbPrinterCartridge from "../components/BreadCrumbPrinterCartridge";
import {withNamespaces} from 'react-i18next';
import MetaTags from "../components/MetaTags";

//BOF Breacking Chnage in reason ov V2.0.0 https://github.com/Semantic-Org/Semantic-UI-React/releases && https://react.semantic-ui.com/migration-guide
import AppMedia from "../components/AppMedia";
const mediaStyles = AppMedia.createMediaStyle();
const { Media, MediaContextProvider } = AppMedia;
//EOF

import ReactPlayer from 'react-player/lazy'


@withNamespaces()

export default class HomePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            growHowTo: false
        }
    }

    

    render() {
        const {t} = this.props;
        return (
            <Grid stackable class='white homepage-wrapper'>
                <MetaTags page="homePage"/>
                <Grid.Row>
                    <Grid.Column mobile={16} tablet={16} computer={8}>
                        <h2>
                            {t('homepage.headline')}
                        </h2>
                        <p class='headline-text'>
                            {t('homepage.subheadline')}
                        </p>

                        {/*<div style={{"float": "left"}} >
                                <iframe className="onlyMobile" width="320" height="180" src="https://www.youtube-nocookie.com/embed/GyxuZ2RWwOs?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <iframe className="onlyDesktop" width="720" height="405" src="https://www.youtube-nocookie.com/embed/GyxuZ2RWwOs?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <div style={{"clear": "both"}} ></div>*/}


                        <div className='player-max'>
                            <div className='player-wrapper'>
                                    <ReactPlayer className='react-player' playing url='videos/dietonerverwerter-720.mp4' controls light='videos/dietonerverwerter-720.jpg' width='100%' height='100%' 
                                        config={{ file: { 
                                            attributes: {
                                            controlsList: 'nofullscreen nodownload'
                                            }
                                        }}}
                                    />
                            </div>
                        </div>
                        <Grid.Column mobile={16} tablet={6} computer={8} style={{'marginTop': '4%'}}>
                            <Link to="/hersteller-waehlen" class='white button primary'>
                                {t('homepage.callToActionTitle')}<Icon name='chevron right'/>
                            </Link>
                        </Grid.Column>
                        <p class='headline-description'>
                            {t('homepage.description')}
                        </p>

                        <div style={{'paddingTop': "30px"}}>
                            <Link to="/tintenpatronen-verkaufen" class='call-to-action'>
                                {t('homepage.callToActionInk.title')}<Icon name='chevron right'/>
                            </Link>
                        </div>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={16} computer={8}>
                        <picture>
                            <source srcSet="/images/mit-originalen-tonerpatronen-geld-verdienen.webp" type="image/webp" />
                            <Image alt='mit-originalen-tonerpatronen-geld-verdienen' src='/images/mit-originalen-tonerpatronen-geld-verdienen.png' 
                            sizes = "(min-width: 960px) 540px, 100vw"
                            srcSet ='/images/mit-originalen-tonerpatronen-geld-verdienen-mobmin.png 430w, /images/mit-originalen-tonerpatronen-geld-verdienen-mob.png 600w, /images/mit-originalen-tonerpatronen-geld-verdienen-tablet.png 900w, /images/mit-originalen-tonerpatronen-geld-verdienen.png 1200w'/>
                        </picture>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{'paddingBottom': 0}}>
                    <div class={"how-to-wrapper " + (this.state.growHowTo ? 'how-to-trigger-grow' : '')}>
                        <div className="trigger-how"
                             onClick={() => this.setState({growHowTo: !this.state.growHowTo})}>
                            {t('homepage.howItWorks')}
                        </div>

                        {
                            this.state.growHowTo ?
                                <div>
                                     <style>{mediaStyles}</style>
                                    <MediaContextProvider>
                                    <Media at="mobile">
                                        <Grid style={{"width":"100%", "height":"100%", "margin": "1rem 0 0 0"}}>
                                            <Grid.Row style={{"padding":0, "height":"45%"}}>
                                                <Grid.Column width={16} class="no-padding">
                                                    <Image class="how-to-image" alt='logos' src='/images/logos.png'/>
                                                </Grid.Column>
                                                <Grid.Column width={16} class="no-padding">
                                                    <Image class="invert-image" alt='logos' src='/images/1.herstellerAuswaehlen.png'/>
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row style={{"padding":0, "height":"5%"}}>
                                                <Grid.Column width={16} class="no-padding">
                                                    <Image class="how-to-image" alt='logos' src='/images/drucker.png'/>
                                                </Grid.Column>
                                                <Grid.Column width={16} class="no-padding">
                                                    <Image class="invert-image" alt='logos' src='/images/2.tonerFinden.png'/>
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column width={16} class="no-padding">
                                                    <Image class="how-to-image" alt='logos' src='/images/KartonGut.png'/>
                                                </Grid.Column>
                                                <Grid.Column width={16} class="no-padding">
                                                    <Image class="invert-image" alt='logos' src='/images/3.zustandDefinieren.png'/>
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column width={16} class="no-padding">
                                                    <Image class="how-to-image" alt='logos' src='/images/geld.png'/>
                                                </Grid.Column>
                                                <Grid.Column width={16} class="no-padding">
                                                    <Image class="invert-image" alt='logos' src='/images/4.geldKassieren.png'/>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Media>
                                    <Media greaterThanOrEqual="tablet">
                                        <Grid style={{"width":"100%", "maxHeight":"300px", "margin": "1rem 0 0 0"}}>
                                            <Grid.Row style={{"paddingBottom":0, "maxHeight":"15%"}}>
                                                <Grid.Column width={3} class="no-padding" />
                                                <Grid.Column width={1} class="no-padding">
                                                    <Image alt='pfeil' src='/images/Pfeil.png'/>
                                                </Grid.Column>
                                                <Grid.Column width={3} class="no-padding" />
                                                <Grid.Column width={1} class="no-padding">
                                                    <Image alt='pfeil' src='/images/Pfeil.png'/>
                                                </Grid.Column>
                                                <Grid.Column width={3} class="no-padding" />
                                                <Grid.Column width={1} class="no-padding">
                                                    <Image alt='pfeil' src='/images/Pfeil.png'/>
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row style={{"padding":0, "maxHeight":"45%"}}>
                                                <Grid.Column width={4} class="no-padding">
                                                    <Image class="how-to-image" alt='logos' src='/images/logos.png'/>
                                                </Grid.Column>
                                                <Grid.Column width={4} class="no-padding">
                                                    <Image class="how-to-image" alt='drucker' src='/images/drucker.png'/>
                                                </Grid.Column>
                                                <Grid.Column width={4} class="no-padding">
                                                    <Image class="how-to-image" alt='KartonGut' src='/images/KartonGut.png'/>
                                                </Grid.Column>
                                                <Grid.Column width={4} class="no-padding">
                                                    <Image class="how-to-image" alt='geld' src='/images/geld.png'/>
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row style={{"padding":0, "maxHeight":"5%"}}>
                                                <Grid.Column width={4} class="no-padding">
                                                    <Image class="invert-image" alt='1.herstellerAuswaehlen' src='/images/1.herstellerAuswaehlen.png'/>
                                                </Grid.Column>
                                                <Grid.Column width={4} class="no-padding">
                                                    <Image class="invert-image" alt='2.tonerFinden' src='/images/2.tonerFinden.png'/>
                                                </Grid.Column>
                                                <Grid.Column width={4} class="no-padding">
                                                    <Image class="invert-image" alt='3.zustandDefinieren' src='/images/3.zustandDefinieren.png'/>
                                                </Grid.Column>
                                                <Grid.Column width={4} class="no-padding">
                                                    <Image class="invert-image" alt='4.geldKassieren' src='/images/4.geldKassieren.png'/>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Media>
                                    </MediaContextProvider>
                                </div>
                                :
                                null

                        }
                    </div>
                </Grid.Row>
                <Grid.Row class="homepage-breadcrumb-wrapper">
                    <BreadCrumbPrinterCartridge/>
                </Grid.Row>
                <Grid.Row class="static-content-seo">
                    <div class="static-content-paragraph">
                        <h1>Volle / ungebrauchte Druckerpatronen verkaufen</h1>
                        <p>Sie fragen sich, wo Sie volle Toner + Druckerpatronen verkaufen können? <Link to="/wo-volle-toner-druckerpatronen-verkaufen">Hier sind Sie richtig!</Link></p>
                        <p>Verkaufen Sie volle, unbenutzte Druckerpatronen an dieTonervewerter. Sowohl für <Link to="/hersteller-waehlen">volle Toner</Link> als auch für <Link to="/tintenpatronen-verkaufen">volle Tintenpatronen</Link>  zahlen wir Ihnen einen fairen Preis.<br />
                        Über unseren Online-Produktkatalog bzw. unser Anfrageformular oben auf dieser Seite erhalten Sie umgehend ein Angebot.</p>
                        <br />

                        <h2>Volle Druckerpatronen unabhängig vom Zustand verkaufen</h2>
                        <p><b>Volle Toner, unbenutzte Trommeleinheiten</b> und <b>ungebrauchte Tintenpatronen</b> für Tintenstrahldrucker: Wir kaufen Druckerpatronen aller gängigen Hersteller - und zwar unabhängig vom Zustand der Druckerpatronen.<br />
                        <br />
                        <b>Verkaufen Sie volle Druckerpatronen u.a. folgender Hersteller:</b></p>                

                        <div class="hersteller" itemScope itemType="http://schema.org/ImageObject">
                        <Link to="/toner-finden/serie-waehlen/Brother"><img src="/images/logo-brother.png" itemProp="contentUrl" alt="Volle Druckerpatronen von Brother verkaufen" width="88" height="20" /></Link>
                            <meta itemProp="description" content="Volle Druckerpatronen von Brother verkaufen"/>
                        </div>

                        <div class="hersteller" itemScope itemType="http://schema.org/ImageObject">
                        <Link to="/toner-finden/serie-waehlen/Canon"><img src="/images/logo-canon.png" itemProp="contentUrl" alt="Volle Druckerpatronen von Canon verkaufen" width="95" height="20" /></Link>
                            <meta itemProp="description" content="Volle Druckerpatronen von Canon verkaufen"/>
                        </div>

                        <div class="hersteller" itemScope itemType="http://schema.org/ImageObject">
                            <img src="/images/logo-dell.png" itemProp="contentUrl" alt="Volle Druckerpatronen von Dell verkaufen" width="64" height="20" />
                            <meta itemProp="description" content="Volle Druckerpatronen von Dell verkaufen"/>
                        </div>

                        <div class="hersteller" itemScope itemType="http://schema.org/ImageObject">
                            <img src="/images/logo-epson.png" itemProp="contentUrl" alt="Volle Druckerpatronen von Epson verkaufen" width="83" height="20" />
                            <meta itemProp="description" content="Volle Druckerpatronen von Epson verkaufen"/>
                        </div>

                        <div class="hersteller" itemScope itemType="http://schema.org/ImageObject">
                        <Link to="/toner-finden/serie-waehlen/HP"><img src="/images/logo-hp.png" itemProp="contentUrl" alt="Volle Druckerpatronen von HP verkaufen" width="101" height="20" /></Link>
                            <meta itemProp="description" content="Volle Druckerpatronen von HP verkaufen"/>
                        </div>

                        <div class="hersteller" itemScope itemType="http://schema.org/ImageObject">
                        <Link to="/toner-finden/serie-waehlen/Kyocera"><img src="/images/logo-kyocera.png" itemProp="contentUrl" alt="Volle Druckerpatronen von Kyocera verkaufen" width="93" height="20" /></Link>
                            <meta itemProp="description" content="Volle Druckerpatronen von Kyocera verkaufen"/>
                        </div>

                        <div class="hersteller" itemScope itemType="http://schema.org/ImageObject">
                            <img src="/images/logo-lexmark.png" itemProp="contentUrl" alt="Volle Druckerpatronen von Lexmark verkaufen" width="96" height="20" />
                            <meta itemProp="description" content="Volle Druckerpatronen von Lexmark verkaufen"/>
                        </div>

                        <div class="hersteller" itemScope itemType="http://schema.org/ImageObject">
                            <img src="/images/logo-ricoh.png" itemProp="contentUrl" alt="Volle Druckerpatronen von Ricoh verkaufen" width="110" height="20" />
                            <meta itemProp="description" content="Volle Druckerpatronen von Ricoh verkaufen"/>
                        </div>

                        <div class="hersteller" itemScope itemType="http://schema.org/ImageObject">
                        <Link to="/toner-finden/serie-waehlen/Samsung"><img src="/images/logo-samsung.png" itemProp="contentUrl" alt="Volle Druckerpatronen von Samsung verkaufen" width="130" height="20" /></Link>
                            <meta itemProp="description" content="Volle Druckerpatronen von Samsung verkaufen"/>
                        </div>

                        <h2 class="clear">Wie hoch ist die Vergütung?</h2>
                        <p>Die Vergütung hängt von Art und Zustand der Druckerpatronen ab. Für Toner zahlen wir grundlegend mehr als für Tintenpatronen.<br />
                        Mit einer originalverpackten Druckerpatrone erzielen Sie den höchsten Preis. Bei beschädigten oder gänzlich fehlenden Verpackungen liegt die Vergütungshöhe niedriger. Um einen exakten Ankaufspreis zu erhalten, starten Sie bitte unseren Anfrageprozess (siehe oben) bzw. nutzen die Funktionalitäten unserer Internetseite.
                        </p>

                        <h2>Volle Druckerpatronen verkaufen, statt entsorgen</h2>
                        <p>Druckerpatronen, die Sie nicht mehr benötigen, dürfen nicht einfach so weggeworfen werden. Insbesondere bei der unsachgemäßen Entsorgung von Tonern sieht der Gesetzgeber empfindliche Bußgelder vor. Bei den Tonerverwertern <Link to="/voll-dabei/volle-druckerpatronen-entsorgen-gewinnbringend-und-umweltfreundlich">entsorgen Sie Druckerpatronen</Link> nicht nur <Link to="/voll-dabei/volle-toner-entsorgen-und-geld-verdienen">gemäß Kreislaufwirtschaftsgesetz und ElektroG</Link>, sondern außerdem gewinnbringend.</p> 
                        <br />
                        <p><u><Link to="/voll-dabei/sie-wollen-volle-druckerpatronen-verkaufen">Hier finden Sie weiterführende Informationen zum Druckerpatronen-Verkauf</Link></u></p>
                        
                        <hr></hr><br />

                        <div itemScope itemType="http://schema.org/Article">
                            <h3 itemProp="name">Informationen zum Verkauf für unbenutzte und volle Druckerpatronen</h3>
                            <meta itemProp="author" content="dieTonerverwerter"/>
                            <meta itemProp="datePublished" content="2020-01-17"/>
                            <meta itemProp="dateModified" content="2020-01-18"/>
                            <meta itemProp="mainEntityOfPage" content="https://dietonerverwerter.de/"/>
                            <meta itemProp="image" content="https://dietonerverwerter.de/images/mit-originalen-tonerpatronen-geld-verdienen.png"/>
                                
                            <div itemProp="publisher" itemScope itemType="https://schema.org/Organization">
                                <div itemProp="logo" itemScope itemType="https://schema.org/ImageObject">      
                                <meta itemProp="url" content="https://dietonerverwerter.de/images/logos/dietonerverwerter-round-logo.png"/>
                                <meta itemProp="width" content="340"/>
                                <meta itemProp="height" content="100"/>
                                </div>
                                <meta itemProp="name" content="dieTonerverwerter"/>
                            </div>  
                            
                            <div itemProp="articleBody">

                            <div class="hersteller-rechts" itemScope itemType="http://schema.org/ImageObject">
                                 <picture>
                                    <source srcSet="images/conditions/hpBoxClosed.webp" type="image/webp" />
                                    <img src="images/conditions/hpBoxClosed.png" itemProp="contentUrl" alt="Unbenutzte HP Druckerpatronen verkaufen" width="280" height="280" />
                                </picture>
                                <meta itemProp="description" content="Unbenutzte HP Druckerpatronen verkaufen"/>
                            </div>
                            
                            <p>Wenn man unbenutzte und noch volle Druckerpatronen verkaufen möchte, dann bieten wir bei dieTonerverwerter eine gute Möglichkeit dafür. Beim Ankauf von vollen Tintenpatronen, Tonerkartuschen und Trommeleinheiten, bekommen Sie hier mit nur wenig Aufwand einen fairen Preis.</p>
                            
                            <p>Auf unserer Internetseite erhalten Sie für den Ankauf Ihrer Druckerpatronen ein schnelles und umgehendes Angebot, hierfür kann das Anfrageformular (für Tintenpatronen) bzw. der onlinebasierte Produktkatalog (für Tonerkartuschen) genutzt werden.</p>
                            
                            <p>Angenommen werden abhängig vom Zustand, volle und noch unbenutzte Patronen aller gängigen Hersteller.</p>
                            
                            <p>Bei der Höhe der Vergütung wird der Artikel und der entsprechende Zustand beurteilt. Mit originalverpackten Druckerpatronen wird der höchste Preis erzielt. Möchte man Patronen mit einer geöffneten oder gar fehlenden Verpackung verkaufen, dann fällt der Ankaufspreis dementsprechend geringer aus. Um die Rückvergütung genau zu bestimmen, senden Sie uns einfach eine Anfrage über unser Formular. Gerne können Sie uns auch Fotos per E-Mail zukommen lassen. Wichtig ist, dass jede Originalpatrone, die Sie uns verkaufen wollen, noch versiegelt und unbenutzt ist.</p>
                            
                            <h3>Lieber verkaufen, anstatt wegzuwerfen</h3>
                            
                            <p>Volle Druckerpatronen, die nicht mehr benötigt werden, sollten nicht einfach so entsorgt bzw. weggeworfen werden. Sollten Sie eine volle Druckerpatrone bzw. mehrere Druckerpatronen unsachgemäß entsorgen, kann dies zu Strafen führen. Daher raten wir Ihnen, Ihre vollen Druckerpatronen einfach an dieTonerverwerter zu verkaufen. Wir legen höchsten Wert auf Nachhaltigkeit und einen sorgsamen Umgang mit Ressourcen. Mit unserem Kauf für volle und unbenutzte Toner bzw. Druckerpatronen, leisten wir einen wichtigen Beitrag zum Umweltschutz. Selbstverständlich richten wir uns bei all unseren Tätigkeiten nach dem Kreislaufwirtschaftsgesetz und dem ElektroG. Weiter nehmen wir als freiwilliger Teilnehmer am Umweltpakt Bayern teil und tragen das Gütesiegel Qualitätsverbund umweltbewusster Betriebe (QuB).</p>
                            
                            <div class="hersteller-rechts" itemScope itemType="http://schema.org/ImageObject">
                            <picture>
                                    <source srcSet="images/conditions/canonBoxClosed.webp" type="image/webp" />
                                    <img src="images/conditions/canonBoxClosed.png" itemProp="contentUrl" alt="Unbenutzte Canon Druckerpatronen verkaufen" width="280" height="280" />
                                </picture>
                                <meta itemProp="description" content="Unbenutzte Canon Druckerpatronen verkaufen"/>
                            </div>
                            
                            <h3 itemProp="headline">Der Ankauf von vollen Tonern und Druckerpatronen funktioniert ganz einfach</h3>
                            
                            <p>Für volle und noch unbenutzte Druckerpatronen holen Sie ganz einfach über unser Ankaufs-Tool bzw. unser Formular ein Angebot ein. Für den Versand per Paket gibt es bei Bedarf sogenannte ,,vorfrankierte Paketmarken" für DHL. Sobald die Sendung bei uns eingetroffen ist und überprüft wurde, erhalten Sie Ihre Zahlung innerhalb weniger Werktage auf Ihr Bankkonto überwiesen.</p>
                            
                            <p><u>Ihre Vorteile auf einem Blick:</u></p>
                            
                                <ul style={{"listStyleType":"none"}} >
                                    <li>Verkauf Ihrer Restanten zum Festpreis lt. Angebot</li>
                                    <li>Faire Preise für volle Tintenpatronen und Tonerkartuschen</li>
                                    <li>Bei Bedarf: Bereitstellung von vorfrankierten Paketmarken</li>
                                    <li>Schnelle Auszahlung Ihrer Vergütung auf Ihr Bankkonto</li>
                                </ul>
                                
                            <h4>Vor dem Ankauf ist nach dem Verkauf</h4>
                            
                            <p>Da sich die Vergütung nicht nur nach dem Modell der Druckerpatronen richtet, sollte man stets vor dem Verkaufen den Zustand der Verpackung kontrollieren. Es macht natürlich beim Verkaufen einen unterschied, wenn volle Druckerpatronen in der ungeöffneten Originalverpackung verkauft werden, im Vergleich zu nur noch versiegelter Ware ohne jegliche Verpackung. Volle Patronen in einer unbeschädigten Verpackung, sind am meisten Wert!</p>
                            
                            <div class="hersteller-rechts" itemScope itemType="http://schema.org/ImageObject">
                                <picture>
                                    <source srcSet="images/conditions/brotherBoxClosed.webp" type="image/webp" />
                                    <img src="images/conditions/brotherBoxClosed.png" itemProp="contentUrl" alt="Unbenutzte Brother Druckerpatronen verkaufen" width="280" height="280" />
                                </picture>
                                <meta itemProp="description" content="Unbenutzte Brother Druckerpatronen verkaufen"/>
                            </div>
                            
                            <h2>Was versteht man unter ungebrauchten Druckerpatronen und wieso wird ein Ankauf angeboten?</h2>
                            
                            <p>Als ungebrauchte Druckerpatronen, werden volle, mindestens noch versiegelte Tonerkartuschen und Trommeleinheiten, als auch noch verschlossene Tintenpatronen bezeichnet.</p>
                            
                            <p>Solche Patronen sollen nicht einfach auf dem Müll landen, sondern einer Wiederverwendung zugeführt werden. Daher kaufen wir volle Druckerpatronen von allen bekannten Markenherstellern wie: Brother, Canon, Epson, HP und vielen weiteren an.</p>
                            
                            <p>Möchten Sie also schnell, sicher und unkompliziert volle Druckerpatronen verkaufen und zu Geld machen, dann sind wir bei dieTonerverwerter die richtige Adresse dafür.</p>
                            
                            <p>Sie als Kunde, können sich hier sicher sein, für volle Druckerpatronen einen fairen Preis zu guten Konditionen zu bekommen. <Link to="/bewertungen-und-erfahrungen">Bewertungen und Erfahrungen von anderen Kunden zu unserem Ankauf finden Sie hier.</Link> Was will man als Verkäufer mehr!?</p>
                            
                            </div>
                            
                        </div>                    
                    
                    
                    
                    </div>
                </Grid.Row>
            </Grid>
        );
    }
}