import React from 'react';
import {Grid} from "semantic-ui-react"

import PrinterCartridgeTypeList from "../components/PrinterCartridgeTypeList";
import config from '../../config/main.config'
import BreadCrumbPrinterCartridge from "../components/BreadCrumbPrinterCartridge";
import history from "../helper/browserHistory"
import { withNamespaces } from 'react-i18next';
import MetaTags from "../components/MetaTags"
import {getTokenFromLocalStorage} from "../helper/util";

@withNamespaces()
export default class PrinterCartridgePrinterModel extends React.Component {

    constructor(props) {
        super(props);
        if (props.staticContext) {

            let formatPrinterModels = [];
            for(let prop in props.staticContext) {
                if(props.staticContext[prop].hasOwnProperty("printerModel")) {
                    formatPrinterModels.push(props.staticContext[prop].printerModel)
                }
            }

            this.state = {
                printerModels: formatPrinterModels
            };

        } else {
            this.state = {
                printerModels: []
            };
        }

    }

    componentDidMount() {
        const { series, manufacturer } = this.props.match.params;

        if(typeof series !== 'undefined' && typeof manufacturer !== 'undefined') {
            if (series.slice(-1) === "_") {
                history.replace('/toner-nicht-gefunden');
            } else {
                let realSeries = decodeURI(series).replace(/_/g, ' ');
                let realManufacturer = decodeURI(manufacturer).replace(/_/g, ' ');

                localStorage.setItem(config.STORAGE_KEY_SELECTED_PRINTER_CARTRIDGE, JSON.stringify({
                    manufacturer: realManufacturer,
                    series: realSeries === 'null' ? null : realSeries
                }));

                let urlToFetch = decodeURI(series) === 'null' ?
                    config.BASE_URL + "printerCartridges/printers/seriesNull?manufacturer=" + decodeURI(realManufacturer)
                    :
                    config.BASE_URL + "printerCartridges/printers?series=" + decodeURI(realSeries) + "&manufacturer=" + decodeURI(realManufacturer);;

                const userToken = getTokenFromLocalStorage();
                let headers = config.HEADERS;
                if(userToken != null){
                    headers.token =  userToken
                }

                fetch(urlToFetch, {
                    method: 'GET',
                    headers: headers
                }).then((response) => {
                    if (response.status >= 200 && response.status < 400) {
                        response.json().then(json => {
                            if(json.length === 0) {
                                history.replace('/toner-nicht-gefunden');
                            }
                            else {
                                let printerModels = json.map(result => result.printerModel);

                                this.setState({
                                    printerModels: printerModels
                                });
                            }
                        });

                    } else {
                        history.push('/toner-nicht-gefunden');
                    }

                }).catch(error => {
                    history.push('/toner-nicht-gefunden');
                });
            }
        }
        else {
            history.push('/toner-nicht-gefunden');
        }

    }

    render() {
        const {t} = this.props;
        const {manufacturer, series} = this.props.match.params;
        let add = {manufacturer: manufacturer, series: series}

        return (
            <Grid stackable class='main-grid'>
                <MetaTags page="drucker-waehlen" add={add}/>
                <Grid.Row class='main-grid-header-row'>
                    <Grid.Column>
                        <h1 className="fira big">
                            {t('printerCartridge.printerModel.headline', add)}
                        </h1>
                        <a href="/service" className="fira small block">
                            {t('printerCartridge.printerModel.subheadline')}
                        </a>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row class='main-grid-main-row'>
                    <PrinterCartridgeTypeList data={this.state.printerModels}
                                              textForNull={t('printerCartridge.printerModel.textForNull')}
                                                aHrefItem={(printerModel) => {
                                                    const { series, manufacturer } = this.props.match.params;
                                                    if(typeof manufacturer !== 'undefined' && manufacturer !== 'null') {
                                                        let replacedManufacturer = manufacturer.replace(/\s/g, '_');
                                                        let replacedSeries = series.replace(/\s/g, '_');
                                                        let replacedPrinterModel = printerModel;
                                                        if(printerModel !== null) {
                                                            replacedPrinterModel = printerModel.replace(/\s/g, '_');
                                                        }

                                                        return('/toner-finden/kartusche-waehlen/' + replacedManufacturer + '/' + replacedSeries + '/' + replacedPrinterModel)
                                                    }
                                                }}
                                                notFound = {{text: t('printerCartridge.printerModel.notFound'), link: "/nicht-aufgelistet"}}
                                            />
                </Grid.Row>
                <Grid.Row class='bread-crumb-row main-grid-footer-row'>
                    <BreadCrumbPrinterCartridge
                        activeItem='type'
                        forceBottom={true}/>
                </Grid.Row>
            </Grid>
        );
    }
}