import React from 'react';
import MetaTags from "../components/MetaTags"
import {Link} from "react-router-dom";


export default class Impress extends React.Component {

    render() {

        return (
            <div class="static-content">
                <MetaTags page="DHL503" />
                <div class="static-content-paragraph">
                    <h1>Der Deutsche Post DHL Server ist zur Zeit leider nicht verfügbar</h1>
                    <br />
                    <p><b>Leider antwortet der Server der Deutschen Post DHL Group nicht mit der Erstellung der angeforderten Versandschein(e).</b><br />
                    Das kann an einer Überlastung des Servers oder an Wartungsarbeiten liegen.</p>
                    <Link to="service" title="Bitte kontaktieren Sie uns">
                        Bitte kontaktieren Sie uns, damit wir Ihnen die Versandschein(e) manuell zukommen lassen, sobald der Server wieder verfügbar ist.       
                    </Link>
                    <br /> <br />
                    <p>Vielen Dank</p>
                </div>
            </div>
        );
    }
}