/**
 * Created by Matijas on 11.04.2017.
 */

import {isDevelop} from '../js/helper/util';

export default {
    BASE_URL: isDevelop() ? "http://localhost:3000/api/" : "/api/",
    BASE_IMAGE_URL: isDevelop() ? "http://localhost:3000/images/" : "/images/",
    STORAGE_KEY_SELECTED_PRINTER_CARTRIDGE: "4WfG5jv3",
    STORAGE_KEY_STATE: "1CfB3jv7",
    TOKEN_STORAGE_KEY: "1336/(/%%&))=UDHDJ/Djdjsadawdj7123122mdkfööö",
    STORAGE_KEY_SHOPPING_CART: "1337|)4RK70R|)574Y3R(°-°)",
    HEADERS: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'pragma': 'no-cache',
        'cache-control': 'no-cache',
        'Access-Control-Allow-Origin': '*'
    }
}