import React from 'react';
import {Grid} from "semantic-ui-react"

import PrinterCartridgeTypeList from "../components/PrinterCartridgeTypeList";
import config from '../../config/main.config'
import BreadCrumbPrinterCartridge from "../components/BreadCrumbPrinterCartridge";
import history from "../helper/browserHistory";
import {withNamespaces} from 'react-i18next';
import MetaTags from "../components/MetaTags"
import {getTokenFromLocalStorage} from "../helper/util";

@withNamespaces()
export default class PrinterCartridgeItemNumber extends React.Component {

    constructor(props) {
        super(props);
        if (props.staticContext) {

            let formatItemNumbers = [];
            for (let prop in props.staticContext) {
                if (props.staticContext[prop].hasOwnProperty("itemNumber") && props.staticContext[prop].hasOwnProperty("originalItemNumber")) {
                    formatItemNumbers.push({
                        itemNumber: props.staticContext[prop].itemNumber,
                        originalItemNumber: props.staticContext[prop].originalItemNumber
                    })
                }
            }

            this.state = {
                itemNumbers: formatItemNumbers
            };

        } else {
            this.state = {
                itemNumbers: []
            };
        }

    }

    componentDidMount() {
        const {printerModel, manufacturer, series} = this.props.match.params;

        if (typeof printerModel !== 'undefined' && typeof manufacturer !== 'undefined' && typeof series !== 'undefined') {

            if (printerModel.slice(-1) === "_") {
                history.replace('/toner-nicht-gefunden');
            } else {
                let realSeries = decodeURI(series).replace(/_/g, ' ');
                let realManufacturer = decodeURI(manufacturer).replace(/_/g, ' ');
                let realPrinterModel = decodeURI(printerModel).replace(/_/g, ' ');

                localStorage.setItem(config.STORAGE_KEY_SELECTED_PRINTER_CARTRIDGE, JSON.stringify({
                    manufacturer: realManufacturer,
                    series: realSeries === 'null' ? null : decodeURI(series),
                    printer: realPrinterModel === 'null' ? null : decodeURI(printerModel)
                }));

                let urlToFetch = realSeries === 'null' || realPrinterModel === 'null' ?
                    config.BASE_URL + "printerCartridges/itemNumber/printerOrSeriesNull?manufacturer=" + realManufacturer + "&series=" + realSeries + "&printer=" + realPrinterModel
                    :
                    config.BASE_URL + "printerCartridges/itemNumber?printer=" + realPrinterModel + "&manufacturer=" + realManufacturer + "&series=" + realSeries;

                //Send token if logged in to get also ink results
                const userToken = getTokenFromLocalStorage();
                let headers = config.HEADERS;
                if (userToken != null) {
                    headers.token = userToken
                }

                fetch(urlToFetch, {
                    method: 'GET',
                    headers: headers
                }).then((response) => {
                    if (response.status >= 200 && response.status < 400) {
                        response.json().then(json => {
                            if (json.length === 0) {
                                history.replace('/toner-nicht-gefunden');
                            } else {
                                this.setState({
                                    itemNumbers: json.map(result => {

                                        return {
                                            itemNumber: result.itemNumber,
                                            originalItemNumber: result.originalItemNumber
                                        }

                                    })
                                });
                            }

                        });

                    } else {
                        history.replace('/toner-nicht-gefunden');
                    }

                }).catch(error => {
                    history.replace('/toner-nicht-gefunden');
                });
            }


        } else {
            history.replace('/toner-nicht-gefunden');
        }

    }

    render() {
        const {t} = this.props;
        const {manufacturer, series, printerModel} = this.props.match.params;
        let add = {manufacturer: manufacturer, series: series, printerModel: printerModel.replace(series, '')} //add duplicate "series" in printerModell

        return (
            <Grid stackable class='main-grid'>
                <MetaTags page="kartusche-waehlen" add={add}/>
                <Grid.Row class='main-grid-header-row'>
                    <Grid.Column>
                        <h2 className="fira big">
                            {t('printerCartridge.itemNumber.headline')}
                        </h2>
                        <a href="/service" className="fira small block">
                            {t('printerCartridge.itemNumber.subheadline')}
                        </a>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row class='main-grid-main-row'>
                    <PrinterCartridgeTypeList data={this.state.itemNumbers.map(result => result.originalItemNumber)}
                                              onClickItem={(originalItemNumber, index) => {
                                                  const {printerModel, manufacturer, series} = this.props.match.params;
                                                  let findItemNumber = this.state.itemNumbers[index];

                                                  if (
                                                      typeof printerModel !== 'undefined' && printerModel !== 'null' &&
                                                      typeof manufacturer !== 'undefined' && manufacturer !== 'null' &&
                                                      typeof series !== 'undefined' && series !== 'null'
                                                  ) {

                                                      let realSeries = decodeURI(series).replace(/_/g, ' ');
                                                      let realManufacturer = decodeURI(manufacturer).replace(/_/g, ' ');
                                                      let realPrinterModel = decodeURI(printerModel).replace(/_/g, ' ');


                                                      if (typeof findItemNumber !== 'undefined') {
                                                          if (typeof localStorage !== "undefined") {
                                                              localStorage.setItem(config.STORAGE_KEY_SELECTED_PRINTER_CARTRIDGE, JSON.stringify({
                                                                  manufacturer: realManufacturer,
                                                                  series: realSeries,
                                                                  printer: realPrinterModel,
                                                                  itemNumber: findItemNumber.itemNumber,
                                                                  originalItemNumber: originalItemNumber,
                                                              }));
                                                          }
                                                      }
                                                  }
                                              }}
                                              aHrefItem={(originalItemNumber, index) => {

                                                  let findItemNumber = this.state.itemNumbers[index];

                                                  if (typeof findItemNumber !== 'undefined') {
                                                      return ('/zustand-definieren/' + findItemNumber.itemNumber);
                                                  } else {
                                                      return ('/toner-nicht-gefunden'); //maybe some work needed
                                                  }
                                              }}
                                              notFound={{
                                                  text: t('printerCartridge.itemNumber.notFound'),
                                                  link: "/nicht-aufgelistet"
                                              }}
                    />
                </Grid.Row>
                <Grid.Row class='main-grid-main-row'>
                    <Grid.Column>
                        <h1 className="fira small block">
                            {t('printerCartridge.itemNumber.headlineForSEO', add)}
                        </h1>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row class='bread-crumb-row main-grid-footer-row'>
                    <BreadCrumbPrinterCartridge
                        activeItem='type'
                        forceBottom={true}/>
                </Grid.Row>
            </Grid>
        );
    }
}