import React from 'react';
import {Grid, Icon, Image} from "semantic-ui-react"
import {Link} from 'react-router-dom';
import BreadCrumbPrinterCartridge from "../components/BreadCrumbPrinterCartridge";
import {withNamespaces} from 'react-i18next';
import MetaTags from "../components/MetaTags"

@withNamespaces()
export default class LppriceInquiry extends React.Component {

    render() {
        return (
            <Grid stackable class='white homepage-wrapper'>
                <MetaTags page="preisanfrage-druckerpatronen" />
                <Grid.Row>
                    <Grid.Column mobile={16} tablet={16} computer={8}>
                       
                        <h1>Preisanfrage für unbenutzte Druckerpatronen</h1>
                        <hr></hr>
                        <br />
                        <p>Ihr Schrank quillt über mit vollen Druckerpatronen, dabei haben Sie den dazugehörenden Drucker schon lange entsorgt? Dann sind Sie hier richtig. Wir kaufen diese Restbestände!<br />
                        <br />
                        Einfach die folgende Preisanfrage herunterladen, ausfüllen und an <a href="mailto:info@sanocycling.de" className="white">info@sanocycling.de</a> senden.<br />
                        Gerne können Sie uns auch Ihre eigenen Auflistungen bzw. Dateien zukommen lassen.
                        </p>
                        
                        <a href="/download/Preisanfrage-Druckerpatronen-bei-dieTonerverwerter_de.pdf" title="Preisanfrage für unbenutzte Druckerpatronen herunterladen" className="white" target="_blank">
                            <img src="/images/preisanfrage-druckerpatronen.jpg" alt="Preisanfrage für unbenutzte Druckerpatronen herunterladen"/><br />
                            <b>Preisanfrage für Druckerpatronen hier herunterladen</b><br />
                        </a>
                        
                        <br />
                        <br />
                        <hr></hr>
                        <h2>Verkaufen statt entsorgen</h2>
                        <p>Zum Wegwerfen sind solche unbenutzten Tonerkartuschen und Tintenpatronen doch viel zu schade? Da haben Sie völlig Recht!
        Verkaufen statt wegwerfen – Füllen Sie unsere Preisanfrage für unbenutzte Druckerpatronen aus und wir senden Ihnen ruckzuck ein Angebot.<br />
                        <br />
                        Sie haben wieder Platz, die Druckerpatronen landen nicht auf dem Müll UND eine Vergütung gibt es auch noch.</p>
                        <br />
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={16} computer={8}>
                        <Image alt='mit-originalen-tonerpatronen-geld-verdienen' src='/images/mit-originalen-tonerpatronen-geld-verdienen.png'/>
                    </Grid.Column>
                </Grid.Row>
                
                <Grid.Row class="homepage-breadcrumb-wrapper">
                    <BreadCrumbPrinterCartridge/>
                </Grid.Row>
            </Grid>
        );
    }
}