import React from 'react';
import MetaTags from "../components/MetaTags"

export default class Impress extends React.Component {

    render() {

        return (
            <div class="static-content">
                <MetaTags page="impressum" />
                <div class="static-content-paragraph">
                    <h1>Impressum</h1>
                    <br />
                    <strong><span className="Haendlerbund_Rechtstext_Absatz"><span className="Haendlerbund_Rechtstext_Paragraph"><span className="Haendlerbund_Rechtstext_Untertitel">Gesetzliche Anbieterkennung:</span></span></span><br />
                    <br />
                    <span className="Haendlerbund_Rechtstext_Absatz">Sanocycling GmbH<br />
                        diese vertr. d. d. Geschäftsführer Patrick Höhne</span><br />
                    <span className="Haendlerbund_Rechtstext_Absatz">Bahnhofstr. 26<br />
                        91161 Hilpoltstein<br />
                        Deutschland</span><br />
                    <span className="Haendlerbund_Rechtstext_Absatz">Telefon: 09174/9767-27</span><br />
                    <span className="Haendlerbund_Rechtstext_Absatz">E-Mail: </span><span className="Haendlerbund_Rechtstext_Absatz">info@sanocycling.de<br />
                        USt-IdNr.: DE281993964</span><span className="Haendlerbund_Rechtstext_Absatz" /></strong><strong><span className="Haendlerbund_Rechtstext_Absatz" /></strong><br />
                    <strong><span className="Haendlerbund_Rechtstext_Absatz">eingetragen im Handelsregister des Amtsgerichtes Nürnberg<br />
                        Handelsregisternummer HRB 28281</span></strong><br />
                    <br />
                    <span className="Haendlerbund_Rechtstext_Absatz" /><br />
                    <br />
                    <span className="Haendlerbund_Rechtstext_Absatz" /><br />
                    <br />
                    <span className="Haendlerbund_Rechtstext_Absatz"><strong>Wir sind seit&nbsp;</strong></span><span className="Haendlerbund_Rechtstext_Absatz"><strong>01.03.2017&nbsp;</strong></span><span className="Haendlerbund_Rechtstext_Absatz"><strong>Mitglied der Initiative "FairCommerce".</strong><br />
                    Nähere Informationen hierzu finden Sie unter www.fair-commerce.de.</span><br />
                    <br />     

                    <span className="Haendlerbund_Rechtstext_Absatz"><strong>Alternative Streitbeilegung:</strong><br />
                    Die Europ&auml;ische Kommission stellt eine Plattform f&uuml;r die au&szlig;ergerichtliche Online-Streitbeilegung (OS-Plattform) bereit, aufrufbar unter&nbsp;</span><a href="https://ec.europa.eu/consumers/odr" 
                    target="_blank">https://ec.europa.eu/odr</a><span className="Haendlerbund_Rechtstext_Absatz">.</span><br />    
                    
                       
                </div>
            </div>

        );
    }
}