import React from 'react';
import {observer} from "mobx-react";
import {observable, toJS} from "mobx";

import {Divider, Header, Image, Segment, Grid, Icon, Message, Modal, Button, Form} from 'semantic-ui-react'
import {Link} from 'react-router-dom'

import config from "../../config/main.config";
import history from "../helper/browserHistory";
import {withNamespaces} from "react-i18next";

import {validateEmail} from '../helper/util'
import MetaTags from "../components/MetaTags"

import ReactPlayer from 'react-player/lazy'

const DEFAULT_ARTICLE = {
    manufacturer: '',
    article: '',
    amount: '',
    state: '',
    expireDate: '',
    hint: 0
}

const DEFAULT_USER_DATA = {
    name: '',
    email: ''
}

const DEFAULT_USER_DATA_ERROR = {
    name: false,
    email: false,
}

const STATE_OPTIONS = [
    {
        key: 1,
        text: 'Originalverpackt & ungeöffnet',
        value: 'closed'
    }, {
        key: 2,
        text: 'Karton bereits geöffnet',
        value: 'open'
    }
]

const MANUFACTURER_OPTIONS = [
    {
        key: 1,
        text: 'Brother',
        value: 'Brother'
    }, {
        key: 2,
        text: 'Canon',
        value: 'Canon'
    }, {
        key: 3,
        text: 'Dell',
        value: 'Dell'
    }, {
        key: 4,
        text: 'Epson',
        value: 'Epson'
    }, {
        key: 5,
        text: 'HP',
        value: 'HP'
    }, {
        key: 6,
        text: 'Konica Minolta',
        value: 'Konica Minolta'
    }, {
        key: 7,
        text: 'Kyocera',
        value: 'Kyocera'
    }, {
        key: 8,
        text: 'Lexmark',
        value: 'Lexmark'
    }, {
        key: 9,
        text: 'OKI',
        value: 'OKI'
    }, {
        key: 10,
        text: 'Olivetti',
        value: 'Olivetti'
    }, {
        key: 11,
        text: 'Panasonic',
        value: 'Panasonic'
    }, {
        key: 12,
        text: 'Philips',
        value: 'Philips'
    }, {
        key: 13,
        text: 'Ricoh',
        value: 'Ricoh'
    }, {
        key: 14,
        text: 'Samsung',
        value: 'Samsung'
    }, {
        key: 15,
        text: 'Sharp',
        value: 'Sharp'
    }, {
        key: 16,
        text: 'Toshiba',
        value: 'Toshiba'
    }, {
        key: 17,
        text: 'Xerox',
        value: 'Xerox'
    }, {
        key: 18,
        text: 'Anderer',
        value: 'Anderer'
    }
]


/*https://stackblitz.com/edit/declarative-focus-demo-j2xrag
const utilizeFocus = () => {
	const ref = React.createRef()
	const setFocus = () => {ref.current &&  ref.current.focus()}
	return {setFocus, ref} 
}*/

@withNamespaces()
@observer
export default class RequestPage extends React.Component {

    constructor(props) {
        super(props);
        this.requestForm = React.createRef();
        this.requestError = React.createRef();
        //this.input1Focus = utilizeFocus()
    }

    @observable
    userData = DEFAULT_USER_DATA

    @observable
    userDataError = DEFAULT_USER_DATA_ERROR

    @observable
    articles = [DEFAULT_ARTICLE]

    @observable
    checkedConditions = false

    @observable
    fieldError = false

    @observable
    showSuccessModal = false

    @observable
    loading = false


    submit() {
        this.loading = true

        //check mandatory fields
        if (!this.mandatoryFilled()){ 
            this.requestError.current.scrollIntoView()
            this.loading = false
            
            //this.input1Focus.setFocus()
            return
        }
        const user = toJS(this.userData)
        const data = toJS(this.articles)

        fetch(config.BASE_URL + 'request', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                articles: data,
                ...user
            })
        }).then((resp) => resp.json())
            .then(data => {
                this.showSuccessModal = true;

                //https://analystadmin.medium.com/implementing-google-analytics-and-google-tag-manager-into-a-react-js-app-e986579cd0ee
                window.dataLayer.push({
                    event: 'requestSubmitted'
                  });

            })
            .catch(function (error) {
                //send exception mail
                alert("Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie unseren Service!")
            });
    }

    mandatoryFilled() {
        let missingFields = false;

        for (const key in this.userData) {
            // skip loop if the property is from prototype
            if (!this.userData.hasOwnProperty(key)) 
                continue;
            
            //check if error object has this key
            if (!this.userDataError.hasOwnProperty(key)) 
                continue;
            
            const obj = this.userData[key];


            
            if (obj.length === 0) {
                missingFields = true;
                this.userDataError[key] = 'Bitte füllen Sie dieses Feld aus';
            } else {
                this.userDataError[key] = false;

                //check email
                if(key === 'email'){
                    if (!validateEmail(this.userData.email)){
                        missingFields = true;
                        this.userDataError[key] = 'Bitte überprüfen Sie die Schreibweise Ihrer E-Mailadresse';
                    }
                }
            }
        }
        

        /*if (this.checkedConditions) {
            this.userDataError.checkedConditions = false;
        } else {
            missingFields = true;
            this.userDataError.checkedConditions = true;
        }*/

        this.fieldError = missingFields;
        return !missingFields
    }

    render() {
        const {t} = this.props;
          
        return (
            <div class="request">
                 <MetaTags page="request" />
                {<Modal
                    centered={false}
                    open={this.showSuccessModal}
                    onClose={() => {this.showSuccessModal = false
                        history.push("/voll-dabei/das-ist-beim-versand-von-vollen-tonerkartuschen-zu-beachten")
                    }}
                >
                    <Modal.Header>{t('request.modal-title', {name: this.userData.name})}</Modal.Header>
                    <Modal.Content scrolling>
                        <p>{t('request.modal-text', {email: this.userData.email})}</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button positive onClick={() => history.push("/voll-dabei/das-ist-beim-versand-von-vollen-tonerkartuschen-zu-beachten")}> <Icon name={'check'}/>{t('request.modal-button')}</Button>
                    </Modal.Actions>
                </Modal>}

                <div class="requestparagraph">

                    <Grid >
                        <Grid.Row >
                            <Grid.Column computer={10} tablet={9} mobile={16}>
                                <h1>{t('request.title')}</h1>
                            </Grid.Column >   
                            <Grid.Column mobile={16} style={{ marginTop: "20px"}} only='mobile'>
                                <Link to='/bewertungen-und-erfahrungen' target="_blank">
                                    <Image
                                        alt='Wir haben Top-Bewertungen auf ProvenExpert'
                                        src='/images/ProvenExpert-Bewertungssiegel-Mobile.png'
                                        size=''
                                        className="pointer"
                                        centered
                                    />
                                    </Link>                             
                            </Grid.Column >
                                
                            <Grid.Column computer={10} tablet={9} mobile={16} style={{marginTop: "20px"}}>        
                                <p>An uns können Sie Ihre übrig gebliebenen Tintenpatronen und Tonerkartuschen der Originalhersteller schnell, einfach und zuverlässig verkaufen. <b onClick={() => this.requestForm.current.scrollIntoView()} className="pointer">Nutzen Sie unser Anfrageformular</b>. Wir senden Ihnen zügig ein Angebot und somit einen Ankaufswert für Ihr übrig gebliebenes, unbenutztes Druckerzubehör per E-Mail zu.</p><br />
                                <Button class='white button primary' onClick={() => this.requestForm.current.scrollIntoView()} style={{"text-align": "center"}}>
                                            Direkt zum Anfrageformular <Icon name='chevron right'/>
                                </Button>
                                <br />  <br />  
                                {/*<div style={{"text-align": "center"}} >
                                    <iframe className="onlyDesktop" width="720" height="405" src="https://www.youtube-nocookie.com/embed/GyxuZ2RWwOs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    <iframe className="onlyMobile" width="320" height="180" src="https://www.youtube-nocookie.com/embed/GyxuZ2RWwOs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>*/}

                                <div className='player-max' style={{"margin": "0px auto"}} >
                                    <div className='player-wrapper'>
                                            <ReactPlayer className='react-player' playing url='videos/dietonerverwerter-720.mp4' controls='true' light='videos/dietonerverwerter-720.jpg' width='100%' height='100%' 
                                                config={{ file: { 
                                                    attributes: {
                                                    controlsList: 'nofullscreen nodownload'
                                                    }
                                                }}}
                                            />
                                    </div>
                                </div>

                                <br />  <br />  
                                <Image className="onlyMobile" src="images/unbenutzte-Druckerpatronen-in-allen-Zustaenden-verkaufen-mobil.png" alt="Bei uns können Sie unbenutzte Druckerpatronen in allen Zuständen verkaufen" />
                                <Image className="onlyDesktop" src="images/unbenutzte-Druckerpatronen-in-allen-Zustaenden-verkaufen.png" alt="Bei uns können Sie unbenutzte Druckerpatronen in allen Zuständen verkaufen" />
                                <br />    
                               

                                <Segment>
                                    <p>Die Ermittlung des Zustands ist kinderleicht: <u>A, B, C oder gar D-Ware gibt es bei uns nicht!</u><br />
                                    <br />
                                    <Button class='white button primary' onClick={() => this.requestForm.current.scrollIntoView()} style={{"text-align": "center"}}>
                                            Direkt zum Anfrageformular <Icon name='chevron right'/>
                                    </Button>
                                     <br /><br />
                                    Bei uns ist es <span class='packageType'>egal, ob</span> es sich um die <span class='packageType'>neueste</span> oder eine <span class='packageType'>ältere Verpackung</span> handelt.<br /> <span class='packageSticker'>Aufkleber</span> auf dem Karton (z.B. Adresslabels oder Inventuraufkleber) <span class='packageSticker'>sind kein Problem</span> und können drauf bleiben.<br />
                                    Selbst <span class='packageDamage'>kleinere Beschädigungen</span> an der Verpackung wie Eindrückungen oder kleinere Löcher <span class='packageDamage'>sind in Ordnung</span>!<br />
                                    <br />
                                    Für uns ist es nur wichtig, ob die unbenutzte Druckerpatrone noch originalverpackt ist, oder nicht. <b>Ganz einfach</b>!
                                    <br />
                                    
                                    <br />
                                    <i>Alternative zum Onlineformular: <a href="/download/Anfrageformular.pdf" title="Anfrageformular für volle Original-Druckerpatronen herunterladen">Hier PDF-Anfrageformular herunterladen</a> oder eigene Auflistung per E-Mail an <a href="mailto:info@sanocycling.de">info@sanocycling.de</a> senden.</i> 
                                    </p>
                                </Segment>
                                <br />
                                    <p>Wussten Sie, dass Sie ihr überschüssiges Vollgut total einfach, schnell und stressfrei gegen Bares verkaufen können? Solange es sich um unbenutzte <b>Druckerpatronen der Originalhersteller</b> handelt, sind wir Ihr Partner. Die Originalhersteller sind:  <i>Brother, 
                                    Canon, 
                                    Dell, 
                                    Epson, 
                                    HP (Hewlett-Packard),
                                    Konica Minolta,
                                    Kyocera,
                                    Lexmark,
                                    OKI,
                                    Olivetti,
                                    Panasonic,
                                    Philips,
                                    Ricoh,
                                    Samsung,
                                    Sharp,
                                    Toshiba,
                                    Utax und Xerox.</i><br />
                                    <br />
                                    Wir sind der top bewertete Dienstleister für den Rückkauf von übrig gebliebenen Druckerpatronen. Egal ob es dabei um Tintenpatronen, Tonerkartuschen, oder Trommeleinheiten handelt. Selbst abgelaufene Artikel können noch bares Geld wert sein. Füllen Sie einfach das folgende Anfrageformular aus und erhalten Sie Ihr persönliches Angebot per E-Mail.
                                    </p>
                            </Grid.Column>
                            <Grid.Column computer={5} tablet={7} textAlign={"center"} only='computer tablet'>
                                
                                <Link to='/bewertungen-und-erfahrungen' target="_blank">
                                    <Image
                                            alt='Wir haben Top-Bewertungen auf ProvenExpert'
                                            src='/images/ProvenExpert-Bewertungssiegel.png'
                                            size=''
                                            className="pointer"
                                            centered
                                        />
                                    <br />
                                    <Image
                                        alt='dieTonerverwerter.de'
                                        src='/images/ProvenExpert-Top-Dienstleister-2022.png'
                                        size=''
                                        className="pointer"
                                        centered
                                    />
                                </Link>   
                                    
                            </Grid.Column>
                        </Grid.Row>
                        <div class="ui divider" id ="requestForm" ref={this.requestForm}></div>
                        <Grid.Row >
                            <Grid.Column computer={16} tablet={16} mobile={16}>
                                <h2>{t('request.your-articles')}</h2>
                                <Segment>
                                    <Form>
                                        {this
                                            .articles
                                            .map((article, index) => <div key={index}>
                                                <Header as='h3'>Artikel {index + 1}: {this.articles[index].manufacturer} {this.articles[index].article}</Header>
                                                <Form.Group widths='equal'>
                                                    <Form.Select
                                                        fluid
                                                        label={t('request.manufacturer')}
                                                        options={MANUFACTURER_OPTIONS}
                                                        placeholder='Herseller wählen ...'
                                                        onChange={(e, {value}) => this.articles[index].manufacturer = value}
                                                        />

                                                <Form.Input
                                                        label={t('request.article')}
                                                        value={article.article}
                                                        onChange={(e, {value}) => this.articles[index].article = value}
                                                />

                                                </Form.Group>
                                                <Form.Group widths='equal'>
                                                    <Form.Input
                                                        fluid
                                                        label={t('request.amount')}
                                                        value={article.amount}
                                                        onChange={(e, {value}) => this.articles[index].amount = value}
                                                        width={3}/>
                                                    <Form.Select
                                                        label={t('request.state')}
                                                        options={STATE_OPTIONS}
                                                        placeholder='Bitte wählen ...'
                                                        onChange={(e, {value}) => { 
                                                            //this.articles[index].state = value
                                                            //search for fitting text in STATE_OPTIONS
                                                            let text = STATE_OPTIONS.filter(v => v.value.includes(value))[0].text
                                                            this.articles[index].state = text
                                                            this.articles[index].hint = value === 'open' ? 1 : 0
                                                        }}
                                                        width={7}/>
                                                    <Form.Input
                                                        label={t('request.expireDate')}
                                                        placeholder='Bei Tintenpatronen'
                                                        value={article.expireDate}
                                                        onChange={(e, {value}) => this.articles[index].expireDate = value}
                                                        width={4}/>

                                                </Form.Group>
                                                {   
                                                    this.articles[index].hint === 1 ? <p>Hinweis: Ein geöffneter oder fehlender Karton ist nicht schlimm. Wichtig ist nur, dass sich der <u>ursprüngliche Inhalt noch im Originalzustand befindet</u>.</p> : null
                                                }
                                                <Divider section/>
                                            </div>)}
                                        <Button icon color='grey' size='small' onClick={() => this.articles.push(DEFAULT_ARTICLE)}>
                                            <Icon name={'add'}/>
                                            Weitere Druckerpatrone hinzufügen
                                        </Button>
                                    </Form>
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row >
                            <Grid.Column>
                                <div style={{"padding-top": "15px"}} ref={this.requestError}>
                                    <h3>{t('request.your-data')}</h3>
                                    <Form error={this.fieldError}>
                                        <Form.Group widths={"equal"}>
                                            <Form.Input
                                                label={t('request.name')}
                                                value={this.userData.name}
                                                onChange={(e, {value}) => this.userData.name = value}
                                                error={this.userDataError.name}
                                                width={6}/>
                                            <Form.Input
                                                label={t('request.email')}
                                                value={this.userData.email}
                                                onChange={(e, {value}) => this.userData.email = value}
                                                error={this.userDataError.email}
                                                width={6}/>
                                        </Form.Group>
                                        <Message
                                            error
                                            header={t('request.form-error-title')}
                                            content={t('request.form-error-text')}/>
                                            <br />
                                            
                                        <Form.Button positive size='big' submit onClick={() => this.submit()} disabled={this.loading}>
                                            {t('request.send-request')} <Icon name='chevron right'/>
                                        </Form.Button>
                                    </Form>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        <div class="ui divider"></div>
                        <Grid.Row >
                            <Grid.Column computer={16} tablet={16} mobile={16}>
                            <br/>
                            <Image centered alt='Machen Sie Ihre Lagerbestände von vollen Druckerpatronen zu barem Geld!' src='images/mit-originalen-tonerpatronen-geld-verdienen.png' />
                            <h2>Volle Toner verkaufen: Machen Sie Ihre Lagerbestände von vollen Druckerpatronen zu barem Geld</h2>
                            <p>Durch die Fortschritte, die bei Druckern und Multifunktionsgeräten inzwischen gemacht wurden, steigen viele Betriebe wieder auf Altbewährtes um und nutzen wieder Geräte mit Tintenpatronen.<br/>
                            Denn Tintenstrahldrucker haben gegenüber schnellen, leistungsfähigen Laserdruckern gleich mehrere wesentliche Vorteile. Neben einem niedrigeren Energieverbrauch, sind es vor allem die fehlenden Gerüche und die Geräuschlosigkeit, die Tintenstrahldrucker wieder attraktiver machen. <b>Doch was soll mit den bereits angeschafften Lagerbeständen an vollen Tonern geschehen? Hier die gute Nachricht: Diese lassen sich leicht zu barem Geld machen!</b>  Verkaufen Sie einfach Ihre nicht mehr benötigten Bestände an Tonerkartuschen und schaffen Sie so Platz für Ihr neues Verbrauchsmaterial. Füllen Sie das <b onClick={() => this.requestForm.current.scrollIntoView()} className="pointer">obige Anfrageformular online aus</b> und erhalten Sie kurze Zeit später Ihr Ankaufsangebot per E-Mail.
                            </p>

                            <h3>Schnell und unkompliziert – volle Toner und auch Tintenpatronen verkaufen</h3>
                            <p>Sie haben sich entschieden, Ihre nicht mehr benötigten Tonerkartuschen und/oder Tintenpatronen zu verkaufen, die noch unbenutzt und voll sind? Nutzen Sie für Tonerkartuschen unseren <Link to="/volle-toner-verkaufen">Online-Produktkatalog</Link> – dort finden Sie die Artikel der gängigen Hersteller. Durch gezielte Fragen wird der Zustand Ihrer Patrone ermittelt, welche Sie anschließend in den Verkaufskorb legen können. Der Ankaufspreis wird Ihnen direkt online angezeigt.                            
                             Bei Bedarf stellen wir Ihnen bereits frankierte DHL Paketmarken für den innerdeutschen Versand zur Verfügung. Diese müssen Sie dann nur noch ausdrucken und auf Ihr Paket kleben. Alternativ können Sie die Artikel selbstverständlich auf eigene Kosten an uns senden. <br/>
                             Sobald die Ware bei uns eingegangen und geprüft worden ist, erfolgt die Auszahlung auf Ihr Konto automatisch nach wenigen Tagen. Natürlich ohne Abschläge, versteckte Kosten oder ähnliches.<br/>
                             Tipp: Sie müssen selbstverständlich nicht unseren Online-Produktkatalog nutzen, sondern können ganz traditionell eine Ankaufsanfrage stellen und erhalten ein Angebot per E-Mail. Das geht übrigens auch für übrig gebliebene Tintenpatronen! <b onClick={() => this.requestForm.current.scrollIntoView()} className="pointer">Das entsprechende Anfrageformular finden Sie hier</b>.
                            </p>
                            
                            <h3>Volle Druckerpatronen verkaufen – gut für die Umwelt</h3>
                            <p>Der Verkauf Ihrer nicht mehr benötigten Toner und Tintenpatronen hat gleich mehrere Vorteile. Sie erhalten dafür nicht nur einen fairen Ankaufspreis, sondern sparen sich auch die ansonsten anfallenden Entsorgungskosten. Da eine gesonderte Entsorgung mitunter teuer werden kann. Diese Kosten können Sie durch den Verkauf einsparen und haben so finanziell doppelt profitiert. Auch der Weg zum Recyclinghof entfällt dadurch. Gleichzeitig tragen Sie aktiv zum Umweltschutz bei, denn unbenutzte Druckerpatronen enthalten viele wertvolle Rohstoffe. Die verarbeiteten Metalle wie Kupfer, Gold und Platin würden ungenutzt im Müll landen. Durch einen Verkauf findet eine tatsächliche Wiederverwendung statt und die Materialien bleiben so im Warenkreislauf. Zudem wurde für die Produktion bereits Erdöl verwendet – auch diese Ressource wäre dann umsonst verbraucht worden.</p> 

                            <h3>Volle Toner verkaufen – dieTonerverwerter sind für Sie da!</h3>
                            <p>Haben Sie weitere Fragen rund um das Thema volle Tonerkartuschen zu verkaufen? Oder brauchen noch weitere Informationen zum Verkauf Ihrer Tintenpatronen? Unser Kundenservice steht Ihnen gerne mit Rat und Tat zur Seite. <a href="/impressum" title="Kontaktdaten der Sanocycling GmbH">Alle wichtigen Kontaktdaten finden Sie in unserem Impressum</a>.</p>

                            </Grid.Column>
                        </Grid.Row>
                    </Grid >
                </div>
            </div>
        );
    }
}