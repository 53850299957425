import React from 'react';
import MetaTags from "../components/MetaTags"

export default class Dataprivacy extends React.Component {


    render() {
        return (
            <div className="static-content">
                <MetaTags page="datenschutz" />
                <div className="static-content-paragraph">
                    <h1>Datenschutzerkl&auml;rung</h1>
                    <br />
                    <a href="javascript:window.cookieconsentControl()">Sie haben die volle Kontrolle, denn: Sie können Ihre <b>Cookie-Einstellungen hier jederzeit ändern.</b></a>
                    <br />
                    <br />
                    <span className="Haendlerbund_Rechtstext_Absatz">Soweit nachstehend keine anderen Angaben gemacht werden, ist die Bereitstellung Ihrer personenbezogenen Daten weder gesetzlich oder vertraglich vorgeschrieben, noch für einen Vertragsabschluss erforderlich. Sie sind zur Bereitstellung der Daten nicht verpflichtet. Eine Nichtbereitstellung hat keine Folgen. Dies gilt nur soweit bei den nachfolgenden Verarbeitungsvorgängen keine anderweitige Angabe gemacht wird.<br />
                    "Personenbezogene Daten" sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person beziehen.</span><br />
                    <br />
                    <div>
                    <strong><span className="Haendlerbund_Rechtstext_Absatz">Server-Logfiles</span></strong></div>
                    <div>
                    <span className="Haendlerbund_Rechtstext_Absatz">Sie können unsere Webseiten besuchen, ohne Angaben zu Ihrer Person zu machen.&nbsp;</span></div>
                    <div>
                    <span className="Haendlerbund_Rechtstext_Absatz">Bei jedem Zugriff auf unsere Website werden an uns oder unseren Webhoster / IT-Dienstleister Nutzungsdaten durch Ihren Internet Browser übermittelt und in Protokolldaten (sog. Server-Logfiles) gespeichert. Zu diesen gespeicherten Daten gehören z.B. der Name der aufgerufenen Seite, Datum und Uhrzeit des Abrufs, die IP-Adresse, die übertragene Datenmenge und der anfragende Provider. Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus unserem überwiegenden berechtigten Interesse an der Gewährleistung eines störungsfreien Betriebs unserer Website sowie zur Verbesserung unseres Angebotes.&nbsp;</span><br />
                    &nbsp;</div>
                    <span style={{fontSize: '20px'}}><em><strong>Kontakt</strong></em></span><br />
                    <br />
                    <span className="Haendlerbund_Rechtstext_Absatz"><strong>Verantwortlicher</strong></span><strong><span className="Haendlerbund_Rechtstext_Absatz" /></strong><br />
                    <span className="Haendlerbund_Rechtstext_Absatz">Kontaktieren Sie uns auf Wunsch. Die Kontaktdaten des für die Datenverarbeitung Verantwortlichen finden Sie in unserem Impressum.</span><br />
                    <br />
                    <p dir="ltr">
                    <span className="Haendlerbund_Rechtstext_Absatz"><strong>Initiativ-Kontaktaufnahme des Kunden per E-Mail</strong><br />
                        Wenn Sie per E-Mail initiativ mit uns in Geschäftskontakt treten, erheben wir Ihre personenbezogenen Daten (Name, E-Mail-Adresse, Nachrichtentext) nur in dem von Ihnen zur Verfügung gestellten Umfang. Die Datenverarbeitung dient der Bearbeitung und Beantwortung Ihrer Kontaktanfrage.<br />
                        Wenn die Kontaktaufnahme der Durchführung vorvertraglichen Maßnahmen (bspw. Beratung bei Kaufinteresse, Angebotserstellung) dient oder einen bereits zwischen Ihnen und uns geschlossenen Vertrag betrifft, erfolgt diese Datenverarbeitung auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO.<br />
                        Erfolgt die Kontaktaufnahme aus anderen Gründen erfolgt diese Datenverarbeitung auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus unserem überwiegenden berechtigten Interesse an der Bearbeitung und Beantwortung Ihrer Anfrage. <em><strong>In diesem Fall haben Sie das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit dieser auf Art. 6 Abs. 1 lit. f DSGVO beruhenden Verarbeitungen Sie betreffender personenbezogener Daten zu widersprechen.</strong></em><br />
                        Ihre E-Mail-Adresse nutzen wir nur zur Bearbeitung Ihrer Anfrage. Ihre Daten werden anschließend unter Beachtung gesetzlicher Aufbewahrungsfristen gelöscht, sofern Sie der weitergehenden Verarbeitung und Nutzung nicht zugestimmt haben.</span><br />
                    &nbsp;</p>
                    <div>
                    <br />
                    <span style={{fontSize: '20px'}}><strong><em>Cookies</em></strong></span></div>
                    <div>
                    &nbsp;</div>
                    <div>
                    <span className="Haendlerbund_Rechtstext_Absatz">Unsere Website verwendet Cookies. Cookies sind kleine Textdateien, die im Internetbrowser bzw. vom Internetbrowser auf dem Computersystem eines Nutzers gespeichert werden. Ruft ein Nutzer eine Website auf, so kann ein Cookie auf dem Betriebssystem des Nutzers gespeichert werden. Dieser Cookie enthält eine charakteristische Zeichenfolge, die eine eindeutige Identifizierung des Browsers beim erneuten Aufrufen der Website ermöglicht.</span></div>
                    <div>
                    &nbsp;</div>
                    <div>
                    <span className="Haendlerbund_Rechtstext_Absatz">Cookies werden auf Ihrem Rechner gespeichert. Daher haben Sie die volle Kontrolle über die Verwendung von Cookies. Durch die Auswahl entsprechender technischer Einstellungen in Ihrem Internetbrowser können Sie vor dem Setzen von Cookies benachrichtigt werden und über die Annahme einzeln entscheiden sowie die Speicherung der Cookies und Übermittlung der enthaltenen Daten verhindern. Bereits gespeicherte Cookies können jederzeit gelöscht werden. Wir weisen Sie jedoch darauf hin, dass Sie dann gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können.</span></div>
                    <div>
                    &nbsp;</div>
                    <div>
                    <span className="Haendlerbund_Rechtstext_Absatz">Unter den nachstehenden Links können Sie sich informieren, wie Sie die Cookies bei den wichtigsten Browsern verwalten (u.a. auch deaktivieren) können:</span></div>
                    <div>
                    <span className="Haendlerbund_Rechtstext_Absatz">Chrome: <a href="https://support.google.com/accounts/answer/61416?hl=de" target="_blank">https://support.google.com/accounts/answer/61416?hl=de</a><br />
                        Internet Explorer: <a href="https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank">https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies</a></span></div>
                    <div>
                    <span className="Haendlerbund_Rechtstext_Absatz">Mozilla Firefox: <a href="https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen" target="_blank">https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen</a></span></div>
                    <div>
                    <span className="Haendlerbund_Rechtstext_Absatz">Safari: <a href="https://support.apple.com/de-de/guide/safari/manage-cookies-and-website-data-sfri11471/mac" target="_blank">https://support.apple.com/de-de/guide/safari/manage-cookies-and-website-data-sfri11471/mac</a></span></div>
                    <div>
                    <br />
                    &nbsp;</div>
                    <div>
                    <strong><span className="Haendlerbund_Rechtstext_Absatz">Technisch notwendige Cookies</span></strong></div>
                    <div>
                    <span className="Haendlerbund_Rechtstext_Absatz">Soweit nachstehend in der Datenschutzerklärung keine anderen Angaben gemacht werden setzen wir nur diese technisch notwendigen Cookies zu dem Zweck ein, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Des Weiteren ermöglichen Cookies unseren Systemen, Ihren Browser auch nach einem Seitenwechsel zu erkennen und Ihnen Services anzubieten. Einige Funktionen unserer Internetseite können ohne den Einsatz von Cookies nicht angeboten werden. Für diese ist es erforderlich, dass der Browser auch nach einem Seitenwechsel wiedererkannt wird.</span></div>
                    <div>
                    &nbsp;</div>
                    <div>
                    <span className="Haendlerbund_Rechtstext_Absatz">Die Nutzung von Cookies oder vergleichbarer Technologien erfolgt auf Grundlage des § 15 Abs. 3 S. 1 TMG. Die Verarbeitung Ihrer personenbezogenen Daten erfolgt auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus unserem überwiegenden berechtigten Interesse&nbsp;an der Gewährleistung der optimalen Funktionalität der Website sowie einer nutzerfreundlichen und effektiven Gestaltung unseres Angebots.</span></div>
                    <div>
                    <span className="Haendlerbund_Rechtstext_Absatz"><em><strong>Sie haben das Recht aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit dieser Verarbeitungen Sie betreffender personenbezogener Daten zu widersprechen.</strong></em></span><br />
                    &nbsp;</div>
                    <br />
                    <span style={{fontSize: '20px'}}><em><strong>Analyse&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Werbetracking&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong></em></span><br />
                    <br />
                    <span className="Haendlerbund_Rechtstext_Absatz"><strong>Verwendung von Google Analytics</strong><br />
                    Wir verwenden auf unserer Website den Webanalysedienst Google Analytics der Google LLC. (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; „Google“). Soweit Sie Ihren gewöhnlichen Aufenthalt im Europäischen Wirtschaftsraum oder der Schweiz haben, ist Google Ireland Limited (Gordon House, Barrow Street, Dublin 4, Irland) der für Ihre Daten zuständige Verantwortliche. Google Ireland Limited ist demnach das mit Google verbundene Unternehmen, welches für die Verarbeitung Ihrer Daten und die Einhaltung der anwendbaren Datenschutzgesetze verantwortlich ist.<br />
                    Die Datenverarbeitung dient dem Zweck der Analyse dieser Website und ihrer Besucher sowie für Marketing- und Werbezwecke. Dazu wird Google im Auftrag des Betreibers dieser Website die gewonnenen Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere, mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Dabei können u.a. folgende Informationen erhoben werden: IP-Adresse, Datum und Uhrzeit des Seitenaufrufs, Klickpfad, Informationen über den von Ihnen verwendeten Browser und das von Ihnen verwendete Device (Gerät), besuchte Seiten, Referrer-URL (Webseite, über die Sie unsere Webseite aufgerufen haben), Standortdaten, Kaufaktivitäten. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.<br />
                    Google Analytics verwendet Technologien wie Cookies, Webspeicher im Browser und Zählpixel, die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die dadurch erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert.&nbsp;Für die USA ist kein Angemessenheitsbeschluss der EU-Kommission vorhanden. Die Datenübermittlung erfolgt u.a auf Grundlage von Standardvertragsklauseln&nbsp;als geeignete Garantien für den Schutz der personenbezogenen Daten, einsehbar unter: <a href="https://policies.google.com/privacy/frameworks" target="_blank">https://policies.google.com/privacy/frameworks</a>. Sowohl Google als auch staatliche US-Behörden haben Zugriff auf Ihre Daten. Ihre Daten können von Google mit anderen Daten, wie beispielsweise Ihrem Suchverlauf, Ihren persönlichen Accounts, Ihren Nutzungsdaten anderer Geräte und allen anderen Daten, die Google zu Ihnen vorliegen hat, verknüpft werden.<br />
                    Auf dieser Website ist die IP-Anonymisierung aktiviert. Dadurch wird Ihre IP-Adresse von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt.<br />
                    Die Nutzung von Cookies oder vergleichbarer Technologien erfolgt mit Ihrer Einwilligung auf Grundlage des § 15 Abs. 3 S. 1 TMG i.V.m. Art. 6 Abs. 1 lit. a DSGVO. Die Verarbeitung Ihrer personenbezogenen Daten erfolgt mit Ihrer Einwilligung auf Grundlage des Art. 6 Abs. 1 lit. a DSGVO. Sie können die Einwilligung jederzeit widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird.<br />
                    Nähere Informationen zu Nutzungsbedingungen und Datenschutz finden Sie unter <a href="https://www.google.com/analytics/terms/de.html" target="_blank">https://www.google.com/analytics/terms/de.html</a> bzw. unter <a href="https://www.google.de/intl/de/policies/" target="_blank">https://www.google.de/intl/de/policies/</a> sowie unter <a href="https://policies.google.com/technologies/cookies?hl=de" target="_blank">https://policies.google.com/technologies/cookies?hl=de</a>.</span><br />
                    <br />
                    <div>
                    <span className="Haendlerbund_Rechtstext_Absatz"><strong>Verwendung von Google Ads Conversion-Tracking</strong><br />
                        Wir verwenden auf unserer Website das Online-Werbeprogramm „Google Ads“ und in diesem Rahmen Conversion-Tracking (Besuchsaktionsauswertung). Das Google Conversion Tracking ist ein Analysedienst der Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; „Google“). Soweit Sie Ihren gewöhnlichen Aufenthalt im Europäischen Wirtschaftsraum oder der Schweiz haben, ist Google Ireland Limited (Gordon House, Barrow Street, Dublin 4, Irland) der für Ihre Daten zuständige Verantwortliche. Google Ireland Limited ist demnach das mit Google verbundene Unternehmen, welches für die Verarbeitung Ihrer Daten und die Einhaltung der anwendbaren Datenschutzgesetze verantwortlich ist.<br />
                        Wenn Sie auf eine von Google geschaltete Anzeige klicken, wird ein Cookie für das Conversion-Tracking auf Ihrem Rechner abgelegt. Diese Cookies haben eine begrenzte Gültigkeit, enthalten keine personenbezogenen Daten und dienen somit nicht der persönlichen Identifizierung. Wenn Sie bestimmte Seiten unserer Website besuchen und das Cookie noch nicht abgelaufen ist, können Google und wir erkennen, dass Sie auf die Anzeige geklickt haben und zu dieser Seite weitergeleitet wurden. Jeder Google Ads-Kunde erhält ein anderes Cookie. Somit besteht keine Möglichkeit, dass Cookies über die Websites von Ads-Kunden nachverfolgt werden können.<br />
                        Die Informationen, die mit Hilfe des Conversion-Cookie eingeholt werden, dienen dem Zweck Conversion-Statistiken zu erstellen. Hierbei erfahren wir die Gesamtanzahl der Nutzer, die auf eine unserer Anzeigen geklickt haben und zu einer mit einem Conversion-Tracking-Tag versehenen Seite weitergeleitet wurden. Wir erhalten jedoch keine Informationen, mit denen sich Nutzer persönlich identifizieren lassen.<br />
                        Ihre Daten werden gegebenenfalls in die USA übermittelt.&nbsp;Für die USA ist kein Angemessenheitsbeschluss der EU-Kommission vorhanden. Die Datenübermittlung erfolgt u.a auf Grundlage von Standardvertragsklauseln als geeignete Garantien für den Schutz der personenbezogenen Daten, einsehbar unter: <a href="https://policies.google.com/privacy/frameworks" target="_blank">https://policies.google.com/privacy/frameworks</a>.<br />
                        Die Nutzung von Cookies oder vergleichbarer Technologien erfolgt mit Ihrer Einwilligung auf Grundlage des § 15 Abs. 3 S. 1 TMG i.V.m. Art. 6 Abs. 1 lit. a DSGVO. Die Verarbeitung Ihrer personenbezogenen Daten erfolgt mit Ihrer Einwilligung auf Grundlage des Art. 6 Abs. 1 lit. a DSGVO. Sie können die Einwilligung jederzeit widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird.<br />
                        Nähere Informationen sowie die Datenschutzerklärung von Google finden Sie unter: <a href="https://www.google.de/policies/privacy/" target="_blank">https://www.google.de/policies/privacy/</a></span><br />
                    &nbsp;</div>
                    <div>
                    <span className="Haendlerbund_Rechtstext_Absatz"><strong>Verwendung der Remarketing- oder "Ähnliche Zielgruppen"-Funktion der Google Inc.</strong><br />
                        Wir verwenden auf unserer Website die Remarketing- oder "Ähnliche Zielgruppen"- Funktion der Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; „Google“). Soweit Sie Ihren gewöhnlichen Aufenthalt im Europäischen Wirtschaftsraum oder der Schweiz haben, ist Google Ireland Limited (Gordon House, Barrow Street, Dublin 4, Irland) der für Ihre Daten zuständige Verantwortliche. Google Ireland Limited ist demnach das mit Google verbundene Unternehmen, welches für die Verarbeitung Ihrer Daten und die Einhaltung der anwendbaren Datenschutzgesetze verantwortlich ist.<br />
                        Die Anwendung dient dem Zweck der Analyse des Besucherverhaltens und der Besucherinteressen. Zur Durchführung der Analyse der Websiten-Nutzung, welche die Grundlage für die Erstellung der interessenbezogenen Werbeanzeigen bildet, setzt Google Cookies ein. Über die Cookies werden die Besuche der Website sowie anonymisierte Daten über die Nutzung der Website erfasst. Es erfolgt keine Speicherung von personenbezogenen Daten der Besucher der Website. Besuchen Sie nachfolgend eine andere Website im Google Display-Netzwerk werden Ihnen Werbeeinblendungen angezeigt, die mit hoher Wahrscheinlichkeit zuvor aufgerufene Produkt- und Informationsbereiche berücksichtigen.<br />
                        Ihre Daten werden gegebenenfalls in die USA übermittelt.&nbsp;Für die USA ist kein Angemessenheitsbeschluss der EU-Kommission vorhanden. Die Datenübermittlung erfolgt u.a auf Grundlage von Standardvertragsklauseln als geeignete Garantien für den Schutz der personenbezogenen Daten, einsehbar unter: <a href="https://policies.google.com/privacy/frameworks" target="_blank">https://policies.google.com/privacy/frameworks</a>.<br />
                        Die Nutzung von Cookies oder vergleichbarer Technologien erfolgt mit Ihrer Einwilligung auf Grundlage des § 15 Abs. 3 S. 1 TMG i.V.m. Art. 6 Abs. 1 lit. a DSGVO. Die Verarbeitung Ihrer personenbezogenen Daten erfolgt mit Ihrer Einwilligung auf Grundlage des Art. 6 Abs. 1 lit. a DSGVO. Sie können die Einwilligung jederzeit widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird.<br />
                        Nähere Informationen zu Google Remarketing sowie die dazugehörige Datenschutzerklärung finden Sie unter: <a href="https://www.google.com/privacy/ads/" target="_blank">https://www.google.com/privacy/ads/</a></span><br />
                    &nbsp;</div>
                    <span className="Haendlerbund_Rechtstext_Absatz"><strong>Verwendung von Microsoft Advertising</strong><br />
                    Wir verwenden auf unserer Website Microsoft Advertising der Microsoft Corporation (Microsoft Corporation, One Microsoft Way, Redmond, WA 98052-6399, USA; “Microsoft“).<br />
                    Die Datenverarbeitung dient Marketing- und Werbezwecken und dem Zweck der Erfolgsmessung der Werbemaßnahmen (Conversion-Tracking). Wir erfahren die Gesamtanzahl der Nutzer, die auf eine unserer Anzeigen geklickt haben und zu einer mit einem Conversion-Tracking-Tag versehenen Seite weitergeleitet wurden. Eine persönliche Identifizierung dieser Nutzer ist dadurch aber nicht möglich. Microsoft Advertising verwendet Technologien wie Cookies und Zählpixel, die eine Analyse der Benutzung der Website durch Sie ermöglichen. Bei Klick auf eine von Microsoft Advertising geschaltete Anzeige, wird ein Cookie für das Conversion-Tracking auf Ihrem Rechner abgelegt. Dieses Cookie hat eine begrenzte Gültigkeit und dient nicht der persönlichen Identifizierung. Wenn Sie bestimmte Seiten unserer Website besuchen und das Cookie noch nicht abgelaufen ist, können Microsoft und wir erkennen, dass Sie auf die Anzeige geklickt haben und zu dieser Seite weitergeleitet wurden. Dabei können u.a. folgende Informationen erhoben werden: IP-Adresse, von Microsoft zugeordnete Identifikatoren (Kennzeichen), Informationen über den von Ihnen verwendeten Browser und zu dem von Ihnen verwendeten Device (Gerät), Referrer-URL (Webseite, über die Sie unsere Webseite aufgerufen haben), URL unserer Website.<br />
                    Ihre Daten werden gegebenenfalls in die USA übermittelt.&nbsp;Für die USA ist kein Angemessenheitsbeschluss der EU-Kommission vorhanden.<br />
                    Die Nutzung von Cookies oder vergleichbarer Technologien erfolgt mit Ihrer Einwilligung auf Grundlage des § 15 Abs. 3 S. 1 TMG i.V.m. Art. 6 Abs. 1 lit. a DSGVO. Die Verarbeitung Ihrer personenbezogenen Daten erfolgt mit Ihrer Einwilligung auf Grundlage des Art. 6 Abs. 1 lit. a DSGVO. Sie können die Einwilligung jederzeit widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird.<br />
                    Nähere Informationen zum Datenschutz und den eingesetzten Cookies bei Microsoft Bing finden Sie <a href="https://privacy.microsoft.com/de-de/privacystatement" target="_blank">hier</a>.</span><br />
                    <br />
                    <br />
                    <span style={{fontSize: '20px'}}><strong><em>Plug-ins und Sonstiges</em></strong></span><br />
                    <br />
                    <div>
                    <span className="Haendlerbund_Rechtstext_Absatz"><strong>Verwendung des Google Tag Managers</strong><br />
                        Wir verwenden auf unserer Website den Google Tag Manager der Google LLC. (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; „Google“). Soweit Sie Ihren gewöhnlichen Aufenthalt im Europäischen Wirtschaftsraum oder der Schweiz haben, ist Google Ireland Limited (Gordon House, Barrow Street, Dublin 4, Irland) der für Ihre Daten zuständige Verantwortliche. Google Ireland Limited ist demnach das mit Google verbundene Unternehmen, welches für die Verarbeitung Ihrer Daten und die Einhaltung der anwendbaren Datenschutzgesetze verantwortlich ist.<br />
                        Mit dieser Anwendung werden JavaScript-Tags und HTML-Tags verwaltet, die zur Implementierung insbesondere von Tracking- und Analyse-Tools verwendet werden. Die Datenverarbeitung dient dem Zweck der bedarfsgerechten Gestaltung und der Optimierung unserer Website.<br />
                        Der Google Tag Manager selbst speichert weder Cookies noch werden hierdurch personenbezogene Daten verarbeitet. Er ermöglicht jedoch die Auslösung weiterer Tags, die personenbezogene Daten erheben und verarbeiten können.<br />
                        Nähere Informationen zu Nutzungsbedingungen und Datenschutz finden Sie <a href="https://www.google.com/intl/de/tagmanager/use-policy.html" target="_blank">hier</a>.</span><br />
                    &nbsp;</div>
                    <br />
                    <span style={{fontSize: '20px'}}><strong><em>Betroffenenrechte und Speicherdauer</em></strong></span><br />
                    <br />
                    <strong><span className="Haendlerbund_Rechtstext_Absatz">Dauer der Speicherung</span></strong>
                    <div>
                    <span className="Haendlerbund_Rechtstext_Absatz">Die Daten werden unter Berücksichtigung gesetzlicher Aufbewahrungsfristen gespeichert und dann nach Fristablauf gelöscht, sofern Sie der weitergehenden Verarbeitung und Nutzung nicht zugestimmt haben.</span><br />
                    &nbsp;</div>
                    <span className="Haendlerbund_Rechtstext_Absatz"><strong>Rechte der betroffenen Person</strong><br />
                    Ihnen stehen bei Vorliegen der gesetzlichen Voraussetzungen folgende Rechte nach Art. 15 bis 20 DSGVO zu: Recht auf Auskunft, auf Berichtigung, auf Löschung, auf Einschränkung der Verarbeitung, auf Datenübertragbarkeit.<br />
                    Außerdem steht Ihnen nach Art. 21 Abs. 1 DSGVO ein Widerspruchsrecht gegen die Verarbeitungen zu, die auf Art. 6 Abs. 1 f DSGVO beruhen, sowie gegen die Verarbeitung zum Zwecke von Direktwerbung.</span><br />
                    <br />
                    <span className="Haendlerbund_Rechtstext_Absatz"><strong>Beschwerderecht bei der Aufsichtsbehörde</strong><br />
                    Sie haben gemäß Art. 77 DSGVO das Recht, sich bei der Aufsichtsbehörde zu beschweren, wenn Sie der Ansicht sind, dass die Verarbeitung Ihrer personenbezogenen Daten nicht rechtmäßig erfolgt.</span><br />
                    <br />
                    <span className="Haendlerbund_Rechtstext_Absatz"><strong>Widerspruchsrecht</strong><br />
                    Beruhen die hier aufgeführten personenbezogenen Datenverarbeitungen auf Grundlage unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f DSGVO, haben Sie das Recht aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit diesen Verarbeitungen mit Wirkung für die Zukunft zu widersprechen.<br />
                    Nach erfolgtem Widerspruch wird die Verarbeitung der betroffenen Daten beendet, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihren Interessen, Rechten und Freiheiten überwiegen, oder wenn die Verarbeitung der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen dient.</span><br />
                    <br />
                    <span className="Haendlerbund_Rechtstext_Absatz">letzte Aktualisierung: 27.10.2020</span><br />
                    <br />

                </div>
            </div>
        );
    }
}