import React from 'react';
import MetaTags from "../components/MetaTags"

export default class Terms extends React.Component {

    render() {

        return (
            <div class="static-content">
                <MetaTags page="ankaufsbedingungen" />
                <div class="static-content-paragraph">
                    <h1>Allgemeine Geschäftsbedingungen für den Ankauf von Waren</h1>
                    <h2>1. GELTUNGSBEREICH</h2>
                    1.1. Diese Geschäftsbedingungen der Sanocycling GmbH (nachfolgend „Ankäufer“), gelten für alle Verträge
                    über den Ankauf von neuen und/oder gebrauchten Waren, die ein Verbraucher oder Unternehmer
                    (nachfolgend „Verkäufer“) mit der Sanocycling GmbH abschließt. Hiermit wird der Einbeziehung von eigenen
                    Bedingungen des Verkäufers widersprochen, es sei denn, es wurde etwas anderes vereinbart.<br /><br />

                    1.2. Ein Verbraucher im Sinne dieser Geschäftsbedingungen ist jede natürliche Person, die ein
                    Rechtsgeschäft zu einem Zweck abschließt, der weder ihrer gewerblichen noch ihrer selbstständigen
                    beruflichen Tätigkeit zugerechnet werden kann. Ein Unternehmer im Sinne dieser Geschäftsbedingungen ist
                    eine natürliche oder juristische Person oder eine rechtsfähige Personengesellschaft, die bei Abschluss eines
                    Rechtsgeschäfts in Ausübung ihrer gewerblichen oder selbständigen beruflichen Tätigkeit handelt.

                    <h2>2. ZUSENDUNG</h2>
                    Sendungen an die Sanocycling GmbH gehen grundsätzlich auf Kosten und auf Risiko des Verkäufers
                    (Bringschuld des Verkäufers, Incoterm DDP). Dies gilt auch, wenn die Sanocycling GmbH die Versandkosten übernimmt, den
                    Transport für die Sendungen beauftragt oder DHL Paketmarken für den Versand zur Verfügung stellt.
                    Letzteres dient ausschließlich dazu, den Versandablauf für den Verkäufer zu vereinfachen. Die Kosten für
                    jede benutze Paketmarke trägt der Verkäufer und werden vom Verkaufserlös in Abzug gebracht. Der
                    Verkäufer muss jedem Paket der Sendung den vollständig und wahrheitsgemäß ausgefüllten Begleitzettel
                    beilegen. Mit Versand der Ware bestätigt der Verkäufer, dass er über die Berechtigung zum Verkauf verfügt
                    und der Sanocycling GmbH das Eigentum an der verkauften Sache verschaffen kann. Der Verkäufer bestätigt,
                    dass die Ware frei von jeglichen Rechten Dritter ist.

                    <h2>3. ANKAUFSPREISE</h2>
                    Das Preisangebot erfolgt durch die Sanocycling GmbH über die Internetplattform dieTonerverwerter.de oder
                    auf Anfrage per E-Mail. Der angegebene Kaufpreis bezieht sich ausschließlich auf Waren, die ursprünglich
                    vom Originalhersteller (OEM) im Europäischen Wirtschaftsraum (EWR) in Verkehr gebracht wurden und beinhaltet die gesetzliche Mehrwertsteuer. Mit
                    Übermittlung der Verkaufsdaten über die Internetplattform wird ein Begleitzettel generiert. Dieser Begleitzettel
                    dient als Preisangebot und ist entsprechend der angegebenen Menge und des angegebenen Zustandes
                    längstens bis zum angegebenen Datum gültig und jedem Paket beizulegen. Sollte kein Datum angegeben sein, beträgt die Gültikeit 14 Tage. Entscheidend für die Gültigkeit ist
                    der Tag des tatsächlichen Wareneingangs bei der Sanocycling GmbH. Empfangene Sendungen werden von
                    der Sanocycling GmbH bewertet. Dazu wird die Ware auf Herkunft sowie Echtheit überprüft und der Zustand
                    festgestellt. Stimmt die Ware mit dem Preisangebot überein, bestätigt die Sanocycling GmbH den
                    ordnungsgemäßen Eingang der Ware. Mit dieser Bestätigung wird das Geschäft rechtsverbindlich. Entspricht
                    die gelieferte Ware dem Preisangebot, behält sich die Sanocycling GmbH das Recht vor, die Ware auf eigene
                    Gefahr und Kosten zurückzusenden und übernimmt auf Nachweis die tatsächlichen Versandkosten des Verkäufers.

                    <h2>4. MÄNGEL</h2>
                    Der Verkäufer haftet für Sach- oder Rechtsmängel an der gelieferten Ware nach den gesetzlichen
                    Bestimmungen. Sollte Zustand oder Art der zugesandten Ware vom ursprünglichen Preisangebot abweichen,
                    wird die Sanocycling GmbH den Verkäufer informieren und gegebenenfalls ein neues Preisangebot auf
                    Grundlage der eingesandten Ware per E-Mail oder Telefon unterbreiten. Falls der Verkäufer das wünscht,
                    kann er binnen 5 Werktagen nach Übermittlung der Mängelmitteilung die bemängelte Ware auf eigenen
                    Kosten bei der Sanocycling GmbH abholen lassen. Bereits angefallene Transportkosten (z.B. DHL
                    Paketmarke) können auch bei teilweiser Rücksendung oder Abholung nicht erstattet werden. Bei der
                    Rücksendung der Ware an den Verkäufer geht die Gefahr des zufälligen Untergangs oder einer zufälligen
                    Verschlechterung auf den Verkäufer über, sobald die Sanocycling GmbH die Ware an den zur Ausführung der
                    Versendung bestimmten Paketdienst übergeben hat. Mehrmengen, abgelaufene Preisangebote oder nicht
                    angefragte Waren werden zum Tag der Bewertung mit dem aktuell gültigen Tagespreis der Sanocycling
                    GmbH ohne weitere Rücksprache vergütet.

                    <h2>5. ZAHLUNG</h2>
                    Die Sanocycling GmbH verpflichtet sich, im Falle des Vertragsschlusses und der Übergabe bzw. Lieferung der
                    Ware den vereinbarten Kaufpreis an den Verkäufer zu bezahlen. Sofern nichts anderes vereinbart ist, erfolgt
                    die Zahlung des Kaufpreises unverzüglich nach Übergabe/Lieferung und Bewertung der Ware durch
                    Überweisung auf das vom Verkäufer angegebene Bankkonto.

                    <h2>6. ABSCHLUSSBEDINGUNGEN</h2>
                    6.1 Für sämtliche Rechtsbeziehungen der Parteien gilt das Recht der Bundesrepublik Deutschland unter
                    Ausschluss der Gesetze über den internationalen Kauf beweglicher Waren. Bei Verbrauchern gilt diese
                    Rechtswahl nur insoweit, als nicht der gewährte Schutz durch zwingende Bestimmungen des Rechts des
                    Staates, in dem der Verbraucher seinen gewöhnlichen Aufenthalt hat, entzogen wird.<br /><br />

                    6.2 Ist der Vertragspartner ein Kaufmann, eine juristische Person des öffentlichen Rechts oder ein öffentlichrechtliches
                    Sondervermögen, wird Schwabach als ausschließlicher Gerichtsstand für alle Ansprüche, die sich
                    aus oder aufgrund dieses Vertrages ergeben, vereinbart. Gleiches gilt gegenüber Personen, die keinen
                    allgemeinen Gerichtsstand in Deutschland haben oder Personen, die nach Abschluss des Vertrages ihren
                    Wohnsitz oder gewöhnlichen Aufenthaltsort außerhalb von Deutschland verlegt haben oder deren Wohnsitz
                    oder gewöhnlicher Aufenthaltsort im Zeitpunkt der Klageerhebung nicht bekannt ist. Anwendbar ist
                    ausschließlich deutsches Recht.<br /><br />

                    6.3 Sollten einzelne Regelungen dieser Geschäftsbedingungen ungültig sein oder werden, so wird die
                    Wirksamkeit der übrigen Regelungen hierdurch nicht berührt. Anstelle der unwirksamen Regelung tritt eine
                    Ersatzregelung die dem mit der unwirksamen Regelung angestrebten Zweck möglichst nahe kommt. An allen
                    aufgeführten Markennamen und Zubehörbezeichnungen bestehen Schutzrechte der einzelnen Hersteller. Sie
                    dienen ausschließlich zu Anschauungszwecken.<br /><br /><br />

                    Letzte Aktualisierung: 16.12.2022
                </div>
            </div>

        );
    }
}