import React from 'react';
import {withNamespaces} from 'react-i18next';
import MetaTags from '../components/MetaTags'
import {Link} from 'react-router-dom'
import {Icon, Grid} from "semantic-ui-react"

@withNamespaces()
export default class LpRatings extends React.Component {
    
    componentDidMount() {
        const script = document.createElement("script");
        script.async = false;
        script.src = "https://www.provenexpert.com/widget/landing_sanocycling-gmbh.js?feedback=1&avatar=0&competence=0&style=black";
        
    
        //For head
        //document.head.appendChild(script);
    
        // For body
        document.body.appendChild(script);
    
        // For component
        //this.div.appendChild(script);
      }


    render() {
        
        const {t} = this.props;
        return (
            <div class="landingpage">
                <MetaTags page="bewertungen" />
                <h1>Bewertungen spielen beim Verkauf von vollen Druckerpatronen eine entscheidende Rolle</h1>

                <p><i>Ein bekanntes Sprichwort lautet: "Es ist nicht alles Gold, was glänzt".<br />
                Manchmal trügt der äußere Schein und etwas wird nicht gehalten, wie oder was versprochen wurde.</i></p>

                <p>Vor allem bei einem Verkauf von vollen Druckerpatronen wird ein Fehler und Mangel bei der Entscheidung für einen Anbieters oft erst im Nachhinein entdeckt.<br /> 
                Wir können und wollen nicht beurteilen wie andere Unternehmen arbeiten und ob sie versprechen wie <b>"Wir zahlen Bestpreise für Ihre vollen Toner"</b> oder reißerische Aufmachungen wie <b>"Höchstpreise für unbenutzte Druckerpatronen"</b> immer halten oder nach Erhalt der Ware beispielsweise einfach den Zustand willkürlich verschlechtern und somit weniger Geld als erwartet ausbezahlen.</p>
               
                <br />

                <h2>Wir sind mit Sicherheit nicht unfehlbar, tun aber unser Bestes, um Ihnen eine Top Verkaufserfahrung zu bieten</h2>
                <br />
                <Link to="/hersteller-waehlen" class='white button primary'>
                    <center>
                        Jetzt Toner verkaufen <Icon name='chevron right'/>
                    </center>
                 </Link>
                <br />
                <br />
                <p>Wie sich das Feedback über uns - der Sanocycling GmbH aus Hilpoltstein - genau äußert und welche Erfahrungen unsere Kunden bei dieTonerverwerter gemacht haben, können Sie auf unserer externen Bewertungsplattform lesen.<br />
                Lesen Sie selbst nach, ob andere Kunden tatsächlich super Erfahrungen mit uns gemacht haben und teilen Sie sehr gerne Ihre eigenen mit!</p>
                <br />
                <div id="pewl"></div>
                <br />
                <br />
                <p>Selbstverständlich müssen Sie nicht nur unseren Bewertungen auf dieTonerverwerter Glauben schenken, sondern auch selbst im Internet recherchieren.<br />
                Achten Sie bei Ihrer Meinungsbildung aber bitte darauf, dass sich die Kundenmeinungen und Bewertungen tatsächlich auf dieTonerverwerter (Sanocycling GmbH) beziehen
                und es dabei nicht um ein anderes Unternehmen geht.</p>
            </div>
        )
    }
}    
