import React from 'react';
import MetaTags from "../components/MetaTags"
import {Link} from 'react-router-dom';
import {Icon, Image} from "semantic-ui-react"

export default class Service extends React.Component {

    render() {
        return (
            <div class="service">
                <MetaTags page="service" />
                <div class="serviceparagraph">
                    <h1>Hilfe! Wie finde ich den Verkaufspreis für meine vollen Druckerpatronen?</h1>
                    <p>Keine Sorge, wir helfen Ihnen gerne weiter. Zuerst müssen wir aber den Begriff "Druckerpatronen" genau klären,<br />
                    denn unter Druckerpatronen werden sowohl Tonerkartuschen, als auch Tintenpatronen verstanden.</p>
                    <br />

                    <h2>Volle Tonerkartuschen verkaufen:</h2>
                    <p>Tonerkartuschen besitzen Sie, wenn Sie einen Laserdrucker im Einsatz haben (bzw. hatten). Gefüllt sind solche Kartuschen mit Tonerpulver.<br />
                    Die Verkaufspreise für die gängigsten vollen Toner finden Sie direkt online in unserem <a href="/hersteller-waehlen" title="Suchen Sie den Verkaufspreis für Ihre Toner über unseren Produktkatalog">Produktkatalog von dieTonerverwerter</a>.<br />
                    Falls Ihr Modell dort nicht aufgelistet ist, nutzen Sie bitte unser Anfrageformular:</p>

                    <p><a href="/download/Anfrageformular.pdf" title="Anfrageformular für volle Original-Tonerkartuschen">
                        <b>Anfrageformular für volle Original-Tonerkartuschen herunterladen</b><br />
                        <Image src="/images/anfrageformular.png"  alt="Anfrageformular für volle Original-Tonerkartuschen" width="106" height="150" />
                    </a></p>
                    <p>Tragen Sie in diesem Formular bitte Ihre Artikel im jeweiligen Zustand ein und senden Sie das Formular (gerne zusammen mit Bildern zum Artikelzustand) an <a href="mailto:info@sanocycling.de">info@sanocycling.de</a></p>
                    
                    <p>Welche verschiedenen Zustände es bei Tonerkartuschen und Trommeleinheiten der verschiedenen Hersteller gibt,<br />
                    haben wir <a href="/voll-dabei/einfach-den-zustand-von-tonerkartuschen-verpackungen-erkennen-und-verkaufen" title="Blogbeitrag Zustand von Tonerkartuschen-Verpackungen erkennen">in unserem Blogbeitrag "Einfach den Zustand von Tonerkartuschen-Verpackungen erkennen und verkaufen"</a> anschaulich für Sie erklärt.</p>
                    <br />
                    <h2>Tintenpatronen verkaufen:</h2>
                    <p>Tintenpatronen haben ihren Einsatz in sognannten Tintenstrahldruckern. Anders als bei Tonerkartuschen, sind diese Patronen nicht mit Pulver, sondern mit flüssiger Tinte gefüllt.<br />
                    Dieses entscheidende Merkmal macht den Ankauf von noch vollen Patronen ein bisschen aufwendiger. So fragen wir hier neben der Verpackungsart beispielsweise das Mindesthaltbarkeitsdatum ab.<br />
                    <b>Aber keine Angst, dennoch ist der Verkauf an dieTonerverwerter möglich und mit ein paar Tipps kinderleicht!</b><br />
                    <br />
                    <a href="/tintenpatronen-verkaufen" title="Sonderseite für den Verkauf von vollen Tintenpatronen">
                     Alle Tipps und Hinweise zum Verkauf von Tintenpatronen an dieTonerverwerter haben wir für Sie auf einer Sonderseite zusammengefasst:
                    </a><br />
                    </p>
                
                    <a href="/tintenpatronen-verkaufen" class='call-to-action' title="Sonderseite für den Verkauf von vollen Tintenpatronen">
                       Sonderseite für den Verkauf von vollen Tintenpatronen <Icon name='chevron right'/>
                    </a>
                    <br />
                    <br />
                    <br />
                    <h2 id="gefunden">Ankaufspreis(e) gefunden. Wie geht es weiter?</h2>
           
                    <h3>Möglichkeit 1: Ich habe alle Ankaufspreise online gefunden</h3>
                    <p>Im bestem Fall haben Sie all Ihre <a href="/hersteller-waehlen" title="Suchen Sie den Verkaufspreis für Ihre Toner über unseren Produktkatalog">Ankaufspreise für Tonerkartuschen in unserem Produktkatalog</a> gefunden und in den Verkaufskorb gelegt.<br />
                    Sie haben nun die <a href="/verkaufen" title="Verkaufsdaten übermitteln und Begleitzettel generieren">Möglichkeit zum "Checkout" zu gehen</a> und uns Ihre Verkaufsdaten online zu übermitteln. Nach Eingabe Ihrer Daten generiert unser System zur eindeutigen Identifikation einen Begleitzettel.<br />
                    Diesen legen Sie bitte jedem Paket Ihrer Sendung bei. Jetzt müssen Sie die Tonerkartuschen nur noch in einem passenden Umkarton an uns versenden.<br />
                    <br />
                    Wie das am besten geht und was es zu beachten gibt, können Sie unserem <a href="/voll-dabei/das-ist-beim-versand-von-vollen-tonerkartuschen-zu-beachten" title="Blogbeitrag rund um das Thema Versand von vollen Tonerkartuschen">Blogbeitrag "Das ist beim Versand von vollen Tonerkartuschen an dieTonerverwerter zu beachten"</a> nachlesen.</p>

                    <h3>Möglichkeit 2: Ich habe ein Angebot per E-Mail erhalten</h3>
            	    <p>Sie möchten uns entweder Tintenpatronen verkaufen, oder wir haben die Ankaufspreise für Ihre Tonerkartuschen (noch) nicht in unserem Produktatalog aufgenommen.<br />
                    In diesem Fall erhalten Sie auf Ihre Anfrage von uns ein schriftliches Angebot per E-Mail.<br />
                    <br />
                    Zusammen mit dem personalisierten Angebot übersenden wir Ihnen einen <a href="/download/Lieferschein.pdf" title="Lieferschein zu Ihrer Sendung">Lieferschein zu Ihrer Sendung</a>.<br />
                    Da wir in diesem Fall Ihre Daten nicht direkt online abfragen, ist es unbedingt notwendig, dass Sie das ausgefüllte Formular zusammen mit Ihrem erhaltenen Angebot jedem Paket Ihrer Sendung beilegen.
                    <br />
                    Für den einfachen Versand, senden wir Ihnen unseren praktischen <a href="/download/Versandschein.pdf" title="Versandschein für Ihr Paket">Versandschein</a> mit. Einfach aufkleben, ausreichend frankieren und an uns senden.
                    </p>

                    <br />
                    <h2>Ich benötige weitere Hilfe:</h2>
                    <p>Sie sind noch nicht wunschlos glücklich und haben weitere Fragen? Kein Problem. Wir sind für Sie da. <a href="/impressum" title="Kontaktdaten der Sanocycling GmbH">Alle wichtigen Kontaktdaten finden Sie in unserem Impressum</a>.</p>
                </div>
            </div>
        );
    }
}