import React from 'react';

//Matijas suggestion with Patricks modifications:
import {withNamespaces} from 'react-i18next'
import {Link} from 'react-router-dom';

@withNamespaces()
export default class TextHelper extends React.Component {

    constructor() {
        super()
    }

    //result-headline
    cashResultTrash(t){
        return(
            <div>
                <h1 className="result-headline">{t('cashResultTrash.resultHeadline')}</h1>
                <div className="result-trash">
                    {this.tonerentsorger(t)}
                </div>
            </div>
        )
    }

    tonerentsorger(t){
        return (
            <div className="tonerentsorger">
                <p className="question">{t('tonerentsorger.question')}</p>
                <p className="answer">{t('tonerentsorger.answer')}</p>
                {<ExternalLink href={t('tonerentsorger.href')} title={t('tonerentsorger.title')} value={t('tonerentsorger.value')}/>}
            </div>
        )      
    }

    defineStateDirect(t, add){

        return(
            <span className="fira small block">
                {t('defineState.directPart1', add)}&nbsp;
                <Link to={t('defineState.linkClosedPrice.to', add)} title={t('defineState.linkClosedPrice.title', add)} class="small">
                    {t('defineState.linkClosedPrice.value')}
                </Link>&nbsp;
                {t('defineState.directPart2')}&nbsp;
                <Link to={t('defineState.linkOpenPrice.to', add)} title={t('defineState.linkOpenPrice.title', add)} class="small">
                    {t('defineState.linkOpenPrice.value')}
                </Link>
            </span>
        )
    }

    success(t, add){
        let linkSafeShippment = <Link to={t('successPage.linkSafeShippment.to')} title={t('successPage.linkSafeShippment.title')}>
                                    {t('successPage.linkSafeShippment.value')}
                                </Link>
        return(
            <div>
                <h1 class="success-headline">
                   {t("successPage.thanksForSelling")}
                </h1>
                <p className="success-description">
                    {t("successPage.pleasePrint")}
                </p>
                <p className="success-description">
                    {t("successPage.confirmationSend", {email: add.email})}
                </p>
                <p className="success-description">
                    {t("successPage.takeAttention")} {linkSafeShippment} 
                </p>
                
                <p className="success-description">
                    {t("successPage.repsonse")}
                </p>
            </div>
        )       
    }


    render() {
        const {t, f, add} = this.props; //i18n

        //Unfortunately, the  passed parameter cannot be used directly as a function
        switch(f) {
            case 'cashResultTrash':
                return this.cashResultTrash(t)
            case 'tonerentsorger':
                return this.tonerentsorger(t)
            case 'defineStateDirect':
                return this.defineStateDirect(t, add)
            case 'success':
                return this.success(t, add)
            default:
                return -1; //better: null
        }
    }

}

function ExternalLink(props) {
    return (
        <a href={props.href} target={props.target ? props.target : '_blank'} title={props.title}>{props.value}</a>
    )
}



//Patricks idea:
/*export function TextCashResultTrash(props){
    return (
    <div>
        {props.t('cashResultTrash.resultHeadline')}<br />
        <br />
        <RecTonerentsorger t={props.t} />
    </div>
    )
}

export function RecTonerentsorger(props){
    return (
        <div className="tonerentsorger">
            <p className="question">{props.t('tonerentsorger.question')}</p>
            <p className="answer">{props.t('tonerentsorger.answer')}</p>
            <ExternalLink href={props.t('tonerentsorger.href')} title={props.t('tonerentsorger.title')} value={props.t('tonerentsorger.value')}/>
        </div>
    )
}

export function ExternalLink(props) {
    return (
        <a href={props.href} target={props.target ? props.target : '_blank'} title={props.title}>{props.value}</a>
    )
}*/