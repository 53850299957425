import React from 'react';
import {Grid} from "semantic-ui-react"

import history from "../helper/browserHistory";
import {withNamespaces} from 'react-i18next';
import MetaTags from "../components/MetaTags"

@withNamespaces()
export default class ErrorPageEmptyShoppingCart extends React.Component {


    render() {
        const {t} = this.props;

        return (
            <Grid stackable class='main-grid'>
                <MetaTags page="verkaufskorb-leer" />
                <Grid.Row>
                    <Grid.Column className="main-grid">
                        <h1 className="fira big">
                            {t('errorPage.emptyShoppingCart-headline')}
                        </h1>
                        <a href="/hersteller-waehlen" className="fira small block">
                            {t('errorPage.emptyShoppingCart-subheadline')}
                        </a>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <span className='step-back-link' onClick={() => history.replace('/')}>
                        {t('goToHomePage')}
                    </span>
                </Grid.Row>
            </Grid>
        );
    }
}