import React from 'react';
import {Grid, Input, List} from "semantic-ui-react"
import PropTypes from "prop-types";
import history from "../helper/browserHistory";
import {withNamespaces} from 'react-i18next';

//20190710 test
import {Link} from 'react-router-dom';

//BOF Breacking Chnage in reason ov V2.0.0 https://github.com/Semantic-Org/Semantic-UI-React/releases && https://react.semantic-ui.com/migration-guide
import AppMedia from "../components/AppMedia";
const mediaStyles = AppMedia.createMediaStyle();
const { Media, MediaContextProvider } = AppMedia;
//EOF

@withNamespaces()
export default class PrinterCartridgeTypeList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searchTerm: ""
        };

       this.handleSearchInputChange = this.handleSearchInputChange.bind(this);
    }

    componentWillMount() {
        this.setState({
            searchTerm: ""
        })
    }

    handleSearchInputChange(event) {
        this.setState({
            searchTerm: event.target.value
        })
    }

    render() {
        const {data, onClickItem, showBackLink, textForNull, t, aHrefItem, searchAvailable, notFound} = this.props;       

        let itemElements = data.map((item,index) => (
            /*<List.Item key={item} onClick={() => onClickItem(item)} class='printer-cartridge-list-item'>
                {item}
            </List.Item>*/
            //need to set a link for (google) sitemap!

            //<List.Item key={item} class='printer-cartridge-list-item' as={Link} to={aHrefItem(item, index)} onClick={() => onClickItem(item, index)}>
            <Link to={aHrefItem(item, index)}>
                <List.Item key={item} class='printer-cartridge-list-item'>
                    {item}
                </List.Item>
            </Link>
        ));

        if(this.state.searchTerm && this.state.searchTerm !== "") {
            const filteredData = data.filter(item => {
                return item.toLowerCase().indexOf(this.state.searchTerm.toLowerCase()) > -1
            });

            itemElements = filteredData.map((item, index) => (
                /*<List.Item key={item} onClick={() => onClickItem(item)} class='printer-cartridge-list-item'>
                    {item}
                </List.Item>*/
                //need to set a link for (google) sitemap!
                <Link to={aHrefItem(item, index)}>
                    <List.Item key={item} class='printer-cartridge-list-item'>
                        {item}
                    </List.Item>
                </Link>
            ));
        }


        //const notMobile = { minWidth: Responsive.onlyMobile.maxWidth + 1 };

        return (
            <Grid class='printer-cartridge-list-grid'>
                <style>{mediaStyles}</style>
                <MediaContextProvider>
                { 
                    searchAvailable === undefined || searchAvailable === 0 ?
                        <Grid.Row>
                            <div class="printer-cartridge-search-wrapper">
                                {/*<Responsive {...Responsive.onlyMobile} >*/}
                                <Media at="mobile">
                                    <Input
                                        icon='search'
                                        placeholder={t("search.placeholderInput")}
                                        value={this.state.searchTerm}
                                        onChange={this.handleSearchInputChange}
                                        fluid
                                    />
                                 </Media>
                                 {/*20190802 only autofocus on not mobile*/}
                                 {/*<Responsive {...notMobile} >*/}
                                 <Media greaterThanOrEqual="tablet">
                                    <Input
                                        icon='search'
                                        placeholder={t("search.placeholderInput")}
                                        value={this.state.searchTerm}
                                        onChange={this.handleSearchInputChange}
                                        fluid
                                        autoFocus
                                    />
                                 </Media>                                 
                            </div>
                        </Grid.Row>
 
                    :
                    null
                }
                <Grid.Row>
                    <div style={{'width':'100%'}}>
                        <div className='printer-cartridge-list-logo'/>
                        <div className='printer-cartridge-list-wrapper'>
                           <List class='printer-cartridge-list'>
                                {
                                itemElements.length > 0 ?
                                    itemElements
                                :
                                    this.state.searchTerm !== "" ?
                                      <p class="printer-cartridge-search-noResult">{t("search.noResult")}</p>
                                    :
                                        <p class="printer-cartridge-search-loading"> {t("search.loading")}</p>
                                }
                                {
                                notFound ?
                                    <Link to={notFound.link}>
                                        <List.Item key="other" class='printer-cartridge-list-item'>
                                            <b>{notFound.text}</b>
                                        </List.Item>
                                    </Link>
                                :
                                    null
                                }
                            </List>
                        </div>
                    </div>
                </Grid.Row>
                {/*
                    textForNull !== '' ? <Grid.Row>
                            <span className='step-back-link' onClick={() => onClickItem(null)}>
                             {textForNull}
                            </span>
                        </Grid.Row>
                        :
                        null
                */}
                {
                    showBackLink ? <Grid.Row>
                            <span className='step-back-link' onClick={() => history.goBack()}>
                             {t('stepBack')}
                            </span>
                        </Grid.Row>
                        :
                        null
                }
                </MediaContextProvider>
            </Grid>

        );
    }
}

PrinterCartridgeTypeList.propTypes = {
    data: PropTypes.array,
    onClickItem: PropTypes.func,
    showBackLink: PropTypes.bool,
    textForNull: PropTypes.string
};

PrinterCartridgeTypeList.defaultProps = {
    data: [],
    onClickItem: () => {
    },
    showBackLink: true,
    textForNull: ''
};