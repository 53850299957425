import React from 'react';
import { withNamespaces } from 'react-i18next';
import {observer} from 'mobx-react';
import {Search} from "semantic-ui-react";
import {debounce, getTokenFromLocalStorage} from "../helper/util";
import config from "../../config/main.config";
import history from "../helper/browserHistory";
import {Link} from "react-router-dom";
import uiStore from "../stores/uiStore";

/*const suggestionRenderer = ({ title, linkurl, printercartridge }) => <div onClick={() => {
    uiStore.showBurger = !uiStore.showBurger;
    if(typeof window !== "undefined") {
        localStorage.setItem("searchItemNumber", printercartridge.itemNumber);
        window.dispatchEvent(new Event("changeItemNumber"));
    }
    history.push(linkurl)
}}>
    {title}
</div>;*/

@withNamespaces()
@observer
export default class SearchArticleNumber extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            valueToSearch: "",
            suggestions: []
        };

        this.handleSearchChange = this.handleSearchChange.bind(this);
    }

    handleSearchChange(e, { value }) {
        this.setState({ ...this.state, isLoading: true, valueToSearch: value });

        //Send token if logged in to get also ink results
        const userToken = getTokenFromLocalStorage();
        let headers = config.HEADERS;
        if(userToken != null){
            headers.token =  userToken
        }

        setTimeout(() => {
            fetch(config.BASE_URL + "printerCartridges/search?searchTerm=" + encodeURI(this.state.valueToSearch), {
                method: 'GET',
                headers: headers
            }).then((response) => {
                if (response.status >= 200 && response.status < 400) {
                    response.json().then(json => {
                        if (json.length === 0) {
                            this.setState({ ...this.state, isLoading: false, suggestions:[] });

                        } else {
                            const preparedSuggestions = json.map(printerCartridge => {
                                return {
                                    key: printerCartridge.itemNumber,
                                    as: Link,
                                    to: "/zustand-definieren/" + printerCartridge.itemNumber,
                                    title: printerCartridge.manufacturer + " " + printerCartridge.originalItemNumber
                                }
                            });
                            //reset before inser suggestions
                            this.setState({ ...this.state, isLoading: false, suggestions:[] });
                            this.setState({ ...this.state, isLoading: false, suggestions: preparedSuggestions });
                        }

                    });

                } else {
                    this.setState({ ...this.state, isLoading: false, suggestions:[] });
                }

            }).catch(error => {
                this.setState({ ...this.state, isLoading: false, suggestions:[] });
            });

            /*fetch value and set results*/
        }, 500)
    }

    //vgl.https://codesandbox.io/s/semantic-ui-react-issues-3697-b2y66
    render() {
        let {t} = this.props
        return (
            <React.Fragment>
                <label for="globalSearch" style={{position: 'absolute', color: 'transparent'}}>Global Search</label>
                <Search
                    id="globalSearch"
                    placeholder={t("searchPlacholder")}
                    noResultsMessage={t("no_results")}
                    fluid
                    results={this.state.suggestions} //users
                    value={this.state.valueToSearch}
                    
                    onSearchChange={debounce(this.handleSearchChange, 1000, {
                        leading: true,
                    })}
                    
                    loading={this.state.isLoading}
                    onResultSelect={(e, data) => {
                        uiStore.showBurger = !uiStore.showBurger;
                        if(typeof window !== "undefined") {
                            localStorage.setItem("searchItemNumber", data.result.key);
                            window.dispatchEvent(new Event("changeItemNumber"));
                        }
                        history.push(data.result.to)
                        //this.setState({ ...this.state, value: data.result.title})
                        
                        //reset value and suggestions
                        this.setState({ ...this.state, valueToSearch: '', suggestions:[]})
                        
                        //test, because history is not existing
                        localStorage.setItem(config.STORAGE_KEY_SELECTED_PRINTER_CARTRIDGE, JSON.stringify({
                            manufacturer: null,
                            series: null,
                            printer: null
                        }));
                        
                    }}
                />
            </React.Fragment>
        );
    }
}