import React from "react"
import {Helmet} from "react-helmet-async";
import {withNamespaces} from 'react-i18next'


@withNamespaces()
class MetaTags extends React.Component{
    constructor(props){
        super(props)
      
        const {t, add} = this.props

        //gernerate default values (if exist) for description

        let manufacturer = add && add.manufacturer ?
            add.manufacturer + " "
            : ""

        let series = add && add.series ? 
            add.series + " "
            : ""

        let printerModel = add && add.printerModel ?
            add.printerModel + " "
            : ""

        let originalItemNumber = add && add.originalItemNumber ?
            add.originalItemNumber + " "
            : ""

        this.state = {
            description: {
                defaultValue: t('meta.description.default'),
                manufacturer: manufacturer,
                series: series,
                printerModel: printerModel,
            },
            title:{
                defaultValue: t('meta.title.default'),
                manufacturer: manufacturer,
                originalItemNumber: originalItemNumber
            }

        }
    }
    
    render(){
        const {t, page} = this.props

        return(
            <Helmet>
                <title>{t('meta.title.' + page, this.state.title)}</title>
                <meta name="description" content={t('meta.description.' + page, this.state.description)} />
                <meta name="robots" content={t('meta.robots.' + page, t('meta.robots.default'))} />
                {/*<!-- Organisation -->*/}
                <script type="application/ld+json">
                {`{
                    "@context" : "http://schema.org",
                    "@type" : "Organization",
                    "legalName" : "Sanocycling GmbH",
                    "name" : "dieTonerverwerter",
                    "alternateName" : ["tonerverwerter",
                    "die-tonerverwerter",
                    "sanocycling"],
                    "vatID" : "DE281993964",
                    "taxID" : "241/127/32892",
                    "founder" : ["Patrick Höhne", "Sanocycling GmbH"],
                    "foundingDate" : "2018",
                    "address": {
                    "@type": "PostalAddress",
                    "addressCountry": "Deutschland",
                    "addressLocality": "Hilpoltstein",
                    "addressRegion": "Bayern",
                    "name": "Sanocycling GmbH",
                    "streetAddress": "Bahnhofstr. 26",
                    "postalCode": "91161"
                    },
                    "contactPoint" : [
                    { "@type": "ContactPoint",
                            "name": "Callcenter",
                            "telephone": "+49 9174/9767-27",
                            "email": "info@sanocycling.de",
                            "contactType": "customer support"
                    } , {
                            "@type" : "ContactPoint",
                            "telephone" : "+49 9174/9767-27",
                            "contactType" : "customer service",
                            "areaServed" : "DE",
                                "availableLanguage": {
                                "@type": "Language",
                                "name": "Deutsch",
                                "alternateName": "de"
                                }
                    } ],
                    "email" : "info@sanocycling.de",
                    "logo" : ["https://dietonerverwerter.de/images/logos/dietonerverwerter-round-logo.svg",
                    "https://dietonerverwerter.de/images/logos/dietonerverwerter-round-logo.png",
                    "https://dietonerverwerter.de/images/logos/dietonerverwerter-round.svg",
                    "https://dietonerverwerter.de/images/logos/dietonerverwerter-round.png"
                    ],
                    "url" : "https://dietonerverwerter.de",
                    "sameAs" : ["https://www.facebook.com/dietonerverwerter",
                    "https://twitter.com/tonerverwerter"
                    ]
                }`}
                </script>

                {/*<!-- Local Business -->*/}
                <script type="application/ld+json">
                {`{
                    "@context": "http://schema.org",
                    "@type": "LocalBusiness",
                    "address": {
                    "@type": "PostalAddress",
                        "addressCountry": "Deutschland",
                        "addressLocality": "Hilpoltstein",
                        "addressRegion": "Bayern",
                        "name": "Sanocycling GmbH",
                        "streetAddress": "Bahnhofstr. 26",
                        "postalCode": "91161"
                    },
                    "description": "Ihr Drucker hat den Geist aufgegeben. Den Vorrat an Tonerkartuschen können Sie bei den Spezialisten rund um die Verwertung von Druckerpatronen loswerden",
                    "name": "dieTonerverwerter",
                    "telephone": "+49 9174/9767-27",
                    "openingHours": "Mo,Tu,We,Th,Fr 09:00-12:00 13:00-16:00",
                    "priceRange" : "€",
                    "image" : ["https://dietonerverwerter.de/images/logos/dietonerverwerter-round-logo.svg",
                        "https://dietonerverwerter.de/images/logos/dietonerverwerter-round-logo.png",
                        "https://dietonerverwerter.de/images/logos/dietonerverwerter-round.svg",
                        "https://dietonerverwerter.de/images/logos/dietonerverwerter-round.png"
                        ],
                    "geo": {
                    "@type": "GeoCoordinates",
                    "latitude": "49.188591",
                    "longitude": "11.180343"
                    },
                    "url" : "https://dietonerverwerter.de",
                    "sameAs" : ["https://www.facebook.com/dietonerverwerter",
                        "https://twitter.com/tonerverwerter"
                    ]
                }`}
                </script>
            </Helmet>
        )
    }
}

/*function MetaTags(props){
    return (
        <Helmet>
            <title>{props.t('meta.title.homePage', props.t('meta.title.default'))}</title>
            <meta name="description" content={props.t('meta.description.homePage', props.t('meta.description.default'))} />
            <meta name="robots" content={props.t('meta.robots.homePage', props.t('meta.robots.default'))} />
        </Helmet>
    )
}*/

export default MetaTags