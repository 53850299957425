import React from 'react';
import {Grid, Button, Image} from "semantic-ui-react"

import {withNamespaces} from 'react-i18next';
import {observer} from "mobx-react"
import shoppingCartStore from "../stores/shoppingCartStore";
import invoiceStore from "../stores/invoiceStore";

import history from '../helper/browserHistory'
import config from "../../config/main.config";
import MetaTags from "../components/MetaTags"
import TextHelper from "../components/TextHelper"
import {showBlobFile} from "../helper/util";

@withNamespaces()
@observer
export default class SuccessPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loadingPostageStamp: false,
            loadingInvoice: false
        };

        this.showInvoiceClicked = this.showInvoiceClicked.bind(this);
        this.showPostageStamp = this.showPostageStamp.bind(this);
    }

    componentDidMount() {
        if(invoiceStore.email === null || invoiceStore.invoiceId === null) {
            history.replace('/sitzung-abgelaufen')
        }
        else {
            shoppingCartStore.removeShoppingCart();
        }
    }

    showPostageStamp() {
        this.setState({
            loadingPostageStamp:true
        });

        /*let windowReference = window;

        if(!windowReference.navigator.msSaveOrOpenBlob) {
            windowReference = window.open();
        }*/

        fetch(config.BASE_URL + 'postageStamp/' + (invoiceStore.dhlPostageStamp ? 'dhl/' + invoiceStore.invoiceId + '/' + invoiceStore.isPrivatePerson : '' ), {
            method: 'GET',
            headers: {
                'pragma': 'no-cache',
                'cache-control': 'no-cache',
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/pdf',
                'Content-Type':'application/pdf'
            }
        }).then((response) => {
            this.setState({
                loadingPostageStamp:false
            });
            if (response.status >= 200 && response.status < 400) {

               /* response.arrayBuffer().then(function(buffer) {
                    const file = new Blob([buffer], {type: 'application/pdf'});

                    if(windowReference.navigator.msSaveOrOpenBlob) {
                        windowReference.navigator.msSaveOrOpenBlob(file);
                    } else {
                        windowReference.location = URL.createObjectURL(file);
                    }

                });*/
                response.blob().then((blob)=>{
                    showBlobFile(blob, "Paketmarken");
                })

            } else {
                history.replace('/dhl503');
            }

        }).catch(error => {
            this.setState({
                loadingPostageStamp:false
            });
            history.replace('/sitzung-abgelaufen');
        });
    }

    showInvoiceClicked() {
        this.setState({
            loadingInvoice:true
        });

        fetch(config.BASE_URL + 'invoices/pdf/' + invoiceStore.invoiceId + '/' + invoiceStore.isPrivatePerson, {
            method: 'GET',
            headers: {
                'pragma': 'no-cache',
                'cache-control': 'no-cache',
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/pdf',
                'Content-Type':'application/pdf'
            }
        }).then((response) => {
            this.setState({
                loadingInvoice:false
            });
            if (response.status >= 200 && response.status < 400) {
                response.blob().then((blob)=>{
                    showBlobFile(blob, "Begleitzettel");
                })
            }

        }).catch(error => {
            this.setState({
                loadingInvoice:false
            });
            history.replace('/sitzung-abgelaufen');
        });
    }

    render() {

        const {t} = this.props;

        return (
            <Grid stackable class='main-grid'>
                <MetaTags page="erfolgreich-verkauft" />
                <Grid.Row>
                    <Grid.Column>
                        <h1 className="fira big">
                            {t("successPage.headline")}
                        </h1>
                        <span className="safe-checkout-wrapper">
                            <span className="safe-ssl">
                                {t("safeSSLConnection")}
                            </span>
                            <div className="safe-steps">
                                <span className="safe-step-1">{t("transferSaleData")}</span>
                                <span className="safe-step-2 white">{t("generateDeliveryNote")}</span>
                            </div>
                        </span>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{"paddingBottom": 0}}>
                    <div className='printer-cartridge-list-logo'/>
                </Grid.Row>
                <Grid.Row class='cash-result-row' style={{"paddingTop": 0}}>
                    <Grid.Column width={11} className='result-wrapper'>
                        <TextHelper f="success" add={{email: invoiceStore.email}} />
                    </Grid.Column>
                    <Grid.Column width={5} class="cash-wrapper">
                        <Button className="success-button"
                                loading={this.state.loadingInvoice}
                                disabled={this.state.loadingInvoice}
                                onClick={this.showInvoiceClicked}>
                            <Image alt='pdf_logo' style={{"margin": "auto"}} src="/images/pdf_logo.png"/>
                            {t("successPage.deliveryNote")}
                            <br/>
                            {t("print")}
                        </Button>
                        <Button className="success-button"
                                loading={this.state.loadingPostageStamp}
                                disabled={this.state.loadingPostageStamp}
                                onClick={this.showPostageStamp}>
                            {
                                invoiceStore.dhlPostageStamp ?
                                    <Image alt='dhl_logo' style={{"margin": "auto"}} src="/images/dhl_logo.png"/>
                                    :
                                    null
                            }
                            {
                                invoiceStore.dhlPostageStamp ?
                                    t("successPage.shippingDocumentDHL")
                                :
                                   t("successPage.shippingDocument")
                            }

                            <br/>
                            {t("print")}
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}