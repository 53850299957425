import 'whatwg-fetch';
import 'babel-polyfill';
import React from "react"
import ReactDOM from "react-dom"

import './helper/i18n'
import Layout from "./pages/Layout"
import history from "./helper/browserHistory";
import {Router} from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';

//20190725 add google tag manager
import TagManager from 'react-gtm-module'
const tagManagerArgs = {
    gtmId: 'GTM-5NLD7VG'
}
TagManager.initialize(tagManagerArgs)

const app = document.getElementById('app');
ReactDOM.render(
    <Router history={history}>
        <HelmetProvider>
            <Layout/>
        </HelmetProvider>
    </Router>,
    app);